import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Card } from './item';


const Summary = (props) => {
	const {
		id,
		borrower,
		rate,
		term,
		date,
		asInvestor,
		fromInvestorId,
		projectId,
		invested,
	} = props;
	const { t } = useTranslation();

	return (
		<div className="investment__col">
			<Card>
				<div className="investment__title">{t('invest.investmentSummary')}</div>

				<div className="investment__sep"></div>
				<div className="investment__inner">
					<div className="investment__text">{t('invest.projectId')}:</div>
					<div className="investment__text"><b>{t('invest.idNr')} {id}</b></div>
				</div>

				<div className="investment__inner">
					<div className="investment__text">{t('borrower')}:</div>
					<div className="investment__text"><b>{borrower}</b></div>
				</div>

				<div className="investment__inner">
					<div className="investment__text">{t('invest.interestRate')}:</div>
					<div className="investment__text"><b>{rate}% {t('invest.pa')}</b></div>
				</div>

				<div className="investment__inner">
					<div className="investment__text">{t('invest.loanTerm')}:</div>
					<div className="investment__text"><b>{term}</b></div>
				</div>

				<div className="investment__inner">
					<div className="investment__text">{t('invest.fundraising')}:</div>
					<div className="investment__text"><b>{date}</b></div>
				</div>

				<div className="investment__inner">
					<div className="investment__text">Invested:</div>
					<div className="investment__text"><b>{invested}</b></div>
				</div>

				<div className="investment__sep"></div>

				<div className="investment__subtext">
					{t('invest.byIssuing')} {fromInvestorId} {t('invest.confirmAcceptance')} {projectId}
					&nbsp;
					{t('invest.asSpecified')} {asInvestor} {t('invest.toTransfer')}
				</div>

				<a href="#" className="investment__link">{t('invest.investmentSheet')}</a>
			</Card>
		</div>
	)
};

Summary.defaultProps = {
	id: '12345SIA',
	borrower: 'ABC',
	rate: 12,
	term: '3 months',
	date: '1 jan 2022',
	asInvestor: 'Inlendo A/S',
	fromInvestorId: 'DDD678',
	projectId: 'AB12345',
	invested: 0,
};

Summary.propTypes = {
	id: PropTypes.string.isRequired,
	borrower: PropTypes.string.isRequired,
	rate: PropTypes.number.isRequired,
	term: PropTypes.string.isRequired,
	date: PropTypes.string.isRequired,
	asInvestor: PropTypes.string.isRequired,
	fromInvestorId: PropTypes.string.isRequired,
	projectId: PropTypes.string.isRequired,
	invested: PropTypes.number.isRequired,
};

export default Summary;
