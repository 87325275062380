import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Card } from './item';


const Investment = (props) => {
	const {
		currency,
		balance,
		investmentAmount,
	} = props;
	const [amount, setAmount] = useState(Number(investmentAmount));
	const { t } = useTranslation();
	const onChangeAmount = (amount) => {
		setAmount(amount);
		props.onChange(amount);
	};

	return (
		<div className="investment__col">
			<Card>
				<div className="investment__title">{t('invest.investmentSummary')}</div>
				<div className="investment__balance">{t('invest.yourBalance')} <span>{currency} {balance}</span></div>

				<input
					type="number"
					className="investment__binput"
					value={amount}
					onChange={e => onChangeAmount(Number(e.target.value))}
				/>

				<div className="investment__sep"></div>
				<fieldset>
					<legend><abbr title="required">*</abbr> {t('invest.amountDrawn')}</legend>

					<ul>
						<li>
							<label htmlFor="doc1">
								<input type="checkbox" id="doc1" defaultChecked />
								{t('invest.agreeTo')} <span>DOCUMENT 1</span>
							</label>
						</li>

						<li>
							<label htmlFor="doc2">
								<input type="checkbox" id="doc2" />
								{t('invest.agreeTo')} <span>DOCUMENT 2</span>
							</label>
						</li>
					</ul>
				</fieldset>
			</Card>
		</div>
	)
};

Investment.defaultProps = {
	currency: '€',
	balance: 10000,
	investmentAmount: 0,
	onChange: () => {},
};

Investment.propTypes = {
	currency: PropTypes.string,
	balance: PropTypes.number.isRequired,
	investmentAmount: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default Investment;
