import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Keyboard, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';


const Roadmap = (props) => {
	const {
		items,
	} = props;
	const { t } = useTranslation();

	return (
		<section id="roadmap" className="roadmap">
			<div className="container">
				<h2>
					{t('aboutUS.ourRoadmap')}
					<span>.</span>
				</h2>

				<Swiper
					spaceBetween={0}
					slidesPerView={4}
					modules={[Navigation, Keyboard]}
					effect={'fade'}
					onSlideChange={() => console.log('slide change')}
					onSwiper={(swiper) => console.log(swiper)}
					className="roadmap__slider"
					navigation={{
						nextEl: '.slick-next',
						prevEl: '.slick-prev',
						disabledClass: 'swiper-button-disabled'
					}}
					keyboard
					watchSlidesProgress>
					{items.map((el, index) => (
						<SwiperSlide key={`roadmapSlide_${index}`}>
							{({ isVisible, isActive }) => (
								<div className={`roadmap__item slick-slide ${isVisible ? 'slick-active' : ''} ${isActive ? 'slick-current' : ''}`}>
									<div className="roadmap__title">{el.title}</div>
									<div className="roadmap__subtitle">{el.body}</div>
								</div>
							)}
						</SwiperSlide>
					))}
				</Swiper>

				<button className="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false">Previous</button>
				<button className="slick-next slick-arrow" aria-label="Next" type="button" aria-disabled="false">Next</button>
			</div>
		</section>
	)
};

Roadmap.defaultProps = {
	items: [
		{
			title: 'June 2021',
			body: 'Legal and IT software architecture developed and tested. Integration with collaboration partners.',
		},
		{
			title: 'Oct 2021',
			body: 'Legal and IT software architecture developed and tested. Integration with collaboration partners.',
		},
		{
			title: 'Sep 2021',
			body: 'Legal and IT software architecture developed and tested. Integration with collaboration partners.',
		},
		{
			title: 'Dec 2021',
			body: 'Licensed as ECSP under EU Regulation 2020/1503. Launch of the platform in the Baltics.',
		},
		{
			title: 'Jan 2021',
			body: 'Launch of Autoinvest solution. UX upgrades.',
		},
		{
			title: 'Apr 2021',
			body: 'Launch of Secondary market. Functionality upgrades.',
		},
	]
};

Roadmap.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string,
		body: PropTypes.string,
	})),
};

export default Roadmap;
