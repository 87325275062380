import React from 'react';

import DashboardComponent from '../../components/dashboard';
import Banner from './banner';


const Account = (props) => {
	return (
		<DashboardComponent title={'Account Status'}>
			<Banner
				title={'KYC'}
				text={'Your KYC information has to be updated'}
				btnText={'Update KYC'}
				link={'/entry-knowledge-test'}
			/>

			<Banner
				title={'Sustainability & Appropriateness test'}
				text={'Please complete Sustainability & Appropriateness test, to be able to invest on our platform'}
				btnText={'Take assesment'}
				link={'/entry-knowledge-test'}
			/>
		</DashboardComponent>
	)
};

export default Account;
