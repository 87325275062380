import React from 'react';
import { Translation } from 'react-i18next';

import Hero from './hero';
import Sophisticated from './sophisticated';
import NonSophisticated from './nonSophisticated';


class KnowledgeTest extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			step: 'hero',
		};
	}

	setStep(step) {
		this.setState({
			step,
		});
	}

	render() {
		const {
			step,
		} = this.state;

		return (
			<div id="knowledge-test" className="row p-0 m-0">
				<div className="col p-0 m-0">
					<section className="title">
						<div className="container">
							<Translation>
								{ (t) => (
									<>
										<h1>{t('KYC.entryKnowledgeTest')}</h1>
										<h3>{t('KYC.weWillAsk')}</h3>
									</>
								)}
							</Translation>
						</div>
					</section>

					<section className="content">
						<div className="container">
							{step === 'hero' && <Hero setStep={(step) => this.setStep(step)} />}
							{step === 'sophisticated' && <Sophisticated setStep={(step) => this.setStep(step)} />}
							{step === 'non-sophisticated' && <NonSophisticated setStep={(step) => this.setStep(step)} />}
						</div>
					</section>
				</div>
			</div>
		)
	}
}

export default KnowledgeTest;
