import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


const Banner = (props) => {
	const {
		link,
	} = props;

	return (
		<div className={`invest__content ${props.showProgress ? 'main' : ''}`}>
			<div className="invest__wrap">
				<h2>{props.title}</h2>
				{props.showProgress
					? (
						<div className="line__wrap">
							<div className="line__line"></div>
						</div>
					)
					: (<></>)}
				<div className="invest__total">{props.text}</div>
			</div>

			<Link to={link} className="btn btn--big">{props.btnText}</Link>
		</div>
	)
};

Banner.defaultProps = {
	showProgress: false,
	link: '',
}

Banner.propTypes = {
	showProgress: PropTypes.bool,
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	btnText: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
};

export default Banner;
