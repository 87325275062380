import React from 'react';
import PropTypes from 'prop-types';


const Group = (props) => {
	const {
		group,
		activeGroup,
	} = props;

	return (
		<div className={`faq__group ${activeGroup === group ? 'active' : ''}`}>
			{props.children}
		</div>
	)
};

Group.defaultProps = {
	group: 0,
	activeGroup: 0,
};
Group.propTypes = {
	group: PropTypes.number,
	activeGroup: PropTypes.number,
	children: PropTypes.any,
};

export default Group;
