import React from 'react';
import { Translation } from 'react-i18next';

import Accordion from './accordion';
import { getFAQ } from '../../redux/API/faq';
import Loader from '../../components/loader';


class Faq extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			activeGroup: 0,
			items: [],
		};
	}

	componentDidMount() {
		this.getItems();
	}

	async getItems() {
		const response = await getFAQ();

		this.setState({
			isLoading: false,
			items: response.data,
		});
	}

	render() {
		const {
			items,
			activeGroup,
			isLoading,
		} = this.state;

		return (
			<div id="faq" className="row p-0 m-0">
				<div className="col p-0 m-0">
					<section className="title__big">
						<div className="container">
							<Translation>
							{ (t) => (<>
								<h1 data-echo="Questions...">
									{t('faq.haveA')}
									&nbsp;
									<span>{t('faq.question')}</span>
									<br />
									&nbsp;
									{t('faq.lookHere')}
								</h1>

								<input type="text" className="title__search" placeholder={t('Search')} />
							</>)}
							</Translation>
						</div>
					</section>

					<section>
						{items.length === 0 && isLoading
							? (
								<Loader />
							)
							: (
								<Accordion setActive={activeGroupIndex => this.setState({ activeGroup: activeGroupIndex })}>
									{items.map((el, index) => (
										<Accordion.Group
											title={el.title}
											activeGroup={activeGroup}
											group={index}
											key={`faqAccordion_${index}`}>
											{el.items.map((el, index) => (
												<Accordion.Item
													key={`faqAccordionItem_${index}`}
													title={el.title}
													body={el.body}
												/>
											))}
										</Accordion.Group>
									))}
								</Accordion>
							)}
					</section>
				</div>
			</div>
		)
	}
}

export default Faq;
