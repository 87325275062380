const finances = {
	textReport: 'Tax Report',
	showMore: 'Show more',
	showLess: 'Show less',
	showFilters: 'Show Filters',
	filterName: 'Filter name',
	selected: ' selected',
	selectAll: 'Select all',
	ratePA: 'Rate (p.a.)',
	mainDebt: 'Main debt',
	loanOrigination: 'Loan Originator',
	nothingHere: 'Nothing here.',
	youCurrently: 'You currently do not have any tax information avaliable. Press the button below to view our primary market opportunities',
}

export default finances;
