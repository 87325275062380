import { URL } from '../../config/const';
import blog from '../../mocks/blog';

const urls = {
	getBlog: `${URL}/blog`,
}

const getBlog = async (params) => {
	let data = {};

	if (params.blogId === 'test') {
		data = blog;
	}

	return new Promise((resolve) =>
		setTimeout(() => resolve({
			data
		}), 1500)
	);

	// return new Promise((resolve, reject) =>
	// 	// eslint-disable-next-line prefer-promise-reject-errors
	// 	setTimeout(() => reject({
	// 		data: {
	// 			error: 'Incorrect credentials!'
	// 		}
	// 	}), 1500)
	// );
};

export {
	urls,
	getBlog,
}
