const notifications = [
	{
		title: 'InvestmentAlert',
		date: 'Jul 15 - 10:48 PM',
		body: 'ChangesInInvestment',
		bodyAdditional: '601038',
		type: 'warning',
		isNew: false,
	},
	{
		title: 'CreditCardExpired',
		date: 'Jul 15 - 10:48 PM',
		body: 'ChangesInInvestment',
		bodyAdditional: '601038',
		type: 'error',
		isNew: false,
	},
	{
		title: 'AccountInformation',
		date: 'Jul 15 - 10:48 PM',
		body: 'LearnHowToFinish',
		type: 'information',
		isNew: false,
	},
	{
		title: 'AccountInformation',
		date: 'Jul 15 - 10:48 PM',
		body: 'LearnHowToFinish',
		type: 'notification',
		isNew: false,
	},
	{
		title: 'PaymentComplete',
		date: 'Jul 15 - 10:48 PM',
		body: 'LearnHowToFinish',
		type: 'success',
		isNew: true,
	},
	{
		title: 'PaymentComplete',
		date: 'Jul 15 - 10:48 PM',
		body: 'LearnHowToFinish',
		type: 'information',
		isNew: true,
	},
	{
		title: 'PaymentComplete',
		date: 'Jul 15 - 10:48 PM',
		body: 'LearnHowToFinish',
		type: 'notification',
		isNew: true,
	},
];

export default notifications;
