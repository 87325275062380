import React from 'react';

import Dashboard from '../pages/dashboard';
import Projects from '../pages/projects';
import Project from '../pages/project';
import Deposit from '../pages/deposit';
import Withdraw from '../pages/withdraw';
import Message from '../pages/message';
import Finances from '../pages/finances';
import Invest from '../pages/invest';
import KYC from '../pages/kyc';
import KnowledgeTest from '../pages/knowledge-test';
import Documents from '../pages/documents';
import Notifications from '../pages/notifications';


/**
 * Routes for authorized users
 */
const userRoutes = [
	{
		path: '/dashboard',
		element: <Dashboard />,
	},
	{
		path: '/projects',
		element: <Projects />,
	},
	{
		path: '/deposit',
		element: <Deposit />,
	},
	{
		path: '/withdraw',
		element: <Withdraw />,
	},
	{
		path: '/message/:message',
		element: <Message />,
	},
	{
		path: '/project/:projectId',
		element: <Project />,
	},
	{
		path: '/finances',
		element: <Finances />,
	},
	{
		path: '/invest',
		element: <Invest />,
	},
	{
		path: '/entry-knowledge-test',
		element: <KnowledgeTest />
	},
	{
		path: '/kyc',
		element: <KYC />,
	},
	{
		path: '/documents',
		element: <Documents />,
	},
	{
		path: '/notifications',
		element: <Notifications />,
	},
];

export default userRoutes;
