import { createSlice } from '@reduxjs/toolkit';

import { getProjectsAsync } from '../thunks/projectsThunk';


const initialState = {
	status: 'idle',
	data: {
	},
	error: '',
}

export const projectsSlice = createSlice({
	name: 'projects',
	initialState,
	reducers: {
		clearError: (state) => {
			state.error = '';
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getProjectsAsync.pending, (state) => {
				state.status = 'loading';
				state.error = '';
			})
			.addCase(getProjectsAsync.fulfilled, (state, action) => {
				state.status = 'idle';
				state.error = '';
			})
			.addCase(getProjectsAsync.rejected, (state, action) => {
				state.status = 'error';
				state.error = action.payload;
				state.data = {
				};
			});
	},
});

export const { clearError } = projectsSlice.actions;

export default projectsSlice.reducer;
