import React, { useState } from 'react';
import PropTypes from 'prop-types';


const Faq = (props) => {
	const {
		title
	} = props;
	const [isActive, setActive] = useState(false);

	return (
		<div className="faq__item">
			<div className={`faq__question ${isActive ? 'active' : ''}`} onClick={() => setActive(!isActive)}>{title}</div>

			<div className="faq__answer">
				{props.children}
			</div>
		</div>
	)
}

Faq.defaultProps = {
	title: 'Borrower',
	children: <></>
};

Faq.propTypes = {
	title: PropTypes.string,
	children: PropTypes.any,
};

export default Faq;
