import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { logout } from '../../redux/reducers/authSlice';
import { ReactComponent as Logout } from '../../assets/img/icons/logout.svg';


const TopBar = (props) => {
	const {
		isAuth,
		id,
		firstName,
		lastName,
		type,
		currency,
		balance,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();

	return (
		<div className="topbar" style={{ display: isAuth ? 'block' : 'none' }}>
			<div className="container">
				<strong>{currency}</strong>{balance}
				&nbsp;
				{firstName} {lastName}, {id} ({type})
				&nbsp;
				<a
					href="#"
					onClick={() => dispatch(logout())}>
					{t('logout')}
					&nbsp;
					<Logout />
				</a>
			</div>
		</div>
	)
};

TopBar.defaultProps = {
	currency: '€',
	balance: 1000000,
};

TopBar.propTypes = {
	isAuth: PropTypes.bool.isRequired,
	id: PropTypes.string.isRequired,
	firstName: PropTypes.string.isRequired,
	lastName: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	balance: PropTypes.number.isRequired,
	currency: PropTypes.string.isRequired,
};

export default TopBar;
