import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Flag } from '../../assets/img/flag.svg';

const List = (props) => {
	const {
		data
	} = props;
	const { t } = useTranslation();

	return (
		<div className="list__content">
			<div className="filter filter--wide">
				<div className="filter__item" data-sort="0" data-parse="text">{t('projects.project')}</div>
				<div className="filter__item flex-grow__3" data-sort="0" data-parse="text">{t('status')}</div>
				<div className="filter__item" data-sort="0">{t('investment')}</div>
				<div className="filter__item" data-sort="0">{t('rate')} ({t('projects.pa')})</div>
				<div className="filter__item" data-sort="0">{t('term')} ({t('projects.days')})</div>
				<div className="filter__sep flex-grow__18"></div>
			</div>

			<div className="list__rows">
				{data.map(el => (
					<div className="list__row list__row--small" key={`project_${el.projectId}`}>
						<div className="list__cell" data-title="Project">
							<div className="list__cell-wrap">
								<div className="list__cell-img">
									<Flag alt="Super coffee UOB" />
								</div>

								<div className="list__cell-info">
									<Link to={`/project/${el.projectId}`}>{el.title}</Link>
									<div className="list__cell-project">{el.projectId}<span>AA</span>
									</div>
								</div>
							</div>
						</div>

						<div className="list__cell list__cell--md-col flex-grow__3">
							{t('projects.dayUntilRepayment')} ({el.repaymentProgress}%)
							<div className="progress__wrap">
								<div className="progress__line" style={{ width: '80%' }}></div>
							</div>
							{t('projects.dayOfReturn')}: {el.date}
						</div>
						<div className="list__cell tac" data-title="Investment">{el.currency} {el.amount}</div>
						<div className="list__cell tac" data-title="Rate (p.a.)">{el.rate}%</div>
						<div className="list__cell tac" data-title="Term (days)">{el.term} {t('projects.days')}</div>
						<a href="" className="list__btn">{t('stage')}</a>
						<a href="" className="list__btn">{t('sell')}</a>
					</div>
				))}
			</div>
		</div>
	)
};

List.defaultProps = {
	data: [
		{
			title: 'Vegan sweets production 1',
			projectId: 6228824,
			repaymentProgress: 80,
			amount: 6120,
			currency: '€',
			rate: 14,
			term: 230,
			date: '16.03.2022'
		}
	],
};

List.propTypes = {
	data: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string.isRequired,
		projectId: PropTypes.number.isRequired,
		repaymentProgress: PropTypes.number.isRequired,
		amount: PropTypes.number.isRequired,
		currency: PropTypes.string.isRequired,
		rate: PropTypes.number.isRequired,
		term: PropTypes.number.isRequired,
		date: PropTypes.string.isRequired,
	})),
};

export default List;
