import header from '../ru/header';

const translation = {
	Dashboard: 'Дэшбоард',
	Invest: 'Инвестировать',
	Deposit: 'Депозит',
	Withdraw: 'Снятие',

	header,
};

export default translation;
