import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';


const BulletNav = (props) => {
	const {
		items,
	} = props;
	const container = useRef();
	const bullet = useRef();

	const calcBulletPos = (activeEl) => {
		if (!container.current || !container.current.querySelector('.active')) {
			return
		}

		let dir = 'prev';
		const items = container.current.querySelectorAll('.bignav__item');

		if (items && activeEl) {
			for (let i = 0; i < items.length; i++) {
				items[i].classList.remove('active');
			}

			activeEl.currentTarget.classList.toggle('active');
		}

		const el = container.current.querySelector('.active');
		const cX = el.getBoundingClientRect().left + el.getBoundingClientRect().width / 2 - 12;

		if (el.previousElementSibling && el.previousElementSibling.getAttribute('data-current') === '1') {
			dir = 'next';
		}

		if (items) {
			for (let i = 0; i < items.length; i++) {
				items[i].setAttribute('data-current', 0);
			}
		}

		el.setAttribute('data-current', 1);

		if (dir === 'prev') {
			bullet.current.style.left = cX + 'px';
			if (el.nextElementSibling) {
				bullet.current.style.width = el.getBoundingClientRect().width / 2 + el.nextElementSibling.clientWidth / 2 + 'px'
			} else {
				bullet.current.style.width = el.getBoundingClientRect().width + 'px'
			}
		} else {
			bullet.current.style.width = el.getBoundingClientRect().width / 2 + el.previousElementSibling.clientWidth / 2 + 'px'
		}

		setTimeout(() => {
			bullet.current.style.width = '24px';

			if (dir === 'next') {
				bullet.current.style.left = cX + 'px';
			}
		}, 400);
	};

	useEffect(() => {
		calcBulletPos();
	}, [])

	return (
		<div className="bignav">
			<div className="container" ref={container}>
				{items.map((el, index) => (
					<a
						href="#"
						className={`bignav__item ${index === 0 ? 'active' : ''}`}
						key={`bulletNav_${index}`}
						onMouseOver={(el) => calcBulletPos(el)}>
						{el}
					</a>
				))}
			</div>

			<div className="bignav__bullet" ref={bullet}></div>
		</div>
	)
};

BulletNav.defaultProps = {
	items: [
		'Fundrasing',
		'Active',
		'Finalized',
	],
};

BulletNav.propTypes = {
	items: PropTypes.arrayOf(PropTypes.string),
};

export default BulletNav;
