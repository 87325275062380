import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as Yup from 'yup';
import { Formik } from 'formik';


const schema = Yup.object().shape({
	password: Yup
		.string()
		.required()
		.min(8, 'Must be at least 8 characters')
		.max(20, 'Must be less  than 20 characters')
		.matches(
			/^[a-zA-Z0-9]+$/,
			'Cannot contain special characters or spaces.'
		),
	passwordRepeat: Yup
		.string()
		.required()
		.oneOf([Yup.ref('password'), null], 'Passwords must match'),
});


const Reset = () => {
	return (
		<div id="reset" className="row p-0 m-0">
			<div className="col p-0 m-0">
				<section className="s-login s-login--login">
					<div className="container">
						<h1>Recover password</h1>

						<Formik
							validationSchema={schema}
							onSubmit={console.log}
							initialValues={{
								password: '',
								passwordRepeat: '',
							}}>
							{({
								handleSubmit,
								handleChange,
								handleBlur,
								values,
								touched,
								isValid,
								errors,
								}) => (
								<Form noValidate onSubmit={handleSubmit} className="form">
									<Form.Group controlId="password">
										<Form.Label className="label-hidden">Password</Form.Label>
										<Form.Control
											type="text"
											name="password"
											placeholder="New password"
											value={values.password}
											onChange={handleChange}
											isInvalid={!!errors.password}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.password}
										</Form.Control.Feedback>
									</Form.Group>

									<Form.Group controlId="password">
										<Form.Label className="label-hidden">Password repeat</Form.Label>
										<Form.Control
											type="text"
											name="passwordRepeat"
											placeholder="Repeat password"
											value={values.passwordRepeat}
											onChange={handleChange}
											isInvalid={!!errors.passwordRepeat}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.passwordRepeat
												? errors.passwordRepeat.replace('passwordRepeat', 'Repeat password')
												: ''
											}
										</Form.Control.Feedback>
									</Form.Group>

									<Button type="submit">Reset password</Button>
								</Form>
							)}
						</Formik>
					</div>
				</section>
			</div>
		</div>
	)
};

export default Reset;
