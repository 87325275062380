import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as View } from '../../assets/img/icons/view.svg';


const error = (props) => {
	const { t, i18n } = useTranslation();

	return (
		<section className="message">
			<div className="container">
				<h1>404</h1>

				<View className="message__icon" />
				<div className="message__text centered">
					{t('html.404')}
				</div>

				<Link to={'/'} className="message__btn">{t('Home')}</Link>
			</div>
		</section>
	)
};

export default error;
