import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Icon } from '../../assets/img/icons/view.svg';


const underConstruction = (props) => {
	const { t, i18n } = useTranslation();

	return (
		<section className="message">
			<div className="container">
				<h1>Under Construction</h1>

				<Icon className="message__icon" />
				<div className="message__text centered">
					{t('html.underconstruction')}
				</div>

				<Link to={'/'} className="message__btn">{t('Home')}</Link>
			</div>
		</section>
	)
};

export default underConstruction;
