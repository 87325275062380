import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Gallery from './gallery';
import { Row, Col } from './row';
import Button from 'react-bootstrap/Button';


const Card = (props) => {
	const { t, i18n } = useTranslation();
	const [investmentAmount, setInvestmentAmount] = useState(0);
	const {
		id,
		title,
		amount,
		completed,
		left,
		rate,
		term,
		score,
		ltv,
		purpose,
		security,
		repayment,
		gallery,
		currency,
		type,
		name,
	} = props;
	const setProject = () => {
		props.setProject({
			projectId: id,
			fromInvestorId: 'DDD678',
			asInvestor: 'Inlendo A/S',
			regNumber: '40203329997',
			date: new Date().toISOString().substr(0, 10),
			id: '12345SIA',
			borrower: type === 'Borrower' ? name : '',
			rate: 12,
			term: '3 months',
			fundraisingClosingDate: '1 jan 2024',
			amount,
			investmentAmount,
		});

		props.router.navigate('/invest');
	};

	return (
		<div className="project">
			<div className="container">
				<div className="project__wrap">
					<div className="project__info">
						<h1 className="project__title">{title}</h1>

						<Row>
							<Col>
								<b>{currency}{amount.raised}</b>
								{t('project.amountRaised')}
							</Col>

							<Col>
								<b>{currency}{amount.investment}</b>
								{t('project.investmentRaised')}
							</Col>
						</Row>

						<div className="project__line-bg">
							<div className="project__line" style={{ width: '20%' }}></div>
							<div className="project__goal" style={{ left: '50%' }}>
								<div className="project__goal-hint">{t('project.minTarget')} {currency}{amount.min}</div>
							</div>
						</div>

						<Row>
							<Col>
								{completed}%
							</Col>

							<Col>
								<div className="project__blue">
									<span>{t('project.daysLeft')}</span>
									&nbsp;{left}
								</div>
							</Col>
						</Row>

						<div className="project__sep"></div>
						<Row>
							<Col>
								{t('project.investmentAmount')}
							</Col>

							<Col>
								<b>€{amount.fulfilled}</b>
							</Col>
						</Row>

						<Row>
							<Col>
								{t('project.interestRate')}
							</Col>

							<Col>
								<b>{rate}% p.a.</b>
							</Col>
						</Row>

						<Row>
							<Col>
								{t('project.loanTeam')}
							</Col>

							<Col>
								<b>{term} days</b>
							</Col>
						</Row>

						<Row>
							<Col>
								{t('project.projectScore')}
							</Col>

							<Col>
								<div className="project__blue">{score}</div>
							</Col>
						</Row>

						<Row>
							<Col>
								LTV
							</Col>

							<Col>
								<b>{ltv}%</b>
							</Col>
						</Row>

						<Row>
							<Col>
								{t('project.loanPurpose')}:
							</Col>

							<Col>
								<b>{purpose}</b>
							</Col>
						</Row>

						<Row>
							<Col>
								{t('security')}
							</Col>

							<Col>
								<b>{security}</b>
							</Col>
						</Row>

						<Row>
							<Col>
								{t('project.repaymentType')}
							</Col>

							<Col>
								<div className="project__blue">{repayment}</div>
							</Col>
						</Row>

						<div className="project__sep"></div>
						<form className="project__form">
							<fieldset>
								<legend>{t('project.availableFunds')}
									&nbsp;
									<span data-amount={amount.available}>{currency}&nbsp;{amount.available}</span>
								</legend>
							</fieldset>

							<div className="project__inputarea">
								<input
									type="number"
									name="amount"
									className="project__input"
									placeholder="Your amount"
									value={investmentAmount}
									onChange={e => setInvestmentAmount(Number(e.target.value))}
								/>

								<Button
									className="project__submit"
									onClick={() => setProject()}>
									{t('Invest')}
								</Button>
							</div>
						</form>
					</div>

					<Gallery images={gallery} />
				</div>
			</div>
		</div>
	)
};

Card.defaultProps = {
	id: 6228824,
	title: 'Vegan sweets production',
	amount: {
		raised: 10000,
		fulfilled: 100000,
		investment: 100000,
		available: 23000,
		min: 50000,
	},
	currency: '€',
	completed: 10,
	left: 26,
	rate: 17,
	term: 60,
	score: 'B+',
	ltv: 60,
	purpose: 'acquiring goods',
	security: 'Assignment of Proceeds',
	repayment: 'linear',
	gallery: [
		'slide-1.png',
		'slide-1.png',
		'slide-1.png',
	],
	setProject: () => {},
	type: 'Borrower',
	name: 'Jhon Wick',
	router: {},
};

Card.propTypes = {
	id: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	amount: PropTypes.shape({
		raised: PropTypes.number.isRequired,
		fulfilled: PropTypes.number.isRequired,
		investment: PropTypes.number.isRequired,
		available: PropTypes.number.isRequired,
		min: PropTypes.number.isRequired,
	}),
	completed: PropTypes.number.isRequired,
	left: PropTypes.number.isRequired,
	rate: PropTypes.number.isRequired,
	term: PropTypes.number.isRequired,
	score: PropTypes.string.isRequired,
	ltv: PropTypes.number.isRequired,
	purpose: PropTypes.string.isRequired,
	security: PropTypes.string.isRequired,
	repayment: PropTypes.string.isRequired,
	currency: PropTypes.string.isRequired,
	gallery: PropTypes.arrayOf(PropTypes.string),
	setProject: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	router: PropTypes.object.isRequired,
};

export default Card;
