import { URL } from '../../config/const';
import projects from '../../mocks/projects';
import dashboard from '../../mocks/projects/dashboard';

const urls = {
	getProjects: (id) => `${URL}/projects/${id}`,
}

const getProjects = async (params) => {
	const data = projects;

	return new Promise((resolve) =>
		setTimeout(() => resolve({
			data
		}), 1500)
	);
};

const getOffers = async (params) => {
	const data = dashboard;

	return new Promise((resolve) =>
		setTimeout(() => resolve({
			data
		}), 1500)
	);
};

export {
	urls,
	getProjects,
	getOffers,
}
