import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Navigation } from 'swiper';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/keyboard';

import DashboardComponent from '../../components/dashboard';


const Offers = (props) => {
	const { t } = useTranslation();
	const {
		items,
	} = props;

	return (
		<DashboardComponent
			title={'Actual Offers'}
			subtitle={'Our current investment projects'}
			className={'offers'}
			link={'/projects'}
			isVisibleBtn>
			<Swiper
				spaceBetween={50}
				slidesPerView={4}
				modules={[Navigation, Keyboard]}
				effect={'fade'}
				onSlideChange={() => console.log('slide change')}
				onSwiper={(swiper) => console.log(swiper)}
				className="offers__wrap"
				navigation={{
					nextEl: '.slick-next',
					prevEl: '.slick-prev',
					disabledClass: 'swiper-button-disabled'
				}}
				keyboard
				watchSlidesProgress>
				{items.map((el) => (
					<SwiperSlide key={el.id}>
						{({ isVisible }) => (
							<div className={`card slick-slide ${isVisible ? 'slick-active' : ''}`}>
								<div className="card__img">
									<img src={`/assets/img/${el.img}`} alt="Card" />
								</div>

								<div className="card__header">
									<div className="card__flex">
										<div className="card__id"><span>{t('id')}</span>{el.id}</div>
										<div className="card__class">{el.class}</div>
									</div>
									<div className="card__title">{el.title}</div>
								</div>

								<div className="card__flex">
									<div className="card__price">
										<div className="card__price-main">€{el.price}</div>
										<div className="card__price-secondary">{el.priceSecondary}</div>
									</div>

									<div className="card__round">
										<svg className="card__progress" width="92" height="92">
											<circle className="ready__circle" stroke="#468aff" strokeWidth="8" strokeDasharray="300" fill="transparent" strokeLinecap="round" r="42" cx="46" cy="46"></circle>
										</svg>

										<div className="card__rate"><span>{el.priceLeft}%</span>{t('left').toLowerCase()}</div>
									</div>
								</div>

								<div className="card__flex card__rates">
									<div className="card__rate">
										<span>{el.rate}%</span>
										{t('rate').toLowerCase()}
									</div>

									<div className="card__rate">
										<span>{el.term} {t('month').toLowerCase()}</span>
										{t('term').toLowerCase()}
									</div>

									<div className="card__rate"><span>{el.left}</span>{t('dashboard.daysLeft')}</div>
								</div>

								<div className="card__btn">
									<Link to={`/project/${el.id}`} className="btn">{t('dashboard.moreInfo')}</Link>
								</div>
							</div>
						)}
					</SwiperSlide>
				))}

				<button className="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false">Previous</button>
				<button className="slick-next slick-arrow" aria-label="Next" type="button" aria-disabled="false">Next</button>
			</Swiper>
		</DashboardComponent>
	)
};

Offers.defaultProps = {
	items: [
		{
			img: 'card__img.png',
			id: '00259',
			class: 'B1',
			title: 'Vegan sweets production',
			price: '22,710',
			priceSecondary: '30% out of €150,000',
			priceLeft: 20,
			rate: 14,
			term: '24 month',
			left: 15,
		},
		{
			img: 'card__img--alt.png',
			id: '00260',
			class: 'B1',
			title: 'Vegan sweets production',
			price: '22,710',
			priceSecondary: '30% out of €150,000',
			priceLeft: 20,
			rate: 14,
			term: '24 month',
			left: 15,
		},
		{
			img: 'card__img.png',
			id: '00261',
			class: 'B1',
			title: 'Vegan sweets production',
			price: '22,710',
			priceSecondary: '30% out of €150,000',
			priceLeft: 20,
			rate: 14,
			term: '24 month',
			left: 15,
		},
		{
			img: 'card__img.png',
			id: '00261',
			class: 'B1',
			title: 'Vegan sweets production',
			price: '22,710',
			priceSecondary: '30% out of €150,000',
			priceLeft: 20,
			rate: 14,
			term: '24 month',
			left: 15,
		},
		{
			img: 'card__img.png',
			id: '00261',
			class: 'B1',
			title: 'Vegan sweets production',
			price: '22,710',
			priceSecondary: '30% out of €150,000',
			priceLeft: 20,
			rate: 14,
			term: '24 month',
			left: 15,
		},
	]
};

Offers.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({
		img: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		class: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		price: PropTypes.string.isRequired,
		priceSecondary: PropTypes.string.isRequired,
		priceLeft: PropTypes.number.isRequired,
		rate: PropTypes.number.isRequired,
		term: PropTypes.string.isRequired,
		left: PropTypes.number.isRequired,
	})),
};

export default Offers;
