import React from 'react';
import { Translation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ReactComponent as WhatsUp } from '../../assets/img/icons/whatsapp.svg';
import { ReactComponent as Telegram } from '../../assets/img/icons/telegram.svg';
import { ReactComponent as Facebook } from '../../assets/img/icons/facebook.svg';
import { ReactComponent as FacebookChat } from '../../assets/img/icons/facebook__chat.svg';

import withRouter from '../../components/withRouter';
import { getBlog } from '../../redux/API/blog';
import Loader from '../../components/loader';


class Blog extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			date: '',
			title: '',
			description: '',
			img: '',
			author: {
				title: '',
				date: '',
				img: '',
			},
			post: '',
			share: {},
			isLoading: true,
		};
	}

	componentDidMount() {
		this.getPost()
	}

	async getPost() {
		const blogId = this.props.router.params.blogId;
		const response = await getBlog({
			blogId
		});

		this.setState({
			...response.data,
			isLoading: false
		});
	}

	render() {
		const {
			date,
			title,
			description,
			img,
			author,
			post,
			share,
			isLoading,
		} = this.state;

		return (
			<div id="blog" className="row p-0 m-0">
				<div className="col p-0 m-0">

					{isLoading
						? (
							<Loader style={{ marginBottom: '5rem', marginTop: '5rem' }} />
						)
						: (
							<section>
								<div className="container">
									<div className="post__content">
										<div className="hero__item hero__item--single">
											<div className="hero__img">
												<img src={`/assets/img/${img}`} alt="Post title" />
											</div>

											<div className="hero__content">
												<div className="hero__date">{date}</div>
												<div className="hero__title">{title}</div>
												<div className="hero__description">{description}</div>

												<div className="hero__sep"></div>

												<div className="author">
													<div className="author__img">
														<img src={`/assets/img/${author.img}`} alt="Author" />
													</div>
													<div className="author__content">
														<div className="author__title">{author.title}</div>
														<div className="author__date">
															{author.date}
															&nbsp;
															{author.readTime}
															&nbsp;
															<Translation>{(t) => t('blogs.read')}</Translation>
														</div>
													</div>
												</div>

												<div className="hero__share">
													<Translation>{(t) => t('blog.sharePost')}</Translation>
													<span className="hero__icon"></span>
												</div>
											</div>
										</div>

										<div
											dangerouslySetInnerHTML={{
												__html: post
											}}
											style={{
												marginTop: '1rem',
											}}
										/>

										<div className="post__sep"></div>

										<div className="post__footer">
											<div className="post__top" onClick={() => window.scrollTo(0, 0)}>
												<Translation>{(t) => t('blog.backToTop')}</Translation>
												<span className="post__icon"></span>
											</div>

											<div className="post__share">
												{share.whatsapp && (
													<a href={share.whatsapp}>
														<WhatsUp />
													</a>
												)}
												{share.telegram && (
													<a href={share.telegram}>
														<Telegram />
													</a>
												)}
												{share.facebook && (
													<a href={share.facebook}>
														<Facebook />
													</a>
												)}
												{share.facebook__chat && (
													<a href={share.facebook__chat}>
														<FacebookChat />
													</a>
												)}
											</div>
										</div>
									</div>
								</div>
							</section>
						)
					}
				</div>
			</div>
		)
	}
}

Blog.propTypes = {
	router: PropTypes.object.isRequired,
}

export default withRouter(Blog);
