import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Order from './order';
import Summary from './summary';
import Investment from './investment';
import SmsAuth from './smsAuth';
import DoInvest from './invest';
import Disclaimer from './disclaimer';
import withRouter from '../../components/withRouter';
import { setBalance } from '../../redux/reducers/authSlice';
import { addNotification } from '../../redux/reducers/notificationsSlice';


const projectId = 'AB12345';

class Invest extends React.Component {
	constructor(props) {
		super(props);
		const {
			investmentAmount,
		} = this.props;

		this.state = {
			investmentAmountState: investmentAmount,
		}
	}

	componentDidMount() {
		const {
			type,
			forms,
		} = this.props;

		if (type === 'Investor') {
			if (!forms.KYCCompleted) {
				this.props.router.navigate('/kyc');
			}
		} else {
			if (!forms.KYCCompleted) {
				this.props.router.navigate('/kyc');
			}
			if (!forms.borrowerCompleted) {
				this.props.router.navigate('/kyc');
			}
		}
	}

	makeInvestment() {
		const {
			investmentAmountState,
		} = this.state;
		const {
			id,
			balance,
		} = this.props;
		const currentDate = new Date().toString();
		const date = `${currentDate.substring(4, 10)} - ${currentDate.substring(16, 21)}`;

		this.props.setBalance(balance - investmentAmountState);
		this.props.addNotification({
			title: 'InvestmentAlert',
			date,
			body: 'ChangesInInvestment',
			bodyAdditional: projectId,
			type: 'warning',
			isNew: true,
		});
	}

	render() {
		const {
			id,
			balance,
			amount,
			investmentAmount,
		} = this.props;

		return (
			<div id="invest" className="row p-0 m-0">
				<div className="col p-0 m-0">
					<section>
						<div className="container">
							<Order projectId={projectId} />

							<div className="investment__row">
								<Summary invested={amount.investment} projectId={projectId} />

								<Investment
									investmentAmount={investmentAmount}
									balance={balance}
									onChange={amount => this.setState({ investmentAmountState: amount })}
								/>
							</div>

							<SmsAuth />

							<DoInvest
								makeInvestment={() => this.makeInvestment()}
								asInvestor={id}
							/>

							<Disclaimer />
						</div>
					</section>
				</div>
			</div>
		)
	}
}

Invest.propTypes = {
	id: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	balance: PropTypes.number.isRequired,
	investmentAmount: PropTypes.number.isRequired,
	amount: PropTypes.shape({
		raised: PropTypes.number.isRequired,
		fulfilled: PropTypes.number.isRequired,
		investment: PropTypes.number.isRequired,
		min: PropTypes.number.isRequired,
	}).isRequired,
	setBalance: PropTypes.func.isRequired,
	addNotification: PropTypes.func.isRequired,
	forms: PropTypes.object.isRequired,
	router: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
	setBalance,
	addNotification,
};

const mapStateToProps = state => {
	return {
		id: state.auth.data.id,
		type: state.auth.data.type,
		forms: state.auth.forms,
		balance: state.auth.data.balance,
		amount: state.investment.data.amount,
		investmentAmount: state.investment.data.investmentAmount,
	}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Invest));
