import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';


const Item = (props) => {
	const [isActive, setActive] = useState(false);
	const {
		id,
		date,
		rate,
		currency,
		amount,
		items,
	} = props;
	const { t } = useTranslation();

	return (
		<div className={`list__row list__row--small list__row--haschild ${isActive ? 'active' : ''}`}>
			<div className="list__cell" data-title={t('Project')}>
				<div className="list__cell-col">
					<a href="#" style={{ color: 'var(--blue)' }}>{t('finances.loanOrigination')}</a>
					{id}
				</div>
			</div>
			<div className="list__cell tac">{date}</div>
			<div className="list__cell tac" data-title={t('amount')}>{currency} {amount.current}</div>
			<div className="list__cell tac" data-title={t('finances.ratePA')}>{rate}%</div>
			<div className="list__cell tac" data-title={t('finances.mainDebt')}>{amount.debt}</div>
			<div className="list__cell tac" data-title={t('remaining')}>{currency} {amount.remaining}</div>
			<div className="list__cell list__cell--action" onClick={() => setActive(!isActive)}>
				<div className="list__dropdown"></div>
			</div>

			<div className="list__subrows">
				{items.map((el, index) => (
					<div className="list__subrow list__row--small" key={`subRow_${index}`}>
						<div className="list__subcell">
							<div className={`list__status list__status--${el.status === 1 ? 'green' : 'red'}`}></div>
						</div>
						<div className="list__subcell tac">{el.date}</div>
						<div className="list__subcell tac" data-title={t('amount')}>€ {el.amount.current}</div>
						<div className="list__subcell tac" data-title={t('finances.ratePA')}>{el.rate}%</div>
						<div className="list__subcell tac" data-title={t('finances.mainDebt')}>{el.amount.debt}</div>
						<div className="list__subcell tac" data-title={t('remaining')}>€ {el.amount.remaining}</div>
						<div className="list__subcell"></div>
					</div>
				))}
			</div>
		</div>
	)
};

Item.defaultProps = {
	currency: '€',
	id: '6228824',
	date: '17.09.2022',
	rate: 17,
	amount: {
		current: 4850,
		debt: 0,
		remaining: 2000000,
	},
	items: [
		{
			date: '17.09.2022',
			rate: 17,
			amount: {
				current: 4850,
				debt: 0,
				remaining: 2000000,
			},
			status: 1,
		},
		{
			date: '17.09.2022',
			rate: 17,
			amount: {
				current: 4850,
				debt: 0,
				remaining: 2000000,
			},
			status: 1,
		},
		{
			date: '17.09.2022',
			rate: 17,
			amount: {
				current: 4850,
				debt: 0,
				remaining: 2000000,
			},
			status: 0,
		},
		{
			date: '17.09.2022',
			rate: 17,
			amount: {
				current: 4850,
				debt: 0,
				remaining: 2000000,
			},
			status: 1,
		},
	]
};

Item.propTypes = {
	currency: PropTypes.string,
	id: PropTypes.string.isRequired,
	date: PropTypes.string.isRequired,
	amount: PropTypes.shape({
		current: PropTypes.number.isRequired,
		debt: PropTypes.number.isRequired,
		remaining: PropTypes.number.isRequired,
	}).isRequired,
	rate: PropTypes.number.isRequired,
	items: PropTypes.arrayOf(PropTypes.shape({
		date: PropTypes.string.isRequired,
		rate: PropTypes.number.isRequired,
		amount: PropTypes.shape({
			current: PropTypes.number.isRequired,
			debt: PropTypes.number.isRequired,
			remaining: PropTypes.number.isRequired,
		}).isRequired,
		status: PropTypes.number.isRequired,
	})),
};

export default Item;
