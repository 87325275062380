const deposit = {
	possibleInvestments: 'It is possible to make investments on the Inlendo platform only if there are free funds on an individual account.',
	replenishmentFunds: 'Replenishment of funds is carried out exclusively by transferring funds from a bank account belonging to the investor to the nominal account of the Platform Operator.',
	addFounds: 'Add funds to your account',
	bankAccount: 'Beneficiary Bank Account Number (IBAN)',
	beneficiaryAddress: 'Beneficiary address',
	beneficiaryBank: 'Beneficiary bank SWIFT/BIC code',
	beneficiaryName: 'Beneficiary name',
	beneficiaryRegistration: 'Beneficiary Registration Number',
	paymentDetails: 'Payment Details / Purpose',
	depositTo: 'Deposit to account number',
	assignedId: 'You have been assigned ID',
	fundsAreCredited: 'After the funds are credited to the nominal account, the full functionality of theplatform will be available to you and you will be able to invest.',
	makingPayment: `
		By making a payment to a nominal account,
		you confirm the conclusion of an investment assistance agreement and an
		agency agreement.
	`,
	accountLimit: 'Account limits',
	minimumInvestment: 'Minimum investment',
	maximumInvestment: 'Maximum investment',
	nonSophisticatedInvestor: 'Non-sophisticated investor',
	netIncome: 'of net income',
	sophisticatedInvestor: 'Sophisticated investor',
	individualEntrepreneurs: 'Individual entrepreneurs',
	noRestrictions: 'no restrictions',
	nominalBankAccount: 'Nominal bank account',
	itemHelpText: `
		A special account is opened for a legal entity that has the right by law to keep 
		funds of third parties separately. The funds on this type of account remain the
		property of the investor and are subject to supervision and control by the financial
		institution in which the nominal account is opened. An individual account is an
		equivalent reflection of the funds belonging to the investor on a nominal bank
		account and is intended only to record the actions of the Investor on the
		Platform.	
	`,
}

export default deposit;
