import { URL } from '../../config/const';

const urls = {
	getFAQ: `${URL}/deposit`,
}

const getFAQ = async (params) => {
	const data = [
		{
			title: 'investing',
			items: [
				{
					title: 'How do I get access to start investing?',
					body: 'Identification is performed through a set of factors by verifying the documents provided and confirmed and by a reconciliation of the data received on the moment of funds credited to the Payment Account with the data of the documents provided. You have to provide a copy of two official documents on your name, a photo of your face with a passport, and if the system administrator considers it necessary, then make a video call in which we will record the coincidence of your face with the photo in your passport. Then the Platform Operator will reconcile the data about the payer provided by the Payment Institution with your data provided during registration.',
				},
				{
					title: 'How did the Platform identify me?',
					body: 'Identification is performed through a set of factors by verifying the documents provided and confirmed and by a reconciliation of the data received on the moment of funds credited to the Payment Account with the data of the documents provided. You have to provide a copy of two official documents on your name, a photo of your face with a passport, and if the system administrator considers it necessary, then make a video call in which we will record the coincidence of your face with the photo in your passport. Then the Platform Operator will reconcile the data about the payer provided by the Payment Institution with your data provided during registration.',
				},
			]
		},
		{
			title: 'whoWeAre',
			items: [
				{
					title: 'Who we are?',
					body: 'Identification is performed through a set of factors by verifying the documents provided and confirmed and by a reconciliation of the data received on the moment of funds credited to the Payment Account with the data of the documents provided. You have to provide a copy of two official documents on your name, a photo of your face with a passport, and if the system administrator considers it necessary, then make a video call in which we will record the coincidence of your face with the photo in your passport. Then the Platform Operator will reconcile the data about the payer provided by the Payment Institution with your data provided during registration.',
				},
			]
		},
		{
			title: 'autoInvest',
			items: [
				{
					title: 'Should you auto invest?',
					body: 'Identification is performed through a set of factors by verifying the documents provided and confirmed and by a reconciliation of the data received on the moment of funds credited to the Payment Account with the data of the documents provided. You have to provide a copy of two official documents on your name, a photo of your face with a passport, and if the system administrator considers it necessary, then make a video call in which we will record the coincidence of your face with the photo in your passport. Then the Platform Operator will reconcile the data about the payer provided by the Payment Institution with your data provided during registration.',
				},
			]
		},
		{
			title: 'withdravals',
			items: [
				{
					title: 'What about withdravals?',
					body: 'Identification is performed through a set of factors by verifying the documents provided and confirmed and by a reconciliation of the data received on the moment of funds credited to the Payment Account with the data of the documents provided. You have to provide a copy of two official documents on your name, a photo of your face with a passport, and if the system administrator considers it necessary, then make a video call in which we will record the coincidence of your face with the photo in your passport. Then the Platform Operator will reconcile the data about the payer provided by the Payment Institution with your data provided during registration.',
				},
			]
		},
	];


	return new Promise((resolve) =>
		setTimeout(() => resolve({
			data
		}), 1500)
	);
};

export {
	getFAQ,
}
