import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Keyboard, Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/keyboard';
import 'swiper/css/pagination';


const Gallery = (props) => {
	const {
		images
	} = props;

	return (
		<div className="project__gallery">
			<Swiper
				spaceBetween={0}
				slidesPerView={1}
				modules={[Navigation, Keyboard, Pagination]}
				effect={'fade'}
				onSlideChange={() => console.log('slide change')}
				onSwiper={(swiper) => console.log(swiper)}
				navigation={{
					nextEl: '.slick-next',
					prevEl: '.slick-prev',
					disabledClass: 'swiper-button-disabled'
				}}
				style={{
					width: '100%',
					height: '100%',
				}}
				pagination={{
					clickable: true
				}}
				keyboard
				watchSlidesProgress>
				{images.map((el, index) => (
					<SwiperSlide key={`project_screen_${index}`}>
						{({ isVisible }) => (
							<div className={`project__slide slick-slide ${isVisible ? 'slick-active' : ''}`} style={{
								width: '100%',
								height: '100%',
							}}>
								<img src={`/assets/img/${el}`} alt={`Project screen ${index + 1}`} />
							</div>
						)}
					</SwiperSlide>
				))}

				<button className="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false">Previous</button>
				<button className="slick-next slick-arrow" aria-label="Next" type="button" aria-disabled="false">Next</button>
			</Swiper>
		</div>
	)
}

Gallery.defaultProps = {
	images: [
		'slide-1.png',
		'slide-1.png',
		'slide-1.png',
	],
};

Gallery.propTypes = {
	images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Gallery;
