import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';


const Hero = (props) => {
	const [isSophisticated, setSophisticated] = useState(false);
	const [isTransactionCarrier, setTransactionCarrier] = useState(false);
	const { t } = useTranslation();
	const handleSubmit = (e) => {
		if (isSophisticated && !isTransactionCarrier) {
			props.setStep('sophisticated');
		} else {
			props.setStep('non-sophisticated');
		}
	};

	return (
		<Form noValidate className="form form--kyc" encType="multipart/form-data">
			<fieldset>
				<legend>{t('KYC.doYouWork')}</legend>

				<ul>
					<li>
						<label htmlFor="sophisticated_1">
							<Form.Check
								type="radio"
								name="set_1"
								id="sophisticated_1"
								checked={isSophisticated}
								onChange={() => setSophisticated(!isSophisticated)}
							/>

							{t('yes')}
						</label>

						<label htmlFor="sophisticated_2">
							<Form.Check
								type="radio"
								name="set_1"
								id="sophisticated_2"
								checked={!isSophisticated}
								onChange={() => setSophisticated(!isSophisticated)}
							/>

							{t('no')}
						</label>
					</li>
				</ul>
			</fieldset>

			<fieldset>
				<legend>{t('KYC.haveYouCarried')}</legend>

				<ul>
					<li>
						<label htmlFor="carried_1">
							<Form.Check
								type="radio"
								name="set_2"
								id="carried_1"
								checked={isTransactionCarrier}
								onChange={() => setTransactionCarrier(!isTransactionCarrier)}
							/>

							{t('yes')}
						</label>

						<label htmlFor="carried_2">
							<Form.Check
								type="radio"
								name="set_2"
								id="carried_2"
								checked={!isTransactionCarrier}
								onChange={() => setTransactionCarrier(!isTransactionCarrier)}
							/>

							{t('no')}
						</label>
					</li>
				</ul>
			</fieldset>

			<button type={'button'} onClick={handleSubmit}>{t('next')}</button>
		</Form>
	)
};

Hero.defaultProps = {
	setStep: () => {},
};

Hero.propTypes = {
	setStep: PropTypes.func.isRequired,
};

export default Hero;
