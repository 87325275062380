import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translation } from 'react-i18next';

import { getNotificationsAsync } from '../../redux/thunks/notificationThunk';
import Loader from '../../components/loader';


class Notifications extends React.Component {
	componentDidMount() {
		const {
			data,
		} = this.props;

		if (data.length === 0) {
			this.props.getNotificationsAsync();
		}
	}

	render() {
		const {
			status,
			data,
		} = this.props;

		return (
			<div id="notifications" className="row p-0 m-0">
				<div className="col p-0 m-0">
					<section className="title__big">
						<div className="container">
							<Translation>
								{ (t) => (<>
									<h1 data-echo={t('notifications.questionsss')}>
										{t('All')}
										&nbsp;
										<span>{t('Notifications')}</span>
										<br />
										&nbsp;
										{t('notifications.inOnePlace')}
									</h1>

									<input type="text" className="title__search" placeholder={t('Search')} />
								</>)}
							</Translation>
						</div>
					</section>

					<section>
						<div className="container">
							{status === 'loading'
								? <Loader />
								: (
									<>
										{data.filter(el => !el.isNew).map((el, index) => (
											<div key={`alert_${index}`} className={`alert alert--${el.type}`}>
												{el.type !== 'notification' && <div className="alert__icon"></div>}

												<Translation>
													{ (t) => (
														<div className="alert__content">
															<div className="alert__title">{t(`notifications.title${el.title}`)}</div>
															<div className="alert__date">{el.date}</div>
															<div className="alert__hint">{t(`notifications.body${el.body}`)} {el.bodyAdditional}</div>
														</div>
													) }
												</Translation>
											</div>
										))}

										<div className="new-messages">
											<Translation>{(t) => t('notifications.newMessages')}</Translation>
										</div>

										{data.filter(el => el.isNew).map((el, index) => (
											<div key={`alert_${index}`} className={`alert alert--${el.type}`}>
												{el.type !== 'notification' && <div className="alert__icon"></div>}

												<Translation>
													{ (t) => (
														<div className="alert__content">
															<div className="alert__title">{t(`notifications.title${el.title}`)}</div>
															<div className="alert__date">{el.date}</div>
															<div className="alert__hint">{t(`notifications.body${el.body}`)} {el.bodyAdditional}</div>
														</div>
													) }
												</Translation>
											</div>
										))}
									</>
								)
							}
						</div>
					</section>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		status: state.notifications.status,
		data: state.notifications.data,
	}
};

const mapDispatchToProps = {
	getNotificationsAsync,
};

Notifications.propTypes = {
	status: PropTypes.string.isRequired,
	data: PropTypes.array.isRequired,
	getNotificationsAsync: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
