const project = {
	info: 'Project information',
	amountRaised: 'Amount raised',
	investmentRaised: 'Investment raised',
	investmentAmount: 'Investment amount',
	minTarget: 'Min. target',
	daysLeft: 'Days left',
	interestRate: 'Interest rate',
	projectScore: 'Project score',
	loanTeam: 'Loan term',
	loanPurpose: 'Purpose of the loan',
	repaymentType: 'Repayment type',
	availableFunds: 'Your avaliable funds',
	companyName: 'Company Name',
	registrationNumber: 'Registration number',
	industry_nace: 'Industry (NACE)',
	borrowerGemos: 'See “Gemoss” full track record on Inlendo',
	financialAndScoring: 'Financials and Scoring',
	balanceSheet: 'Balance Sheet',
	longTermAssets: 'Long term assets',
	shortTermAssets: 'Short term assets',
	totalAssets: 'Total assets',
	interestBearing: 'Interest bearing liabilities',
	otherLiabilities: 'Other liabilities',
	totalLiabilities: 'Total liabilities',
	totalEquity: 'Total Equity+liabilities',
	incomeStatement: 'Income Statement',
	netRevenues: 'Net revenues',
	grossProfit: 'Gross profit',
	netProfit: 'Net profit',
	kpi: 'KPI’s',
	ebitdaMargin: 'EBITDA margin, %',
	debtEquityRatio: 'Debt/Equity ratio',
	netWorkingCapital: 'Net Working Capital, EUR',
	scoringBreakdown: 'Scoring Breakdown',
	negativePoints: 'Negative points',
	companyFinancials: 'Company financials',
	dealInformation: 'Deal Information',
	securityGuarantees: 'Security and guarantees',
	otherInformationFactors: 'Other company information factors',
	companyInfoTransparency: 'Company information and transparency',
	scoringPoints: 'Scoring points',
	scoringPointsEarned: 'Scoring points earned % of total',
	borrowerResponsibility: 'Borrower responsibility statement',
};

export default project;
