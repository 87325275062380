const borrower = [
	{
		id: 'EU095713',
		type: 'Borrower',
		firstName: 'Jhon',
		middleName: 'Jameson',
		lastName: 'Doe',
		balance: 1000000,
		currency: '€',
		KYCCompleted: true,
		borrowerCompleted: false,
		login: {
			email: 'user@borrower.com',
			password: 'borrower',
		},
	},
	{
		id: 'EU095713',
		type: 'Borrower',
		firstName: 'Andrew',
		middleName: 'Jameson',
		lastName: 'Willson',
		balance: 1000000,
		currency: '€',
		KYCCompleted: false,
		borrowerCompleted: false,
		login: {
			email: 'user@borrower2.com',
			password: 'borrower',
		},
	}
];

export default borrower;
