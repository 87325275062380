const project = [
	{
		id: 6228824,
		title: 'Vegan sweets production 1',
		amount: {
			raised: 7200,
			fulfilled: 320000,
			investment: 6120,
			available: 23000,
			min: 75000,
		},
		currency: '€',
		completed: 5,
		left: 23,
		rate: 14,
		term: 230,
		score: 'A+',
		ltv: 78,
		purpose: 'acquiring goods',
		security: 'Assignment of Proceeds',
		repayment: 'linear',
		gallery: [
			'slide-1.png',
			'slide-1.png',
			'slide-1.png',
		],
		borrower: {
			title: 'Score A',
			companyName: 'CHEVK Wagner',
			registrationNumber: '40103099092',
			country: 'Russia',
			industryNACE: 'Non-specialised wholesale of food, beverages and tobacco (46.39, version 2.0) (Source: SRS)',
			owners: 'John Peters 70%, Linda Murniece 20%, Others 10%',
			description: 'Large text, describing all the aspects of the company and ' +
				'business, written by us (admin), but is based on the information, provided by Borrower ' +
				'through onboarding and further communication',
		},
		finScore: {
			balanceSheet: [
				2020,
				2019,
				2018,
			],
			longTermAssets: [
				1350.000,
				1350.000,
				585.000,
			],
			shortTermAssets: [
				585.000,
				585.000,
				585.000,
			],
			totalAssets: [
				1935.000,
				1935.000,
				585.000,
			],
			interestBearing: [
				1515.000,
				1515.000,
				1515.000,
			],
			otherLiabilities: [
				1515.000,
				1515.000,
				1515.000,
			],
			totalLiabilities: [
				1935.000,
				1935.000,
				585.000,
			],
			totalEquity: [
				1935.000,
				1935.000,
				585.000,
			],
			incomeStatement: [
				2020,
				2019,
				2018,
			],
			netRevenues: [
				1350.000,
				1350.000,
				585.000,
			],
			cogs: [
				585.000,
				585.000,
				585.000,
			],
			grossProfit: [
				1935.000,
				1935.000,
				585.000,
			],
			netProfit: [
				1935.000,
				1935.000,
				585.000,
			],
			kpi: [
				2020,
				2019,
				2018,
			],
			ebitda: [
				1350.000,
				1350.000,
				585.000,
			],
			ebitdaMargin: [
				585.000,
				585.000,
				585.000,
			],
			debtEquityRatio: [
				1935.000,
				1935.000,
				585.000,
			],
			netWorkingCapital: [
				1935.000,
				1935.000,
				585.000,
			],
			negativePoints: -6,
			companyFinancials: 18,
			dealInformation: 32,
			securityGuarantees: 32,
			otherInformationFactors: 32,
			companyInfoTransparency: 32,
			scoringValue: 23,
		},
		stages: [
			{
				status: 'pending',
				date: '2022-01-20',
				stage: 'The letter for payment day received',
				comments: 'Photos and certificated are received by the platform. Please see ...',
			},
			{
				status: 'pending',
				date: '2022-01-20',
				stage: 'The letter for payment day received',
				comments: 'Photos and certificated are received by the platform. Please see ...',
			},
			{
				status: 'declined',
				date: '2022-01-20',
				stage: 'The letter for payment day received',
				comments: 'Photos and certificated are received by the platform. Please see ...',
			},
			{
				status: 'declined',
				date: '2022-01-20',
				stage: 'The letter for payment day received',
				comments: 'Photos and certificated are received by the platform. Please see ...',
			},
		],
		documents: [
			{
				date: '2022-01-20',
				type: 'Document 1',
				comments: 'Photos and certificated are received by the platform. Please see ...',
			},
			{
				date: '2022-01-20',
				type: 'Document 2',
				comments: 'Photos and certificated are received by the platform. Please see ...',
			},
		],
	},
	{
		id: 8228824,
		title: 'Vegan sweets production 2',
		amount: {
			raised: 10000,
			fulfilled: 100000,
			investment: 100000,
			available: 23000,
			min: 50000,
		},
		completed: 10,
		left: 26,
		rate: 17,
		term: 60,
		score: 'B+',
		ltv: 60,
		purpose: 'acquiring goods',
		security: 'Assignment of Proceeds',
		repayment: 'linear',
		gallery: [
			'slide-1.png',
			'slide-1.png',
			'slide-1.png',
		],
		borrower: {
			title: 'Score BA',
			companyName: 'SIA Gemoss',
			registrationNumber: '40103099092',
			country: 'Latvia',
			industryNACE: 'Non-specialised wholesale of food, beverages and tobacco (46.39, version 2.0) (Source: SRS)',
			owners: 'John Peters 70%, Linda Murniece 20%, Others 10%',
			description: 'Large text, describing all the aspects of the company and ' +
				'business, written by us (admin), but is based on the information, provided by Borrower ' +
				'through onboarding and further communication',
		},
		finScore: {
			balanceSheet: [
				2020,
				2019,
				2018,
			],
			longTermAssets: [
				1350.000,
				1350.000,
				585.000,
			],
			shortTermAssets: [
				585.000,
				585.000,
				585.000,
			],
			totalAssets: [
				1935.000,
				1935.000,
				585.000,
			],
			interestBearing: [
				1515.000,
				1515.000,
				1515.000,
			],
			otherLiabilities: [
				1515.000,
				1515.000,
				1515.000,
			],
			totalLiabilities: [
				1935.000,
				1935.000,
				585.000,
			],
			totalEquity: [
				1935.000,
				1935.000,
				585.000,
			],
			incomeStatement: [
				2020,
				2019,
				2018,
			],
			netRevenues: [
				1350.000,
				1350.000,
				585.000,
			],
			cogs: [
				585.000,
				585.000,
				585.000,
			],
			grossProfit: [
				1935.000,
				1935.000,
				585.000,
			],
			netProfit: [
				1935.000,
				1935.000,
				585.000,
			],
			kpi: [
				2020,
				2019,
				2018,
			],
			ebitda: [
				1350.000,
				1350.000,
				585.000,
			],
			ebitdaMargin: [
				585.000,
				585.000,
				585.000,
			],
			debtEquityRatio: [
				1935.000,
				1935.000,
				585.000,
			],
			netWorkingCapital: [
				1935.000,
				1935.000,
				585.000,
			],
			negativePoints: -6,
			companyFinancials: 18,
			dealInformation: 32,
			securityGuarantees: 32,
			otherInformationFactors: 32,
			companyInfoTransparency: 32,
			scoringValue: 42,
		},
		stages: [
			{
				status: 'approved',
				date: '2022-01-20',
				stage: 'The letter for payment day received',
				comments: 'Photos and certificated are received by the platform. Please see ...',
			},
			{
				status: 'pending',
				date: '2022-01-20',
				stage: 'The letter for payment day received',
				comments: 'Photos and certificated are received by the platform. Please see ...',
			},
			{
				status: 'declined',
				date: '2022-01-20',
				stage: 'The letter for payment day received',
				comments: 'Photos and certificated are received by the platform. Please see ...',
			},
			{
				status: 'approved',
				date: '2022-01-20',
				stage: 'The letter for payment day received',
				comments: 'Photos and certificated are received by the platform. Please see ...',
			},
		],
		documents: [
			{
				date: '2022-01-20',
				type: 'Document 1',
				comments: 'Photos and certificated are received by the platform. Please see ...',
			},
			{
				date: '2022-01-20',
				type: 'Document 2',
				comments: 'Photos and certificated are received by the platform. Please see ...',
			},
		],
	},
];

export default project;
