import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { persistor, store } from './app/store';
import reportWebVitals from './reportWebVitals';
import router from './routes';
import './app/i18n';


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
          <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                  <ThemeProvider>
                      <RouterProvider router={router} />
                  </ThemeProvider>
              </PersistGate>
          </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
