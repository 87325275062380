const aboutUS = {
	where: 'Where',
	money: 'Money',
	comes: 'Comes to work',
	ourRoadmap: 'Our Roadmap',
	teamImage: 'Team image',
	coreTeam: 'Core Team',
	aboutUs: 'About us...',
	regulatoryStatus: 'Regulatory status',
	contactUs: 'Our contacts',
	pleaseFillUpForm: 'Please fill up the form and our team will get back to you within 24 hours.',
	askAQuestion: 'Ask us a question',
	iConfirm: 'I confirm that I have read and agree to the',
	privacyPolicy: 'Privacy Policy',
	helloIWouldLike: 'Hello I would like to inquire about making my first investment',
	sendMessage: 'Send Message',
};

export default aboutUS;
