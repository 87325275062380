const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const countries = [
	{
		id: 1,
		title: 'Russia',
		iso: 'ru',
	},
	{
		id: 2,
		title: 'Latvia',
		iso: 'lv',
	},
	{
		id: 3,
		title: 'Germany',
		iso: 'de',
	},
];

const occupations = [
	{
		title: 'selfEmployed',
		value: '1',
	},
	{
		title: 'employed',
		value: '2',
	},
	{
		title: 'investor',
		value: '3',
	},
];

export {
	phoneRegExp,
	countries,
	occupations,
}
