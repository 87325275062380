import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import BorrowerItem from './accordion/borrower';
import FinandscoreItem from './accordion/finandscore';
import Stages from './accordion/stages';
import Documents from './accordion/documents';


const Info = (props) => {
	const {
		content,
		borrower,
		finScore,
		stages,
		documents,
	} = props;
	const [showContent, setShowContent] = useState(false);
	const { t, i18n } = useTranslation();

	return (
		<section className="content">
			<div className="container">
				<h2>{t('project.info')}</h2>

				<div className={`content__read ${showContent ? 'active' : ''}`} dangerouslySetInnerHTML={{ __html: content }} />
				
				<div className="content__readmore" onClick={() => setShowContent(!showContent)}>
					{showContent ? t('readLess') : t('readMore')}
				</div>

				<BorrowerItem {...borrower} />

				<FinandscoreItem {...finScore} />

				<Stages data={stages} />

				<Documents data={documents} />
			</div>
		</section>
	)
};

Info.defaultProps = {
	content: `
		<p>The loan is used to continue construction works.</p>
		<p>The loan will be repaid from the sale of the collateral property or refinancing by a long-term
		bank loan.</p>
		<p>The loan is secured with a first rank mortgage.</p>
		<p>The borrower retains the opportunity to raise additional capital if necessary, provided that the
		ratio of the loan and the market value of the collateral (LTV) does not exceed 56.0%.</p>
		<p>The established mortgage will also cover loans of additional stages of financing.</p>
		<p>Prior to the additional stages of financing, construction supervision must confirm the completed
		works (if construction has commenced and works have been performed in a considerable amount) and
		a third party must assess the market value of the collateral.</p>
		<p>The LTV in the 1 stage is 15.9% and can increase to 56.0% in further stages.</p>
	`,
	borrower: {
		title: 'Score BA',
		companyName: 'SIA Gemoss',
		registrationNumber: '40103099092',
		country: 'Latvia',
		industryNACE: 'Non-specialised wholesale of food, beverages and tobacco (46.39, version 2.0) (Source: SRS)',
		owners: 'John Peters 70%, Linda Murniece 20%, Others 10%',
		description: 'Large text, describing all the aspects of the company and ' +
			'business, written by us (admin), but is based on the information, provided by Borrower ' +
			'through onboarding and further communication',
	},
	finScore: {
		balanceSheet: [
			2020,
			2019,
			2018,
		],
		longTermAssets: [
			1350.000,
			1350.000,
			585.000,
		],
		shortTermAssets: [
			585.000,
			585.000,
			585.000,
		],
		totalAssets: [
			1935.000,
			1935.000,
			585.000,
		],
		interestBearing: [
			1515.000,
			1515.000,
			1515.000,
		],
		otherLiabilities: [
			1515.000,
			1515.000,
			1515.000,
		],
		totalLiabilities: [
			1935.000,
			1935.000,
			585.000,
		],
		totalEquity: [
			1935.000,
			1935.000,
			585.000,
		],
		incomeStatement: [
			2020,
			2019,
			2018,
		],
		netRevenues: [
			1350.000,
			1350.000,
			585.000,
		],
		cogs: [
			585.000,
			585.000,
			585.000,
		],
		grossProfit: [
			1935.000,
			1935.000,
			585.000,
		],
		netProfit: [
			1935.000,
			1935.000,
			585.000,
		],
		kpi: [
			2020,
			2019,
			2018,
		],
		ebitda: [
			1350.000,
			1350.000,
			585.000,
		],
		ebitdaMargin: [
			585.000,
			585.000,
			585.000,
		],
		debtEquityRatio: [
			1935.000,
			1935.000,
			585.000,
		],
		netWorkingCapital: [
			1935.000,
			1935.000,
			585.000,
		],
		negativePoints: -6,
		companyFinancials: 18,
		dealInformation: 32,
		securityGuarantees: 32,
		otherInformationFactors: 32,
		companyInfoTransparency: 32,
		scoringValue: 42,
	},
	stages: [
		{
			status: 'approved',
			date: '2022-01-20',
			stage: 'The letter for payment day received',
			comments: 'Photos and certificated are received by the platform. Please see ...',
		},
		{
			status: 'pending',
			date: '2022-01-20',
			stage: 'The letter for payment day received',
			comments: 'Photos and certificated are received by the platform. Please see ...',
		},
		{
			status: 'declined',
			date: '2022-01-20',
			stage: 'The letter for payment day received',
			comments: 'Photos and certificated are received by the platform. Please see ...',
		},
		{
			status: 'approved',
			date: '2022-01-20',
			stage: 'The letter for payment day received',
			comments: 'Photos and certificated are received by the platform. Please see ...',
		},
	],
	documents: [
		{
			date: '2022-01-20',
			type: 'Document 1',
			comments: 'Photos and certificated are received by the platform. Please see ...',
		},
		{
			date: '2022-01-20',
			type: 'Document 2',
			comments: 'Photos and certificated are received by the platform. Please see ...',
		},
	],
};

Info.propTypes = {
	content: PropTypes.string.isRequired,
	borrower: PropTypes.object.isRequired,
	finScore: PropTypes.object.isRequired,
	stages: PropTypes.arrayOf(PropTypes.object).isRequired,
	documents: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Info;
