import React from 'react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/keyboard';

import Banner from './banner';
import HowTo from './howTo';
import Projects from './projects';
import Invest from './invest';
import Freedom from './freedom';
import Together from './together';
import Blog from './blog';


class Index extends React.Component {
	render() {
		return (
			<div className="row p-0 m-0">
				<div className="col p-0 m-0">
					<Banner />

					<Projects />

					<Invest />

					<HowTo />

					<Freedom />

					<Together />

					<Blog />
				</div>
			</div>
		);
	}
}

export default Index;
