import React from 'react';

import Index from '../pages/index';
import Faq from '../pages/faq';
import AboutUs from '../pages/about-us';
import ContactUs from '../pages/contact-us';
import Blogs from '../pages/blogs';
import Blog from '../pages/blog';
import Landing from '../pages/landing';
import UnderConstruction from '../components/HTML_pages/underconstruction';

/**
 * Routes for unauthorized users
 */
const mainRoutes = [
	{
		path: '/',
		element: <Index />
	},
	{
		path: '/faq',
		element: <Faq />
	},
	{
		path: '/about-us',
		element: <AboutUs />
	},
	{
		path: '/contact-us',
		element: <ContactUs />
	},
	{
		path: '/blogs',
		element: <Blogs />
	},
	{
		path: '/blog/:blogId',
		element: <Blog />,
	},
	{
		path: '/landing',
		element: <Landing />
	},
	{
		path: '/how-it-works',
		element: <UnderConstruction />,
	},
	{
		path: '/for-borrowers',
		element: <UnderConstruction />,
	},
];

export default mainRoutes;
