import React from 'react';

import { ReactComponent as SVG1 } from '../../assets/img/how-it-works.svg';
import { ReactComponent as SVG2 } from '../../assets/img/how-it-works--mobile.svg';

const HowTo = () => {
	return (
		<section className="how-it-works">
			<div className="container">
				<h2>How it works<span>.</span></h2>

				<SVG1 className="how-it-works__img only--pc" />
				<SVG2 className="how-it-works__img only--mobile" />

				<div className="btn__wrap">
					<a href="" className="btn btn--big">Learn more</a>
				</div>
			</div>
		</section>
	)
};

export default HowTo;
