import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Keyboard } from 'swiper';


const Projects = () => {
	const projects = [
		{
			img: 'card__img.png',
			id: '00259',
			class: 'B1',
			title: 'Vegan sweets production',
			price: '22,710',
			priceSecondary: '30% out of €150,000',
			priceLeft: 20,
			rate: 14,
			term: '24 month',
			left: 15,
		},
		{
			img: 'card__img--alt.png',
			id: '00260',
			class: 'B1',
			title: 'Vegan sweets production',
			price: '22,710',
			priceSecondary: '30% out of €150,000',
			priceLeft: 20,
			rate: 14,
			term: '24 month',
			left: 15,
		},
		{
			img: 'card__img.png',
			id: '00261',
			class: 'B1',
			title: 'Vegan sweets production',
			price: '22,710',
			priceSecondary: '30% out of €150,000',
			priceLeft: 20,
			rate: 14,
			term: '24 month',
			left: 15,
		},
		{
			img: 'card__img--alt.png',
			id: '00262',
			class: 'B1',
			title: 'Vegan sweets production',
			price: '22,710',
			priceSecondary: '30% out of €150,000',
			priceLeft: 20,
			rate: 14,
			term: '24 month',
			left: 15,
		},
	];

	return (
		<section id="projects">
			<div className="container">
				<h2>Our amazing projects<span>.</span></h2>

				<Swiper
					spaceBetween={50}
					slidesPerView={3}
					modules={[Navigation, Keyboard]}
					effect={'fade'}
					onSlideChange={() => console.log('slide change')}
					onSwiper={(swiper) => console.log(swiper)}
					className="card__slider"
					navigation={{
						nextEl: '.slick-next',
						prevEl: '.slick-prev',
						disabledClass: 'swiper-button-disabled'
					}}
					keyboard
					watchSlidesProgress>
					{projects.map((el) => (
						<SwiperSlide key={el.id}>
							{({ isVisible }) => (
								<div className={`card slick-slide ${isVisible ? 'slick-active' : ''}`}>
									<div className="card__img">
										<img src={`/assets/img/${el.img}`} alt="Card" />
									</div>

									<div className="card__header">
										<div className="card__flex">
											<div className="card__id"><span>id</span>{el.id}</div>
											<div className="card__class">{el.class}</div>
										</div>
										<div className="card__title">{el.title}</div>
									</div>

									<div className="card__flex">
										<div className="card__price">
											<div className="card__price-main">€{el.price}</div>
											<div className="card__price-secondary">{el.priceSecondary}</div>
										</div>

										<div className="card__round">
											<svg className="card__progress" width="92" height="92">
												<circle className="ready__circle" stroke="#468aff" strokeWidth="8" strokeDasharray="300" fill="transparent" strokeLinecap="round" r="42" cx="46" cy="46"></circle>
											</svg>

											<div className="card__rate"><span>{el.priceLeft}%</span>left</div>
										</div>
									</div>

									<div className="card__flex card__rates">
										<div className="card__rate"><span>{el.rate}%</span>rate</div>
										<div className="card__rate"><span>{el.term} month</span>Term</div>
										<div className="card__rate"><span>{el.left}</span>Days left</div>
									</div>

									<div className="card__btn">
										<a className="btn">More info</a>
									</div>
								</div>
							)}
						</SwiperSlide>
					))}

					<button className="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false">Previous</button>
					<button className="slick-next slick-arrow" aria-label="Next" type="button" aria-disabled="false">Next</button>
				</Swiper>

				<div className="btn__wrap">
					<a href="" className="btn btn--big">See all projects</a>
				</div>
			</div>
		</section>
	)
};

export default Projects;
