import { URL } from '../../config/const';
import blogs from '../../mocks/blogs';

const urls = {
	getBlogs: `${URL}/blogs`,
}

const getBlogs = async (params) => {
	const data = { ...blogs };

	if (params.page === 0) {
		data.items = [
			blogs.items[0],
			blogs.items[1],
			blogs.items[2],
		]
	} else {
		data.items = [
			blogs.items[3],
			blogs.items[4],
			blogs.items[5],
		]
	}

	return new Promise((resolve) =>
		setTimeout(() => resolve({
			data
		}), 1500)
	);

	// return new Promise((resolve, reject) =>
	// 	// eslint-disable-next-line prefer-promise-reject-errors
	// 	setTimeout(() => reject({
	// 		data: {
	// 			error: 'Incorrect credentials!'
	// 		}
	// 	}), 1500)
	// );
};

export {
	urls,
	getBlogs,
}
