import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Item from './item';


const List = (props) => {
	const [filters, setFilters] = useState(new Array(6).fill(undefined));
	const {
		data,
	} = props;
	const { t } = useTranslation();
	const updateFilters = (index) => {
		const tmp = [...filters];

		if (tmp[index] === 'desc') {
			tmp[index] = 'asc';
		} else if (typeof tmp[index] === 'undefined') {
			tmp[index] = 'desc';
		} else if (tmp[index] === 'asc') {
			tmp[index] = undefined;
		}

		setFilters(tmp);
	}

	return (
		<div className="list__content">
			<div className="filter filter--wide">
				<div className="filter__item" data-sort={filters[0]} onClick={() => updateFilters(0)}>{t('Project')}</div>
				<div className="filter__item" data-sort={filters[1]} onClick={() => updateFilters(1)}>{t('date')}</div>
				<div className="filter__item" data-sort={filters[2]} onClick={() => updateFilters(2)}>{t('amount')}</div>
				<div className="filter__item" data-sort={filters[3]} onClick={() => updateFilters(3)}>{t('finances.ratePA')}</div>
				<div className="filter__item" data-sort={filters[4]} onClick={() => updateFilters(4)}>{t('finances.mainDebt')}</div>
				<div className="filter__item" data-sort={filters[5]} onClick={() => updateFilters(5)}>{t('remaining')}</div>
				<div className="filter__sep"></div>
			</div>

			<div className="list__rows">
				{data.map((el, index) => (
					<Item
						key={`financeProject_${index}`}
						currency={'€'}
						id={el.id}
						date={el.date}
						rate={el.rate}
						amount={el.amount}
						items={el.items}
					/>
				))}
			</div>
		</div>
	)
}

List.defaultProps = {};

List.propTypes = {
	data: PropTypes.array,
};

export default List;
