import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';


const Sidebar = (props) => {
	const { t } = useTranslation();
	const [isTitleActive, setTitleActive] = useState(true);
	const [isDropdownActive, setDropdownActive] = useState(false);
	const [countriesState, setCountriesState] = useState([]);
	const [selectAllState, setSelectAllState] = useState(false);
	const countries = [
		'Czech Republic',
		'Kazakhstan',
		'Lithuania',
		'Moldova',
		'Philippines',
	];
	const changeCountry = (countryIndex) => {
		const tmp = [...countriesState];

		tmp[countryIndex] = !tmp[countryIndex];

		setCountriesState(tmp);
	};
	const selectAll = () => {
		setSelectAllState(!selectAllState);

		setCountriesState(new Array(countries.length).fill(!selectAllState));
	};

	return (
		<>
			<div className="sidebar__showfilters only--mobile">{t('finances.showFilters')}</div>

			<div className="sidebar">
				<div className="sidebar__title">{t('finances.filterName')}</div>

				<div className="select__wrap">
					<div className={`select__title ${isTitleActive ? 'active' : ''}`} onClick={() => setTitleActive(!isTitleActive)}>
						{countriesState.filter(el => el === true).length} {t('finances.selected')}
					</div>

					<div className={`select__dropdown ${isDropdownActive ? 'active' : ''}`}>
						<div className={`select__all ${selectAllState ? 'active' : ''}`} onClick={() => selectAll()}>
							{t('finances.selectAll')}
						</div>

						<Form>
							{countries.map((el, index) => (
								<Form.Label htmlFor={`country_${index}`} key={`country_${index}`}>
									{el}
									<Form.Check
										type="checkbox"
										id={`country_${index}`}
										className="d-flex"
										checked={typeof countriesState[index] === 'undefined' ? false : countriesState[index]}
										onChange={() => changeCountry(index)}
									/>
								</Form.Label>
							))}
						</Form>

						<div className="select__btn" onClick={() => setDropdownActive(!isDropdownActive)}>
							{isDropdownActive ? t('finances.showLess') : t('finances.showMore')}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Sidebar;
