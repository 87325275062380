import { URL } from '../../config/const';

const urls = {
	getDashboard: (id) => `${URL}/projects/${id}`,
}

const getDashboard = async (params) => {
	console.log('params', params)
	const data = {
	};

	return new Promise((resolve) =>
		setTimeout(() => resolve({
			data
		}), 1500)
	);

	// return new Promise((resolve, reject) =>
	// 	// eslint-disable-next-line prefer-promise-reject-errors
	// 	setTimeout(() => reject({
	// 		data: {
	// 			error: 'Incorrect credentials!'
	// 		}
	// 	}), 1500)
	// );
};

export {
	urls,
	getDashboard,
}
