const footer = {
	subscribeToNews: 'Subscribe to our newsletter',
	enterEmail: 'Enter your email',
	landing: 'Landing',
	aboutUs: 'About us',
	loanOriginators: 'Loan originators',
	newsAndUpdates: 'News & updates',
	annualReports: 'Annual reports',
	userTerms: 'User terms',
	privacyPolicy: 'Privacy policy',
	generalLoanTerms: 'General loan terms',
	cookiePolicy: 'Cookie policy',
	policyAvoidance: 'Policy of avoidance',
	investorRisk: 'Investor risk statement',
	lemonWayTC: 'Lemon Way - T&C',
	legalOpinion: 'Legal opinion',
	helpCenter: 'Help Center',
	companyDetails: 'Company details',
	legalAddress: 'Legal address',
	contactUs: 'Contact us',
	registrationNumber: 'Registration number',
	paymentOperator: 'Payment operator',
	partnerOf: 'Partner of',
	lemonWay: 'Lemon Way',
	europeanPayment: ', a European payment institution accredited in France by the ACPR (CIB 16568).',
	copyright: 'Copyright © Inlendo 2022',
	documents: 'My documents',
};

export default footer;
