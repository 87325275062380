import project from '../project';

const projects = [
	{
		title: project[0].title,
		projectId: project[0].id,
		repaymentProgress: 80,
		amount: project[0].amount.investment,
		currency: '€',
		rate: project[0].rate,
		term: project[0].term,
		date: '16.03.2022',
	},
	{
		title: project[1].title,
		projectId: project[1].id,
		repaymentProgress: 40,
		amount: project[0].amount.investment,
		currency: '€',
		rate: project[1].rate,
		term: project[1].term,
		date: '21.03.2023',
	},
];

export default projects;
