import { URL } from '../../config/const';
import notifications from '../../mocks/notifications';

const urls = {
	getNotifications: `${URL}/notifications`,
}

const getNotifications = async (params) => {
	const data = [...notifications];

	return new Promise((resolve) =>
		setTimeout(() => resolve({
			data
		}), 1500)
	);

	// return new Promise((resolve, reject) =>
	// 	// eslint-disable-next-line prefer-promise-reject-errors
	// 	setTimeout(() => reject({
	// 		data: {
	// 			error: 'Incorrect credentials!'
	// 		}
	// 	}), 1500)
	// );
};

export {
	urls,
	getNotifications,
}
