import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Item = (props) => {
	const [isActive, setActive] = useState(false);
	const {
		title,
		subtitle,
		currency,
		balance,
		rows,
		icon
	} = props;

	return (
		<div className={`dash__item ${isActive ? 'active' : ''}`}>
			<div className="dash__header">
				<div className="dash__img">
					{icon}
				</div>
				<div className="dash__title">{title}</div>
			</div>

			<div className="dash__price">{currency}{balance}</div>
			<div className="dash__hint">{subtitle}</div>

			<Link to={'#'} className="dash__btn" onClick={() => setActive(!isActive)}>More info</Link>

			<div className="dash__subitem">
				{rows.map((el, index) => (
					<div className="dash__subrow" key={`subitem_${title.toLowerCase().replaceAll(' ', '')}_${index}`}>
						<span>{el.title}</span>
						<span>{el.subtitle}</span>
					</div>
				))}
			</div>
		</div>
	)
};

Item.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	currency: PropTypes.string,
	balance: PropTypes.string,
	rows: PropTypes.array,
	icon: PropTypes.element,
};

Item.defaultProps = {
	title: 'Account Information',
	subtitle: 'Avaliable balance',
	currency: '€',
	balance: '10000',
	rows: [
		{
			title: 'Account Value',
			subtitle: '€10,000',
		},
		{
			title: 'Account Value',
			subtitle: '€10,000',
		},
		{
			title: 'Account Value',
			subtitle: '€10,000',
		},
		{
			title: 'Account Value',
			subtitle: '€10,000',
		},
	],
	icon: <div></div>
};

export default Item;
