import React from 'react';
import {
	useLoaderData,
	useLocation,
	useNavigate,
	useParams,
} from 'react-router-dom';

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		const location = useLocation();
		const navigate = useNavigate();
		const params = useParams();
		const loader = useLoaderData();

		return (
			<Component
				{...props}
				router={{ location, navigate, params, loader }}
			/>
		);
	}

	return ComponentWithRouterProp;
}

export default withRouter;
