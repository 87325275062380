import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';


const AddFounds = (props) => {
	const {
		bank,
		bankAccount,
		beneficiaryAddress,
		beneficiaryBank,
		beneficiaryRegistration,
		depositID,
		assignedID,
		qrCode,
	} = props;
	const { t } = useTranslation();

	return (
		<div className="investment__row">
			<div className="investment__col">
				<div className="investment__item">
					<div className="investment__title">{t('deposit.addFounds')}</div>

					<div className="investment__sep"></div>
					<div className="investment__inner">
						<div className="investment__text">{t('bank')}</div>
						<div className="investment__text"><b>{bank}</b></div>
					</div>

					<div className="investment__inner">
						<div className="investment__text">{t('deposit.bankAccount')}</div>
						<div className="investment__text"><b>{bankAccount}</b></div>
					</div>

					<div className="investment__inner">
						<div className="investment__text">{t('deposit.beneficiaryAddress')}</div>
						<div className="investment__text"><b>{beneficiaryAddress}</b></div>
					</div>

					<div className="investment__inner">
						<div className="investment__text">{t('deposit.beneficiaryBank')}</div>
						<div className="investment__text"><b>{beneficiaryBank}</b></div>
					</div>

					<div className="investment__inner">
						<div className="investment__text">{t('deposit.beneficiaryName')}</div>
						<div className="investment__text"><b>Inlendo</b></div>
					</div>

					<div className="investment__inner">
						<div className="investment__text">{t('deposit.beneficiaryRegistration')}</div>
						<div className="investment__text"><b>{beneficiaryRegistration}</b></div>
					</div>

					<div className="investment__inner">
						<div className="investment__text">{t('deposit.paymentDetails')}</div>
						<div className="investment__text">
							<b>
								{t('deposit.depositTo')}
								{depositID}
							</b>
						</div>
					</div>
					<div className="investment__sep"></div>

					<div className="investment__subtext">
						{t('deposit.assignedId')}
						{assignedID}
						{t('deposit.fundsAreCredited')}
					</div>

					<div className="investment__subtext">
						{t('deposit.makingPayment')}
					</div>
				</div>
			</div>

			<div className="investment__col">
				<div className="investment__item">
					<div className="investment__img">
						<img src={`/assets/img/${qrCode}`} alt="qr code"/>
					</div>
				</div>
			</div>
		</div>
	)
}

AddFounds.defaultProps = {
	bank: 'Paysera LT UAB',
	bankAccount: 'LV0780280942043E704028',
	beneficiaryAddress: '21st Street New York, USA',
	beneficiaryBank: 'HABA1234',
	beneficiaryRegistration: '189279403033',
	depositID: '494398',
	assignedID: '405597',
	qrCode: 'qr.svg',
};

AddFounds.propTypes = {
	bank: PropTypes.string,
	bankAccount: PropTypes.string,
	beneficiaryAddress: PropTypes.string,
	beneficiaryBank: PropTypes.string,
	beneficiaryRegistration: PropTypes.string,
	depositID: PropTypes.string,
	assignedID: PropTypes.string,
	qrCode: PropTypes.string,
};

export default AddFounds;
