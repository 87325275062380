import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Card, Item } from './item';


const Invest = (props) => {
	const {
		asInvestor,
	} = props;
	const { t } = useTranslation();

	return (
		<Card>
			<Item>
				<div className="investment__subtext">{t('invest.byAccepting')} {asInvestor} {t('invest.toTransferFunds')}
				</div>
				<div className="investment__btn accept" onClick={() => props.makeInvestment()}>{t('invest.acceptInvest')}</div>
			</Item>
		</Card>
	)
};

Invest.defaultProps = {
	asInvestor: 'Inlendo A/S',
	makeInvestment: () => {},
};

Invest.propTypes = {
	asInvestor: PropTypes.string.isRequired,
	makeInvestment: PropTypes.func.isRequired,
};

export default Invest;
