import { createAsyncThunk } from '@reduxjs/toolkit';

import { loginUser } from '../API/auth';


export const loginUserAsync = createAsyncThunk(
	'auth/loginUser',
	async (data, { rejectWithValue }) => {
		try {
			const response = await loginUser(data);
			return response.data
		} catch (err) {
			return rejectWithValue(err.data.error)
		}
	}
);
