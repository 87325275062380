const documents = [
	{
		title: 'Document 1',
		url: '#',
	},
	{
		title: 'Document 2',
		url: '#',
	},
];

const statements = [
	{
		title: 'Statement 1',
		date: '09/12/2019 - 07/08/2022',
	},
	{
		title: 'Statement 2',
		date: '09/12/2021 - 07/08/2022',
	},
	{
		title: 'Statement 3',
		date: '09/12/2023 - 07/08/2023',
	},
];

export {
	documents,
	statements,
};
