import React from 'react';
import { useSelector } from 'react-redux';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import NotFoundPageError from '../components/HTML_pages/404';
import RootError from './rootError';
import { isAuth } from '../redux/reducers/authSlice';


const RootErrorBoundary = (props) => {
	const isAuthState = useSelector(state => isAuth(state))
	const data = useSelector(state => state.auth.data)
	const error = useRouteError();

	if (isRouteErrorResponse(error)) {
		if (error.status === 404) {
			return (
				<RootError isAuth={isAuthState} {...data}>
					<NotFoundPageError />
				</RootError>
			);
		}

		if (error.status === 401) {
			return <div>You aren&apos;t authorized to see this</div>;
		}

		if (error.status === 503) {
			return <div>Looks like our API is down</div>;
		}

		if (error.status === 418) {
			return <div>🫖</div>;
		}
	}

	return <div>Something went wrong</div>;
}

export default RootErrorBoundary;
