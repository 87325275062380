import React from 'react';

import Banner from './banner';
import DashboardComponent from '../../components/dashboard/';
import Items from './Items';
import Account from './account';
import Offers from './offers';
import { getOffers } from '../../redux/API/projects';


class Dashboard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			projects: [],
		};
	}

	componentDidMount() {
		this.getProjects();
	}

	async getProjects() {
		const response = await getOffers();

		this.setState({
			projects: response.data,
			isLoading: false,
		});
	}

	render() {
		const {
			projects,
		} = this.state;

		return (
			<div id="dashboard" className="row p-0 m-0">
				<div className="col p-0 m-0">
					<DashboardComponent className={''}>
						<Banner
							showProgress
							title={'Vegan sweets production'}
							text={'29% invested'}
							btnText={'Invest'}
							link={'/invest'}
						/>
					</DashboardComponent>

					<Items />

					<Account />

					<Offers
						items={projects}
					/>
				</div>
			</div>
		)
	}
}

export default Dashboard;
