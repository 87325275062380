import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FaqItem from '../faq';
import LogoDark from '../../../assets/img/logo-dark.svg';


const Borrower = (props) => {
	const { t, i18n } = useTranslation();
	const {
		title,
		companyName,
		registrationNumber,
		country,
		industryNACE,
		owners,
		description,
	} = props;

	return (
		<FaqItem title={t('borrower')}>
			<div className="in__wrap">
				<div className="in__row in__row--start">
					<img src={LogoDark} alt="Inlendo" />
					<div className="in__title">{title}</div>
				</div>

				<div className="in__blue">{t('project.borrowerGemos')}</div>
				<div className="in__sep"></div>
				<div className="in__row">
					<span>{t('project.companyName')}:</span>
					<span>{companyName}</span>
				</div>

				<div className="in__row">
					<span>{t('project.registrationNumber')}:</span>
					<span>{registrationNumber}</span>
				</div>

				<div className="in__row">
					<span>{t('country')}:</span>
					<span>{country}</span>
				</div>

				<div className="in__row">
					<span>{t('project.industry_nace')}:</span>
					<span>{industryNACE}</span>
				</div>

				<div className="in__row">
					<span>{t('owners')}:</span>
					<span>{owners}</span>
				</div>

				<div className="in__description">
					{description}
				</div>
			</div>
		</FaqItem>
	)
};

Borrower.defaultProp = {
	title: 'Score BA',
	companyName: 'SIA Gemoss',
	registrationNumber: '40103099092',
	country: 'Latvia',
	industryNACE: 'Non-specialised wholesale of food, beverages and tobacco (46.39, version 2.0) (Source: SRS)',
	owners: 'John Peters 70%, Linda Murniece 20%, Others 10%',
	description: 'Large text, describing all the aspects of the company and ' +
		'business, written by us (admin), but is based on the information, provided by Borrower ' +
		'through onboarding and further communication',
};

Borrower.propTypes = {
	title: PropTypes.string,
	companyName: PropTypes.string,
	registrationNumber: PropTypes.string,
	country: PropTypes.string,
	industryNACE: PropTypes.string,
	owners: PropTypes.string,
	description: PropTypes.string,
};

export default Borrower;
