import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import mainRoutes from './main';
import authRoutes from './auth';
import userRoutes from './user';
import RootErrorBoundary from '../components/rootErrorBoundary';
import App from '../app/App';
import { isAuth } from '../redux/reducers/authSlice';


const PrivateWrapper = () => {
	const isAuthState = useSelector(state => isAuth(state))
	return isAuthState ? <App /> : <Navigate to='/login' />;
};

const router = createBrowserRouter([
	{
		element: <App />,
		errorElement: <RootErrorBoundary />,
		children: [
			...mainRoutes,
			...authRoutes,
		],
	},
	{
		element: <PrivateWrapper />,
		errorElement: <RootErrorBoundary />,
		children: [
			...userRoutes,
		],
	},
]);


export default router;
