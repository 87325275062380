import { URL } from '../../config/const';

const urls = {
	getDeposit: `${URL}/deposit`,
}

const getDeposit = async (params) => {
	console.log('params', params)
	const data = {
		bank: 'Paysera LT UAB',
		bankAccount: 'LV0780280942043E704028',
		beneficiaryAddress: '21st Street New York, USA',
		beneficiaryBank: 'HABA1234',
		beneficiaryRegistration: '189279403033',
		depositID: '494398',
		assignedID: '405597',
		qrCode: 'qr.svg',
		minDeposit: '50 EUR',
		netIncome: 10,
	};

	return new Promise((resolve) =>
		setTimeout(() => resolve({
			data
		}), 1500)
	);

	// return new Promise((resolve, reject) =>
	// 	// eslint-disable-next-line prefer-promise-reject-errors
	// 	setTimeout(() => reject({
	// 		data: {
	// 			error: 'Incorrect credentials!'
	// 		}
	// 	}), 1500)
	// );
};

export {
	urls,
	getDeposit,
}
