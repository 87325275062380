import React, { useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';


const Select = (props) => {
	const selectRef = useRef();
	const [isActive, setActive] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const {
		text,
		ariaLabel,
		items,
		name,
	} = props;
	const { t } = useTranslation();
	const selectItem = (item) => {
		setSelectedItem(item);
		setActive(false);

		setTimeout(() => {
			const e = new Event('change', { bubbles: true });
			selectRef.current.dispatchEvent(e);
		}, 25)

		props.onSelect(item);
	}

	return (
		<div className="select__custom">
			<Form.Select
				ref={selectRef}
				aria-label={typeof ariaLabel !== 'undefined' ? ariaLabel : text}
				name={name}
				value={(selectedItem && selectedItem.value) || ''}
				onChange={props.onChange}>
				{items.map((el, index) => (
					<option key={`select_${index}`} value={el.value}>{el.title}</option>
				))}
			</Form.Select>

			<div
				className={`select-selected ${isActive ? 'select-arrow-active' : ''}`}
				onClick={() => setActive(!isActive)}>
				{(selectedItem && selectedItem.title) || text}
			</div>

			<div className={`select-items ${isActive ? '' : 'select-hide'}`}>
				{items.map((el, index) => (
					<div onClick={() => selectItem(el)} key={`select_${index}`}>{el.title}</div>
				))}
			</div>
		</div>
	)
}

Select.defaultProps = {
	name: 'countryOfResidence',
	text: 'Select country',
	ariaLabel: '',
	items: [
		{
			title: 'Germany',
			value: 'de',
		},
		{
			title: 'Latvia',
			value: 'lv',
		},
		{
			title: 'Russia',
			value: 'ru',
		},
	],
	onSelect: () => {},
	onChange: () => {},
};

Select.propTypes = {
	name: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	items: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string.isRequired,
		value: PropTypes.any.isRequired,
	})).isRequired,
	onSelect: PropTypes.func,
	onChange: PropTypes.func,
	ariaLabel: PropTypes.string,
};

export default Select;
