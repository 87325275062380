import React from 'react'

import CardImg from '../../assets/img/card__img.png';
import CardImgAlt from '../../assets/img/card__img--alt.png';

const Banner = () => {
  return (
		<section className="banner">
			<div className="container">
				<div className="banner__content">
					<h1>Trade finance<br /> you will enjoy<span>.</span></h1>
					<h3>Invest, sell and control your entire<br /> financial activity - in one place.</h3>

					<div className="btns">
						<div className="btn btn--big">Get started</div>
						<div className="btn btn--big alt">See How it Works</div>
					</div>

					<div className="banner__counter">9,204,000</div>
					<div className="banner__hint">users trust us, powered by lemonway.</div>
				</div>

				<div className="banner__cards">
					<div className="card">
						<div className="card__img">
							<img src={CardImg} alt="Card" />
						</div>

						<div className="card__header">
							<div className="card__flex">
								<div className="card__id"><span>id</span>00259</div>
								<div className="card__class">B1</div>
							</div>
							<div className="card__title">Vegan sweets production</div>
						</div>

						<div className="card__flex">
							<div className="card__price">
								<div className="card__price-main">€22,710</div>
								<div className="card__price-secondary">30% out of €150,000</div>
							</div>

							<div className="card__round">
								<svg className="card__progress" width="92" height="92">
									<circle className="ready__circle" stroke="#468aff" strokeWidth="8" strokeDasharray="300" fill="transparent" strokeLinecap="round" r="42" cx="46" cy="46"></circle>
								</svg>
								<div className="card__rate"><span>20%</span>left</div>
							</div>
						</div>

						<div className="card__flex card__rates">
							<div className="card__rate"><span>14%</span>rate</div>
							<div className="card__rate"><span>24 month</span>Term</div>
							<div className="card__rate"><span>15</span>Days left</div>
						</div>

						<div className="card__btn">
							<a className="btn">More info</a>
						</div>
					</div>
					<div className="card">
						<div className="card__img">
							<img src={CardImgAlt} alt="Card" />
						</div>

						<div className="card__header">
							<div className="card__flex">
								<div className="card__id"><span>id</span>00260</div>
								<div className="card__class">B1</div>
							</div>
							<div className="card__title">Vegan sweets production</div>
						</div>

						<div className="card__flex">
							<div className="card__price">
								<div className="card__price-main">€22,710</div>
								<div className="card__price-secondary">30% out of €150,000</div>
							</div>
							<div className="card__round">
								<svg className="card__progress" width="92" height="92">
									<circle className="ready__circle" stroke="#468aff" strokeWidth="8" strokeDasharray="300" fill="transparent" strokeLinecap="round" r="42" cx="46" cy="46"></circle>
								</svg>
								<div className="card__rate"><span>20%</span>left</div>
							</div>
						</div>

						<div className="card__flex card__rates">
							<div className="card__rate"><span>14%</span>rate</div>
							<div className="card__rate"><span>24 month</span>Term</div>
							<div className="card__rate"><span>15</span>Days left</div>
						</div>

						<div className="card__btn">
							<a className="btn">More info</a>
						</div>
					</div>
				</div>
			</div>
		</section>
  )
}

export default Banner
