import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';


const Item = (props) => {
	const {
		minDeposit,
		netIncome,
	} = props;
	const { t } = useTranslation();

	return (
		<div className="investment__item">
			<div className="investment__title">{t('deposit.accountLimit')}</div>
			<div className="investment__sep"></div>
			<div className="investment__inner">
				<div className="investment__text"><b>{t('deposit.minimumInvestment')}</b></div>
				<div className="investment__text">{minDeposit}</div>
			</div>

			<div className="investment__sep"></div>
			<div className="investment__inner">
				<div className="investment__text"><b>{t('deposit.maximumInvestment')}</b></div>
			</div>

			<div className="investment__inner">
				<div className="investment__text">{t('deposit.nonSophisticatedInvestor')}</div>
				<div className="investment__text">{netIncome}% {t('deposit.netIncome')}</div>
			</div>

			<div className="investment__inner">
				<div className="investment__text">{t('deposit.sophisticatedInvestor')}</div>
				<div className="investment__text">{t('deposit.noRestrictions')}</div>
			</div>

			<div className="investment__inner">
				<div className="investment__text">{t('deposit.individualEntrepreneurs')}</div>
				<div className="investment__text">{t('deposit.noRestrictions')}</div>
			</div>

			<div className="investment__inner">
				<div className="investment__text">{t('company')}</div>
				<div className="investment__text">{t('deposit.noRestrictions')}</div>
			</div>

			<div className="investment__sep"></div>
			<div className="investment__inner">
				<div className="investment__text"><b>{t('deposit.nominalBankAccount')}</b></div>
			</div>

			<p>
				{t('deposit.itemHelpText')}
			</p>
		</div>
	)
};

Item.defaultProps = {
	minDeposit: '50 EUR',
	netIncome: 10,
};

Item.propTypes = {
	minDeposit: PropTypes.string,
	netIncome: PropTypes.number,
};

export default Item;
