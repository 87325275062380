import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import * as Yup from 'yup';
import Button from 'react-bootstrap/Button';

import { ReactComponent as WhatsUp } from '../assets/img/icons/whatsapp-w.svg';
import { ReactComponent as Telegram } from '../assets/img/icons/telegram-w.svg';
import { ReactComponent as Facebook } from '../assets/img/icons/facebook-w.svg';
import { phoneRegExp } from '../app/misc';


const schema = Yup.object().shape({
	name: Yup
		.string()
		.required()
		.max(200),
	email: Yup
		.string()
		.email()
		.required()
		.matches(
			/^[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]{2,}$/,
			'Please provide valid email address'
		),
	phone: Yup
		.string()
		.required()
		.matches(phoneRegExp, 'Phone number is not valid'),
	message: Yup
		.string()
		.required()
		.max(1000)
});

const ContactUs = (props) => {
	const { t } = useTranslation();

	return (
		<section className="contact-us">
			<div className="container">
				<h2>Contact us<span>.</span></h2>

				<div className="contact-us__content">
					<div className="contact-us__info">
						<div className="contact-us__title">{t('aboutUS.contactUs')}</div>

						<p>{t('aboutUS.pleaseFillUpForm')}</p>
						<p><b>{t('phone')}:</b> +371 2 875 6612</p>
						<p><b>{t('email')}:</b> support@inlendo.com</p>
						<p><b>{t('address')}:</b> Lutrinu iela 1A, Riga, LV-1002, Latvia</p>

						<div className="contact-us__socials">
							<b>{t('aboutUS.askAQuestion')}:&nbsp;&nbsp;</b>
							<a href="#">
								<WhatsUp alt={'What\'s up'} />
							</a>

							<a href="#">
								<Telegram alt={'Telegram'} />
							</a>

							<a href="#">
								<Facebook alt={'Facebook'} />
							</a>
						</div>
					</div>

					<div className="contact-us__form">
						<Formik
							validationSchema={schema}
							onSubmit={console.log}
							initialValues={{
								name: '',
								email: '',
								phone: '',
								message: t('aboutUS.helloIWouldLike'),
							}}>
							{({
								handleSubmit,
								handleChange,
								handleBlur,
								values,
								touched,
								isValid,
								errors,
							}) => (
								<Form noValidate onSubmit={handleSubmit} className="form">
									<Form.Group controlId="name">
										<Form.Control
											type="text"
											name="name"
											className="icon__user"
											placeholder={t('name')}
											value={values.name}
											onChange={handleChange}
											isInvalid={!!errors.name}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.name}
										</Form.Control.Feedback>
									</Form.Group>

									<Form.Group controlId="email">
										<Form.Control
											type="email"
											name="email"
											className="icon__email"
											placeholder={t('email')}
											value={values.email}
											onChange={handleChange}
											isInvalid={!!errors.email}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.email}
										</Form.Control.Feedback>
									</Form.Group>

									<Form.Group controlId="phone">
										<Form.Control
											type="number"
											name="phone"
											className="icon__phone"
											placeholder={t('phone')}
											value={values.phone}
											onChange={handleChange}
											isInvalid={!!errors.phone}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.phone}
										</Form.Control.Feedback>
									</Form.Group>

									<Form.Group controlId="message">
										<Form.Control
											as="textarea"
											name="message"
											placeholder={t('Message')}
											value={values.message}
											onChange={handleChange}
											isInvalid={!!errors.message}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.message}
										</Form.Control.Feedback>
									</Form.Group>

									<Form.Group controlId="offers">
										<Form.Label className="form__glabel">
											<Form.Check
												type="checkbox"
												id="offers"
											/>

											<p>{t('aboutUS.iConfirm')} <a href="">{t('aboutUS.privacyPolicy')}</a></p>
										</Form.Label>
									</Form.Group>

									<Button type="submit">{t('aboutUS.sendMessage')}</Button>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</section>
	)
};

export default ContactUs;
