import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';


const Together = (props) => {
	const {
		body,
	} = props;
	const { t } = useTranslation();

	return (
		<section className="together">
			<div className="container">
				<h2>{t('aboutUS.regulatoryStatus')}<span>.</span></h2>
				<p>
					{body}
				</p>
			</div>
		</section>
	)
};

Together.defaultProps = {
	body: `
		AS Inlendo operates in compliance with the European Regulation 2020/1503 on European Crowdfunding
		Service Providers (ECSP) for Business. As a regulated ECSP, AS Inlendo has filed and expects to be
		licensed and regulated by the Financial and Capital Markets Commission (FCMC) of the Republic of
		Latvia. AS Inlendo is presently part of the regulatory sandbox facility of the FCMC, where it
		conducts the technical testing of its autoinvest application. AS Inlendo has applied for and expects
		to act as a payment service provider agent for LemonWay, which is a Payment Institution authorised
		by the ACPR - Banque d France under number 16558. AS Inlendo is a Republic of Latvia joint stock
		company ("Akciju Sabiedriba"), registration number 40203329997.
	`,
};
Together.propTypes = {
	body: PropTypes.string,
};

export default Together;
