import React, { useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Select from '../../components/select';


const schema = Yup.object().shape({
	firstName: Yup
		.string()
		.matches(
			/^[a-zA-Z]+$/,
			'Please provide valid name'
		)
		.required()
		.min(2)
		.max(200),
	middleName: Yup
		.string()
		.matches(
			/^[a-zA-Z]+$/,
			'Please provide valid middle name'
		)
		.required()
		.min(2)
		.max(200),
	lastName: Yup
		.string()
		.matches(
			/^[a-zA-Z]+$/,
			'Please provide valid last name'
		)
		.required()
		.min(2)
		.max(200),
	countryOfCitizenship: Yup
		.string()
		.required(),
	countryOfResidence: Yup
		.string()
		.required(),
	residenceAddress: Yup
		.string()
		.required()
		.max(250),
	personalIdentityNumber: Yup
		.string()
		.required()
		.max(50),
	occupation: Yup
		.string()
		.required(),
	taxResidenceCountry: Yup
		.string()
		.required(),
	politicalPerson: Yup
		.string()
		.required(),
	documentType: Yup
		.string()
		.required(),
});

const Lender = (props) => {
	const fileRef = useRef();
	const {
		data,
		countries,
		occupations,
	} = props;
	const { t } = useTranslation();
	const politicalPerson = [
		{
			title: t('yes'),
			value: 1,
		},
		{
			title: t('no'),
			value: 2,
		},
	];
	const documentType = [
		{
			title: t('kyc.doc1'),
			value: 1,
		},
		{
			title: t('kyc.doc2'),
			value: 2,
		},
	];
	const onSubmit = data => {
		props.updateProfile({
			firstName: data.firstName,
			middleName: data.middleName,
			lastName: data.lastName,
		});
	}

	return (
		<section className="content">
			<div className="container">
				<Formik
					validationSchema={schema}
					onSubmit={onSubmit}
					initialValues={{
						firstName: data.firstName,
						middleName: data.middleName,
						lastName: data.lastName,
						countryOfCitizenship: '',
						countryOfResidence: '',
						residenceAddress: '',
						personalIdentityNumber: '',
						occupation: '',
						taxResidenceCountry: '',
						politicalPerson: '',
						documentType: '',
					}}>
					{({
						handleSubmit,
						handleChange,
						handleBlur,
						values,
						touched,
						isValid,
						errors,
					}) => (
						<Form noValidate onSubmit={handleSubmit} className="form form--kyc">
							<Form.Group controlId="firstName">
								<Form.Label>{t('firstName')}</Form.Label>

								<Form.Control
									type="text"
									name="firstName"
									placeholder={t('firstName')}
									value={values.firstName}
									onChange={handleChange}
									isInvalid={!!errors.firstName}
								/>
								<Form.Control.Feedback type="invalid" className="mb-3">
									{errors.firstName}
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group controlId="middleName">
								<Form.Label>{t('middleName')}</Form.Label>

								<Form.Control
									type="text"
									name="middleName"
									placeholder={t('middleName')}
									value={values.middleName}
									onChange={handleChange}
									isInvalid={!!errors.middleName}
								/>
								<Form.Control.Feedback type="invalid" className="mb-3">
									{errors.middleName}
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group controlId="lastName">
								<Form.Label>{t('lastName')}</Form.Label>

								<Form.Control
									type="text"
									name="lastName"
									placeholder={t('lastName')}
									value={values.lastName}
									onChange={handleChange}
									isInvalid={!!errors.lastName}
								/>
								<Form.Control.Feedback type="invalid" className="mb-3">
									{errors.lastName}
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group controlId="countryOfCitizenship">
								<Form.Label>{t('kyc.countryOfCitizenship')}</Form.Label>

								<Select
									name="countryOfCitizenship"
									text={t('kyc.selectCountry')}
									items={countries.map(el => {
										return {
											title: el.title,
											value: el.iso,
										}
									})}
									onChange={handleChange}
								/>
								<div className="invalid-feedback mb-3" style={{ display: errors.countryOfCitizenship ? 'block' : '' }}>
									{errors.countryOfCitizenship}
								</div>
							</Form.Group>

							<Form.Group controlId="countryOfResidence">
								<Form.Label>{t('kyc.countryOfResidence')}</Form.Label>

								<Select
									name="countryOfResidence"
									text={t('kyc.selectCountry')}
									items={countries.map(el => {
										return {
											title: el.title,
											value: el.iso,
										}
									})}
									onChange={handleChange}
								/>
								<div className="invalid-feedback mb-3" style={{ display: errors.countryOfResidence ? 'block' : '' }}>
									{errors.countryOfResidence}
								</div>
							</Form.Group>

							<Form.Group controlId="residenceAddress">
								<Form.Label>{t('kyc.residenceAddress')}</Form.Label>

								<Form.Control
									type="text"
									name="residenceAddress"
									placeholder={t('kyc.residenceAddress')}
									value={values.residenceAddress}
									onChange={handleChange}
									isInvalid={!!errors.residenceAddress}
								/>
								<Form.Control.Feedback type="invalid" className="mb-3">
									{errors.residenceAddress}
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group controlId="personalIdentityNumber">
								<Form.Label>{t('kyc.personalIdentityNumber')}</Form.Label>

								<Form.Control
									type="text"
									name="personalIdentityNumber"
									placeholder={t('kyc.personalIdentityNumber')}
									value={values.personalIdentityNumber}
									onChange={handleChange}
									isInvalid={!!errors.personalIdentityNumber}
								/>
								<Form.Control.Feedback type="invalid" className="mb-3">
									{errors.personalIdentityNumber}
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group controlId="occupation">
								<Form.Label>{t('kyc.occupation')}</Form.Label>

								<Select
									name="occupation"
									text={t('kyc.selectOccupation')}
									items={occupations.map(el => {
										return {
											title: t(el.title),
											value: el.value,
										}
									})}
									onChange={handleChange}
								/>

								<div className="invalid-feedback mb-3" style={{ display: errors.occupation ? 'block' : '' }}>
									{errors.occupation}
								</div>
							</Form.Group>

							<Form.Group controlId="taxResidenceCountry">
								<Form.Label>{t('kyc.taxResidenceCountry')}</Form.Label>

								<Select
									name="taxResidenceCountry"
									text={t('kyc.selectCountry')}
									items={countries.map(el => {
										return {
											title: el.title,
											value: el.iso,
										}
									})}
									onChange={handleChange}
								/>
								<div className="invalid-feedback mb-3" style={{ display: errors.taxResidenceCountry ? 'block' : '' }}>
									{errors.taxResidenceCountry}
								</div>
							</Form.Group>

							<Form.Group controlId="politicalPerson">
								<Form.Label>{t('kyc.politicalPerson')}</Form.Label>

								<Select
									name="politicalPerson"
									text={t('kyc.selectType')}
									items={politicalPerson}
									onChange={handleChange}
								/>

								<div className="form__info">
									{t('kyc.whatIsPoliticalPerson')}

									<div className="form__info-content">{t('kyc.inFinancialRegulation')}</div>
								</div>

								<div className="invalid-feedback mb-3" style={{ display: errors.politicalPerson ? 'block' : '' }}>
									{errors.politicalPerson}
								</div>
							</Form.Group>

							<Form.Group controlId="documentType">
								<Form.Label>{t('kyc.documentType')}</Form.Label>

								<Select
									name="documentType"
									text={t('kyc.selectType')}
									items={documentType}
									onChange={handleChange}
								/>

								<div className="invalid-feedback mb-3" style={{ display: errors.documentType ? 'block' : '' }}>
									{errors.documentType}
								</div>
							</Form.Group>

							<Form.Group controlId="documentCopy">
								<Form.Label>{t('kyc.documentCopy')}</Form.Label>

								<label htmlFor="file" onClick={() => fileRef.current.click()}>
									<span>{t('kyc.chooseFile')}</span>

									<input
										ref={fileRef}
										placeholder={t('kyc.documentCopy')}
										value={values.documentCopy}
										onChange={handleChange}
										type="file"
										id="documentCopy"
										name="documentCopy"
									/>
								</label>

								<div className="invalid-feedback mb-3" style={{ display: errors.documentCopy ? 'block' : '' }}>
									{errors.documentCopy}
								</div>
							</Form.Group>

							<Button type="submit">{t('kyc.submitInformation')}</Button>
						</Form>
					)}
				</Formik>
			</div>
		</section>
	)
}

Lender.defaultProps = {
	countries: [
		{
			id: 1,
			title: 'Russia',
			iso: 'ru',
		},
		{
			id: 2,
			title: 'Latvia',
			iso: 'lv',
		},
		{
			id: 3,
			title: 'Germany',
			iso: 'de',
		},
	],
	occupations: [
		{
			title: 'self-employed',
			value: 1,
		},
		{
			title: 'employed',
			value: 2,
		},
		{
			title: 'investor',
			value: 3,
		},
	],
	data: {
		firstName: '',
		middleName: '',
		lastName: '',
	},
	updateProfile: () => {},
};

Lender.propTypes = {
	data: PropTypes.shape({
		firstName: PropTypes.string,
		middleName: PropTypes.string,
		lastName: PropTypes.string,
	}),
	countries: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string.isRequired,
		iso: PropTypes.string.isRequired,
	})),
	occupations: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
	})),
	updateProfile: PropTypes.func.isRequired,
};

export default Lender;
