import React from 'react';

const Freedom = () => {
	return (
		<section className="freedom">
			<div className="container">
				<h2>Get one step closer to financial freedom<span>.</span></h2>
				<div className="btn__wrap">
					<a href="#" className="btn btn--big btn--grad">Choose your project</a>
				</div>
			</div>
		</section>
	)
};

export default Freedom;
