const blog = {
	date: '03 March 2022',
	title: 'The new EU Crowdfunding Regulation',
	description: 'Real estate crowdfunding as an industry is relatively new but increasingly growing; thus, it is not regulated just yet. But it\'s hard to ignore its presence – the real estate crowdfunding market is expected to reach a whopping $869 Billion by 2027, and this has prompted the EU parliament to announce Regulation (EU) 2020/1503, which refers to all EU member states and will come into force in November 2021.',
	img: 'hero__img.png',
	author: {
		title: 'James Arthur',
		date: 'Feb',
		img: 'hero__author.png',
		readTime: '5/6 min',
	},
	post: `
        <p>
            The EU commission has designed the Regulation to formalize the process of service provision for crowdfunding and widen the pool of investors. At the moment, some countries operate under their own regulations, which makes cross-border crowdfunding operations complex and expensive, so the new regulations are going to make things more manageable for both seasoned and new investors.
        </p>
        <div class="post__img">
            <img src="/assets/img/post__img.png" alt="Post title">
            <span>Photo credit: James Arthur</span>
        </div>
        <h2>EU cross-border crowdfunding regulation overview</h2>
        <p>The new European union crowdfunding regulation was approved on October 7th, 2020 and will be implemented in November 2021.</p>
        <p>The new regulation will apply to projects raising up to 5 Million EUR over a 12 month period, which is considered to cover the needs of most SME businesses and startups. In addition, the Market regulates other projects which exceed EURO 5,000,000 in Financial Investment Directive (MiFID II) and Regulation (MiFI R).</p>
        <p>The new EU regulation is intended to solve the differences between existing national rules (only some countries have them now) for crowdfunding, which have led to substantial compliance expenses disproportionate to the size of the investments.</p>
        <p>According to the new rules, those Eripean Union member states with no crowdfunding regulation will apply the new Regulation from November 2021. Then, having their own crowdfunding legislation in effect, the other Member States will have a transition period until November 2022 to replace their existing regulations with the Regulation EU 2020/1503.</p>
        <div class="post__img">
            <img src="/assets/img/post__img--2.png" alt="Post title">
            <span>Photo credit: James Arthur</span>
        </div>
        <h2>EU cross-border crowdfunding regulation overview</h2>
        <p>The new European union crowdfunding regulation was approved on October 7th, 2020 and will be implemented in November 2021.</p>
        <p>The new regulation will apply to projects raising up to 5 Million EUR over a 12 month period, which is considered to cover the needs of most SME businesses and startups. In addition, the Market regulates other projects which exceed EURO 5,000,000 in Financial Investment Directive (MiFID II) and Regulation (MiFI R).</p>
        <p>The new EU regulation is intended to solve the differences between existing national rules (only some countries have them now) for crowdfunding, which have led to substantial compliance expenses disproportionate to the size of the investments.</p>
        <p>According to the new rules, those Eripean Union member states with no crowdfunding regulation will apply the new Regulation from November 2021. Then, having their own crowdfunding legislation in effect, the other Member States will have a transition period until November 2022 to replace their existing regulations with the Regulation EU 2020/1503.</p>
	`,
	share: {
		whatsapp: '#',
		telegram: '#',
		facebook__chat: '#',
		facebook: '#',
	}
};

export default blog;
