import React from 'react';
import { Translation } from 'react-i18next';

import { getDocuments } from '../../redux/API/documents';
import Loader from '../../components/loader';
import MyDocuments from './myDocuments';


class Documents extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			documents: [],
			statements: [],
		}
	}

	componentDidMount() {
		this.getDocuments();
	}

	async getDocuments() {
		const response = await getDocuments();

		this.setState({
			isLoading: false,
			documents: response.data.documents,
			statements: response.data.statements,
		});
	}

	render() {
		const {
			isLoading,
			documents,
			statements,
		} = this.state;

		return (
			<div id="documents" className="row p-0 m-0">
				<div className="col p-0 m-0">
					<section>
						<div className="container">
							<div className="investment__item main">
								<Translation>
									{ (t) => (
										<>
											<div className="investment__inner">
												<div className="investment__title">{t('documents.myDocuments')}</div>
											</div>

											<div className="investment__sep"></div>
											<p><b>{t('documents.common')}</b></p>
											<p><a href="#">{t('documents.termsOfUse')}</a></p>
											<p><a href="#">{t('documents.investmentAgreementTerms')}</a></p>

											<MyDocuments items={statements} />

											<p><b>{t('documents.projects')}</b></p>
										</>
									) }
								</Translation>

								{isLoading
									? <Loader />
									: documents.map((el, index) => (
										<p key={`document_${index}`}>
											<a href={el.url}>{el.title}</a>
										</p>
									))
								}
							</div>
						</div>
					</section>
				</div>
			</div>
		)
	}
}

export default Documents;
