import React from 'react';
import { Translation } from 'react-i18next';

import Hero from './hero';
import Items from './items';
import { getBlogs } from '../../redux/API/blogs';
import Loader from '../../components/loader';


class Blogs extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			page: 0,
			total: 0,
			pagination: [],
			blogs: [],
			hero: {},
			isLoading: true,
		};

		// this.getBlogs = this.getBlogs.bind(this);
	}

	componentDidMount() {
		this.getBlogs();
	}

	setPage(page) {
		this.setState({
			page,
			isLoading: true,
		}, () => this.getBlogs());
	}

	async getBlogs() {
		const {
			page,
		} = this.state;
		const response = await getBlogs({
			page,
		});

		this.setState({
			blogs: response.data.items,
			total: response.data.total,
			pagination: new Array(Math.floor(response.data.total / 3)).fill(null),
			hero: response.data.hero,
			isLoading: false,
		});
	}

	render() {
		const {
			page,
			blogs,
			pagination,
			isLoading,
		} = this.state;

		return (
			<div id="blogs" className="row p-0 m-0">
				<div className="col p-0 m-0">
					<section className="title__big">
						<div className="container">
							<Translation>
								{ (t) => (<>
									<h1 data-echo={t('blogs.ourBlog')}>
										{t('blogs.extra')}
										&nbsp;
										<span>{t('blogs.extra')}</span>
										<br />
										&nbsp;
										{t('blogs.readAllAboutIt')}
									</h1>
								</>)}
							</Translation>

							<input type="text" className="title__search" placeholder="Search" />
						</div>
					</section>

					<section>
						<div className="container">
							{isLoading
							? (
								<Loader style={{ marginBottom: '5rem' }} />
							)
							: (
								<>
									{page === 0 && <Hero />}

									<Items data={blogs} />
								</>
							)}

							<div className="pagination">
								<div className="pagination__item" onClick={() => page > 0 && this.setPage(page - 1)}>
									&lt;
								</div>

								{pagination.map((el, index) => (
									<div
										onClick={() => this.setPage(index)}
										className={`pagination__item ${page === index ? 'active' : ''}`}
										key={`blogPagination_${index}`}>
										{index + 1}
									</div>
								))}

								<div className="pagination__item" onClick={() => page < pagination.length - 1 && this.setPage(page + 1)}>
									&gt;
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		)
	}
}

export default Blogs;
