import React from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';


const MyDocuments = (props) => {
	const {
		items,
	} = props;
	const { t } = useTranslation();

	return (
		<>
			<div className="investment__sep"></div>

			{items.map((el, index) => (
				<div key={`statement_${index}`}>
					{index !== 0 && <div className="investment__sep" />}

					<Form className="form form--wide form--left">
						<Form.Group className="form__group">
							<Form.Label>{el.title}</Form.Label>

							<div className="form__row">
								<Form.Control
									type="text"
									className="icon__calendar"
									name="date"
									defaultValue={el.date}
								/>

								<div className="form__action">
									<Button type="submit">{t('Submit')}</Button>
								</div>
							</div>
						</Form.Group>
					</Form>

				</div>
			))}


			<div className="investment__sep"></div>
		</>
	)
};

MyDocuments.defaultProps = {
	items: [
		{
			title: 'Statement 1',
			date: '09/12/2019 - 07/08/2022',
		},
		{
			title: 'Statement 2',
			date: '09/12/2021 - 07/08/2022',
		},
		{
			title: 'Statement 3',
			date: '09/12/2023 - 07/08/2023',
		},
	],
};

MyDocuments.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string.isRequired,
		date: PropTypes.string.isRequired,
	})),
};

export default MyDocuments;
