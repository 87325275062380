import React from 'react';
import PropTypes from 'prop-types';


const Row = (props) => {
	return (
		<div className="project__row">
			{props.children}
		</div>
	)
};

Row.propTypes = {
	children: PropTypes.any
};


const Col = (props) => {
	return (
		<div className="project__col">{props.children}</div>
	)
};
Col.propTypes = {
	children: PropTypes.any
};


export {
	Row,
	Col,
}
