const invest = {
	investmentAuthorisation: 'Investment authorisation order No ID',
	fromInvestor: 'From Investor with the ID',
	to: 'to',
	regNo: 'Reg.No.',
	dated: 'Dated',
	investmentSummary: 'Investment project terms summary',
	projectId: 'Project ID No',
	idNr: 'ID Nr',
	interestRate: 'Interest rate',
	loanTerm: 'Loan term',
	fundraising: 'Fundraising closing date',
	byIssuing: 'By issuing this Order, I, Investor number No',
	confirmAcceptance: ', confirm the acceptance of the entirety of offers and conditions enclosed in the Investment Project No ID Nr',
	asSpecified: ', as specified in this Order below, and authorize',
	toTransfer: 'to transfer funds to the Borrower in the amount specified below.',
	investmentSheet: 'Key Investment Information sheet',
	pa: 'p.a.',
	yourBalance: 'Your avaliable balance',
	amountDrawn: 'this amount will be drawn from your Inlendo account and reserved for this project until the Fundraising closing date, when, upon successful closing, the money will be transferred to the Borrower, or returned to your Inlendo account if the Project is not fully funded.',
	agreeTo: 'agree to the',
	authorisation: 'Authorisation via sms',
	inOrderToProceed: 'In order to proceed we will need your authorisation via sms by inserting the sms code in the form below. Your Phone number is: ',
	requestCode: 'Request code',
	byAccepting: 'By accepting this Order I agree and confirm that  I understand and accept the risks associated with this investment as outlined below, and want to proceed. By accepting this Order I confirm and agree with the terms and authorise',
	toTransferFunds: 'to transfer funds.',
	acceptInvest: 'Accept and invest',
	disclaimers: 'Disclaimers and risk warnings',
	disclaimer: 'Disclaimer',
	thisCrowdfunding: '‘This crowdfunding offer has been neither verified nor approved by competent authorities or the European Securities and Markets Authority (ESMA). The appropriateness of your experience and knowledge have not necessarily been assessed before you were granted access to this investment. By making this investment, you assume full risk of taking this investment, including the risk of partial or entire loss of the money invested.’',
	riskWarnings: 'Risk warnings',
	investmentIn: '‘Investment in this crowdfunding project entails risks, including the risk of partial or entire loss of the money invested. Your investment is not covered by the deposit guarantee schemes established in accordance with Directive 2014/49/EU of the European Parliament and of the Council (*). Nor is your investment covered by the investor compensation schemes established in accordance with Directive 97/9/EC of the European Parliament and of the Council (**). You may not receive any return on your investment. This is not a savings product and we advise you not to invest more than 10 % of your net worth in crowdfunding projects. You may not be able to sell the investment instruments when you wish. If you are able to sell them, you may nonetheless incur losses.',
	preContractual: 'Pre-contractual reflection period for non-sophisticated investors:',
	accordingTo: 'According to the Crowdfunding Regula, non-sophisticated investors have a right to a pre-contractual reflection period,  during which you may, at any time, revoke your offer to invest or expression of interest in this crowdfunding offer without giving a reason and without incurring a penalty. The duration of the reflection period is 4 calendar days, and starts at the moment when you make the investment. If, during this time, you would like to revoke your investment in this project, please click “REVOKE MY INVESTMENT” under your Investor cabinet.',

};

export default invest;
