const kyc = {
	kycVerification: 'KYC Verification',
	inOrderToInvest: 'In order to invest within our platform in accordance with our Anti Money Laundering (AML) policy you’ll need to pass our verification process.',
	companyName: 'Company name',
	countryRegistration: 'Country of registration',
	selectCountry: 'Select country',
	submitInformation: 'Submit information',
	countryOfCitizenship: 'Country of citizenship',
	countryOfResidence: 'Country of residence',
	residenceAddress: 'Residence address',
	personalIdentityNumber: 'Personal identity number',
	occupation: 'Occupation',
	selectOccupation: 'Select occupation',
	taxResidenceCountry: 'Your tax residence country',
	politicalPerson: 'Are you a Politically Exposed Person (PEP) or a family member/close associate thereof?',
	whatIsPoliticalPerson: 'What is a politically exposed person?',
	inFinancialRegulation: 'In financial regulation, a politically exposed person is one who has been entrusted with a prominent public function.',
	selectType: 'Select type',
	documentType: 'Document type',
	documentCopy: 'Document scanned copy',
	doc1: 'Document 1',
	doc2: 'Document 2',
	chooseFile: 'Choose file',
	countryOfRegistration: 'Country of registration',
	legalAddress: 'Legal address',
	officeAddress: 'Office address',
};

export default kyc;
