import React from 'react';
import PropTypes from 'prop-types';

import TopBar from './layout/topbar';
import Header from './layout/header';
import Footer from './layout/footer';


const RootError = (props) => {
	const {
		id,
		firstName,
		lastName,
		type,
		currency,
		balance,
		isAuth,
	} = props;

	return (
		<>
			<TopBar
				isAuth={isAuth}
				id={id}
				type={type}
				firstName={firstName}
				lastName={lastName}
				balance={balance}
				currency={currency}
			/>

			<Header type={type} isAuth={isAuth} />

			<main id="main" style={{ marginTop: !isAuth ? '8rem' : '' }}>
				<div className="container-fluid m-0 p-0">
					{props.children}
				</div>
			</main>

			<Footer />
		</>
	)
}

RootError.propTypes = {
	isAuth: PropTypes.bool.isRequired,
	id: PropTypes.string.isRequired,
	firstName: PropTypes.string.isRequired,
	lastName: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	balance: PropTypes.number.isRequired,
	currency: PropTypes.string.isRequired,
	children: PropTypes.element
};

export default RootError;
