import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Card, Item } from './item';


const Order = (props) => {
	const {
		projectId,
		fromInvestorId,
		asInvestor,
		regNumber,
		date,
	} = props;
	const { t } = useTranslation();

	return (
		<Card>
			<Item>
				<div className="investment__title">{t('invest.investmentAuthorisation')} {projectId}</div>
				<div className="investment__close"></div>
			</Item>

			<Item>
				<div className="investment__text">{t('invest.fromInvestor')} {fromInvestorId} {t('invest.to')} {asInvestor},
					{t('invest.regNo')} {regNumber}
				</div>

				<div className="investment__text">{t('invest.dated')} {date}</div>
			</Item>
		</Card>
	)
};

Order.defaultProps = {
	projectId: 'AB12345',
	fromInvestorId: 'DDD678',
	asInvestor: 'Inlendo A/S',
	regNumber: '40203329997',
	date: '20.12.2021',
};

Order.propTypes = {
	projectId: PropTypes.string.isRequired,
	fromInvestorId: PropTypes.string.isRequired,
	asInvestor: PropTypes.string.isRequired,
	regNumber: PropTypes.string.isRequired,
	date: PropTypes.string.isRequired,
};

export default Order;
