import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Select from '../../components/select';


const schema = Yup.object().shape({
	companyName: Yup
		.string()
		.required()
		.max(200),
	countryOfRegistration: Yup
		.string()
		.required(),
	legalAddress: Yup
		.string()
		.required()
		.max(250),
	officeAddress: Yup
		.string()
		.required()
		.max(250),
	politicalPerson: Yup
		.string()
		.required(),
	documentType: Yup
		.string()
		.required(),
});

const Borrower = (props) => {
	const fileRef = useRef();
	const {
		countries,
	} = props;
	const { t } = useTranslation();
	const politicalPerson = [
		{
			title: t('yes'),
			value: 1,
		},
		{
			title: t('no'),
			value: 2,
		},
	];
	const documentType = [
		{
			title: t('kyc.doc1'),
			value: 1,
		},
		{
			title: t('kyc.doc2'),
			value: 2,
		},
	];

	return (
		<section className="content">
			<div className="container">
				<Formik
					validationSchema={schema}
					onSubmit={console.log}
					initialValues={{
						companyName: '',
						countryOfRegistration: '',
						legalAddress: '',
						officeAddress: '',
						politicalPerson: '',
						documentType: '',
					}}>
					{({
						handleSubmit,
						handleChange,
						handleBlur,
						values,
						touched,
						isValid,
						errors,
					}) => (
						<Form noValidate onSubmit={handleSubmit} className="form form--kyc" encType="multipart/form-data">
							<Form.Group controlId="companyName">
								<Form.Label>{t('kyc.companyName')}</Form.Label>

								<Form.Control
									type="text"
									name="companyName"
									placeholder={t('kyc.companyName')}
									value={values.companyName}
									onChange={handleChange}
									isInvalid={!!errors.companyName}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.companyName}
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group controlId="countryOfRegistration">
								<Form.Label>{t('kyc.countryOfRegistration')}</Form.Label>

								<Select
									name="countryOfRegistration"
									text={t('kyc.selectCountry')}
									items={countries.map(el => {
										return {
											title: el.title,
											value: el.iso,
										}
									})}
									onChange={handleChange}
								/>
								<div className="invalid-feedback mb-3" style={{ display: errors.countryOfRegistration ? 'block' : '' }}>
									{errors.countryOfRegistration}
								</div>
							</Form.Group>

							<Form.Group controlId="legalAddress">
								<Form.Label>{t('kyc.legalAddress')}</Form.Label>

								<Form.Control
									type="text"
									name="legalAddress"
									placeholder={t('kyc.legalAddress')}
									value={values.legalAddress}
									onChange={handleChange}
									isInvalid={!!errors.legalAddress}
								/>

								<div className="form__info">Please include street name, city and postal code</div>

								<Form.Control.Feedback type="invalid">
									{errors.legalAddress}
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group controlId="officeAddress">
								<Form.Label>{t('kyc.officeAddress')}</Form.Label>

								<Form.Control
									type="text"
									name="officeAddress"
									placeholder={t('kyc.officeAddress')}
									value={values.officeAddress}
									onChange={handleChange}
									isInvalid={!!errors.officeAddress}
								/>

								<div className="form__info">Please include street name, city and postal code</div>

								<Form.Control.Feedback type="invalid">
									{errors.officeAddress}
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group controlId="politicalPerson">
								<Form.Label>{t('kyc.politicalPerson')}</Form.Label>

								<Select
									name="politicalPerson"
									text={t('kyc.selectType')}
									items={politicalPerson}
									onChange={handleChange}
								/>

								<div className="form__info">
									{t('kyc.whatIsPoliticalPerson')}

									<div className="form__info-content">{t('kyc.inFinancialRegulation')}</div>
								</div>

								<div className="invalid-feedback mb-3" style={{ display: errors.politicalPerson ? 'block' : '' }}>
									{errors.politicalPerson}
								</div>
							</Form.Group>

							<Form.Group controlId="documentType">
								<Form.Label>{t('kyc.documentType')}</Form.Label>

								<Select
									name="documentType"
									text={t('kyc.selectType')}
									items={documentType}
									onChange={handleChange}
								/>

								<div className="invalid-feedback mb-3" style={{ display: errors.documentType ? 'block' : '' }}>
									{errors.documentType}
								</div>
							</Form.Group>

							<Form.Group controlId="documentCopy">
								<Form.Label>{t('kyc.documentCopy')}</Form.Label>

								<label htmlFor="file" onClick={() => fileRef.current.click()}>
									<span>{t('kyc.chooseFile')}</span>

									<input
										ref={fileRef}
										placeholder={t('kyc.documentCopy')}
										value={values.documentCopy}
										onChange={handleChange}
										type="file"
										id="documentCopy"
										name="documentCopy"
									/>
								</label>

								<div className="invalid-feedback mb-3" style={{ display: errors.documentCopy ? 'block' : '' }}>
									{errors.documentCopy}
								</div>
							</Form.Group>

							<Button type="submit">{t('kyc.submitInformation')}</Button>
						</Form>
					)}
				</Formik>
			</div>
		</section>
	)
};

Borrower.defaultProps = {
	countries: [
		{
			id: 1,
			title: 'Russia',
			iso: 'ru',
		},
		{
			id: 2,
			title: 'Latvia',
			iso: 'lv',
		},
		{
			id: 3,
			title: 'Germany',
			iso: 'de',
		},
	],
};

Borrower.propTypes = {
	countries: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string.isRequired,
		iso: PropTypes.string.isRequired,
	})),
};

export default Borrower;
