import React from 'react';
import * as Yup from 'yup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';


const schema = Yup.object().shape({
	email: Yup
		.string()
		.email()
		.required()
		.matches(
			/^[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]{2,}$/,
			'Please provide valid email address'
		),
	password: Yup
		.string()
		.required()
		.min(8, 'Must be at least 8 characters')
		.max(20, 'Must be less  than 20 characters')
		.matches(
			/^[a-zA-Z0-9]+$/,
			'Cannot contain special characters or spaces.'
		),
});


class Register extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			type: 1
		};
	}

	render() {
		return (
			<div id="register" className="row p-0 m-0">
				<div className="col p-0 m-0">
					<section className="s-login s-login--login">
						<div className="container">
							<h1>Register</h1>

							<Nav variant="pills" className="s-login__tabs justify-content-center">
								<Nav.Item className="s-login__tab">
									<Nav.Link
										onClick={() => this.setState({ ...this.state, type: 1 })}
										style={{
											backgroundColor: this.state.type === 2 ? 'var(--gray)' : 'var(--blue)',
										}}>
										Lender
									</Nav.Link>
								</Nav.Item>

								<Nav.Item className="s-login__tab">
									<Nav.Link
										onClick={() => this.setState({ ...this.state, type: 2 })}
										style={{
											backgroundColor: this.state.type === 1 ? 'var(--gray)' : 'var(--blue)',
										}}>
										Borrower
									</Nav.Link>
								</Nav.Item>
							</Nav>

							<Formik
								validationSchema={schema}
								onSubmit={console.log}
								initialValues={{
									email: '',
									password: '',
								}}>
								{({
									handleSubmit,
									handleChange,
									handleBlur,
									values,
									touched,
									isValid,
									errors,
								}) => (
									<Form noValidate onSubmit={handleSubmit} className="form">
										<Form.Group controlId="email">
											<Form.Label className="label-hidden">Email address</Form.Label>
											<Form.Control
												type="email"
												name="email"
												placeholder="Email address"
												value={values.email}
												onChange={handleChange}
												isInvalid={!!errors.email}
											/>
											<Form.Control.Feedback type="invalid">
												{errors.email}
											</Form.Control.Feedback>
										</Form.Group>

										<Form.Group controlId="password">
											<Form.Label className="label-hidden">Password</Form.Label>
											<Form.Control
												type="password"
												name="password"
												placeholder="Password"
												value={values.password}
												onChange={handleChange}
												isInvalid={!!errors.password}
											/>
											<Form.Control.Feedback type="invalid">
												{errors.password}
											</Form.Control.Feedback>
										</Form.Group>

										<fieldset>
											<ul>
												<li>
													<label className="form__glabel" htmlFor="terms">
														<Form.Check
															type="checkbox"
															id="terms"
														/>
														<p>I confirm that I have read and agree to the
															<Link to="">Privacy Policy</Link>
															and <Link to="">Terms & Conditions</Link>
														</p>
													</label>
												</li>
												<li>
													<label className="form__glabel" htmlFor="offers">
														<Form.Check
															type="checkbox"
															id="offers"
															defaultChecked
														/>
														<p>Yes I would like to recieve news and special offers</p>
													</label>
												</li>
											</ul>
										</fieldset>


										<Button type="submit">Create account</Button>
										<p className="form__hint">Already have an account? <Link to="../login">Log in</Link></p>
									</Form>
								)}
							</Formik>
						</div>
					</section>
				</div>
			</div>
		)
	}
}

export default Register;
