import { URL } from '../../config/const';
import { documents, statements } from '../../mocks/documents';

const urls = {
	getDocuments: `${URL}/documents`,
}

const getDocuments = async (params) => {
	const data = {
		documents: [...documents],
		statements: [...statements],
	};

	return new Promise((resolve) =>
		setTimeout(() => resolve({
			data
		}), 1500)
	);

	// return new Promise((resolve, reject) =>
	// 	// eslint-disable-next-line prefer-promise-reject-errors
	// 	setTimeout(() => reject({
	// 		data: {
	// 			error: 'Incorrect credentials!'
	// 		}
	// 	}), 1500)
	// );
};

export {
	urls,
	getDocuments,
}
