import React from 'react';
import { useTranslation } from 'react-i18next';


const Disclaimer = (props) => {
	const { t } = useTranslation();

	return (
		<section className="content">
			<div className="container">
				<h2>{t('invest.disclaimers')}:</h2>
				<h4>{t('invest.disclaimer')}:</h4>

				<p>{t('invest.thisCrowdfunding')}</p>

				<h4>{t('invest.riskWarnings')}:</h4>

				<p>{t('invest.investmentIn')}</p>

				<h4>{t('invest.preContractual')}</h4>

				<p>{t('invest.accordingTo')}</p>
			</div>
		</section>
	)
};

export default Disclaimer;
