const notifications = {
	questionsss: 'Questions',
	inOnePlace: 'In one place',
	newMessages: 'New Messages',
	titleInvestmentAlert: 'Investment Alert',
	titleCreditCardExpired: 'Credit Card Expired',
	titleAccountInformation: 'Account Information',
	titlePaymentComplete: 'Payment Complete',
	bodyChangesInInvestment: 'Changes in your invesment ID',
	bodyLearnHowToFinish: 'Learn how to finish creating your profile.',
};

export default notifications;
