import { createSlice } from '@reduxjs/toolkit';


const initialState = {
	status: 'idle',
	data: {
		projectId: '', // 'AB12345',
		fromInvestorId: '', //  'DDD678',
		asInvestor: '', //  'Inlendo A/S',
		regNumber: '', //  '40203329997',
		date: '', //  '20.12.2021',
		id: '', //  '12345SIA',
		borrower: '', //  'ABC',
		rate: '', //  12,
		term: '', //  '3 months',
		fundraisingClosingDate: '', //  '1 jan 2022',
		amount: {
			raised: 0, // 10000,
			fulfilled: 0, // 100000,
			investment: 0, // 100000,
			min: 0, // 50000,
		},
		investmentAmount: 0,
	},
	error: '',
}

export const investmentSlice = createSlice({
	name: 'investment',
	initialState,
	reducers: {
		setProject: (state, action) => {
			state.data = {
				...state.data,
				...action.payload,
			};
		},
	},
});

export const { setProject } = investmentSlice.actions;

export default investmentSlice.reducer;
