import React from 'react';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Card from './card';
import CardImg from '../../assets/img/card__img.png';
import CardImgAlt from '../../assets/img/card__img--alt.png';


class Landing extends React.Component {
	render() {
		return (
			<div id="landing" className="row p-0 m-0">
				<div className="col p-0 m-0">
					<section className="banner banner--cta">
						<div className="container">
							<div className="banner__content">
								<Translation>
								{(t) => (
									<h1>
										{t('landing.tradeFinance')}
										&nbsp;
										<br />
										&nbsp;
										{t('landing.youWillEnjoy')}
										<span>.</span>
									</h1>
								)}
								</Translation>

								<Translation>
									{(t) => (
										<h3>
											{t('landing.investSell')}
											<br />
											&nbsp;
											{t('landing.financialActivity')}
										</h3>
									)}
								</Translation>

								<div className="btns">
									<Link to={'/dashboard'}>
										<div className="btn btn--big">
											<Translation>{(t) => t('landing.getStarted')}</Translation>
										</div>
									</Link>

									<Link to={'/faq'}>
										<div className="btn btn--big alt">
											<Translation>{(t) => t('landing.seeHowItWorks')}</Translation>
										</div>
									</Link>
								</div>
							</div>

							<div className="banner__cards">
								<Card
									img={CardImg}
								/>

								<Card
									img={CardImgAlt}
								/>
							</div>
						</div>
					</section>
				</div>
			</div>
		)
	}
}

export default Landing;
