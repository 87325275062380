import { createSlice } from '@reduxjs/toolkit';

import { loginUserAsync } from '../thunks/authThunk';
import i18n from '../../app/i18n';


const initialState = {
	status: 'idle',
	isAuth: false,
	forms: {
		KYCCompleted: false,
		borrowerCompleted: false,
	},
	data: {
		id: '',
		type: '',
		firstName: '',
		middleName: '',
		lastName: '',
		balance: 0,
		currency: '',
	},
	language: 'EN',
	error: '',
}

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: (state) => {
			state.isAuth = false;
			state.forms = {
				KYCCompleted: false,
				borrowerCompleted: false,
			};
			state.data = {
				id: '',
				type: '',
				firstName: '',
				middleName: '',
				lastName: '',
				balance: 0,
				currency: '€',
			};
		},
		clearError: (state) => {
			state.error = '';
		},
		setLanguage: (state, action) => {
			i18n.changeLanguage(action.payload);
			state.language = action.payload;
		},
		setBalance: (state, action) => {
			state.data.balance = action.payload
		},
		setForms: (state, action) => {
			state.forms = {
				...state.forms,
				...action.payload,
			}
		},
		updateProfile: (state, action) => {
			state.data = {
				...state.data,
				...action.payload,
			};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(loginUserAsync.pending, (state) => {
				state.status = 'loading';
				state.error = '';
				state.isAuth = false;
			})
			.addCase(loginUserAsync.fulfilled, (state, action) => {
				state.status = 'idle';
				state.error = '';
				state.isAuth = true;
				state.data = {
					id: action.payload.id,
					type: action.payload.type,
					firstName: action.payload.firstName,
					middleName: action.payload.middleName,
					lastName: action.payload.lastName,
					balance: action.payload.balance,
					currency: action.payload.currency,
				};
				state.forms = {
					KYCCompleted: action.payload.KYCCompleted,
					borrowerCompleted: action.payload.borrowerCompleted,
				};
			})
			.addCase(loginUserAsync.rejected, (state, action) => {
				state.status = 'error';
				state.error = action.payload;
				state.isAuth = false;
				state.forms = {
					KYCCompleted: false,
					borrowerCompleted: false,
				};
				state.data = {
					id: '',
					type: '',
					firstName: '',
					middleName: '',
					lastName: '',
					balance: 0,
					currency: '€',
				};
			});
	},
});

export const {
	logout,
	clearError,
	setLanguage,
	setBalance,
	setForms,
	updateProfile,
} = authSlice.actions;

export const isAuth = (state) => state.auth.isAuth;
export const language = (state) => state.auth.language;
export const balance = (state) => state.auth.data.balance;

export default authSlice.reducer;
