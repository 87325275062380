import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import FaqItem from '../faq';


const Documents = (props) => {
	const {
		data,
	} = props;
	const [isActive, setActive] = useState(false);
	const { t } = useTranslation();

	return (
		<FaqItem title={t('Documents')}>
			<div className="doc__row doc__thead">
				<span>{t('date')}</span>
				<span>{t('type')}</span>
				<span>{t('comments')}</span>
			</div>

			{data.map((el, index) => (
				<div className="doc__row" key={`faqRow_${index}`}>
					<span>{el.date}</span>
					<span>{el.type}</span>
					<span>{el.comments}</span>
				</div>
			))}

			<div className="doc__title">{t('project.borrowerResponsibility')}:</div>
			<div className={`content__read ${isActive ? 'active' : ''}`}>
				<p>The loan is used to continue construction works.</p>
				<p>The loan will be repaid from the sale of the collateral property or refinancing by a long-term
					bank loan.</p>
				<p>The loan is secured with a first rank mortgage.</p>
				<p>The borrower retains the opportunity to raise additional capital if necessary, provided that the
					ratio of the loan and the market value of the collateral (LTV) does not exceed 56.0%.</p>
				<p>The established mortgage will also cover loans of additional stages of financing.</p>
				<p>Prior to the additional stages of financing, construction supervision must confirm the completed
					works (if construction has commenced and works have been performed in a considerable amount) and
					a third party must assess the market value of the collateral.</p>
				<p>The LTV in the 1 stage is 15.9% and can increase to 56.0% in further stages.</p>
			</div>

			<div className="content__readmore" onClick={() => setActive(!isActive)}>
				{isActive ? t('readLess') : t('readMore')}
			</div>
		</FaqItem>
	)
}

Documents.defaultProps = {
	data: [
		{
			date: '2022-01-20',
			type: 'Document 1',
			comments: 'Photos and certificated are received by the platform. Please see ...',
		},
		{
			date: '2022-01-20',
			type: 'Document 2',
			comments: 'Photos and certificated are received by the platform. Please see ...',
		},
	],
	statement: `
		<p>The loan is used to continue construction works.</p>
		<p>The loan will be repaid from the sale of the collateral property or refinancing by a long-term bank loan.</p>
		<p>The loan is secured with a first rank mortgage.</p>
		<p>The borrower retains the opportunity to raise additional capital if necessary, provided that the ratio of the loan and the market value of the collateral (LTV) does not exceed 56.0%.</p>
		<p>The established mortgage will also cover loans of additional stages of financing.</p>
		<p>Prior to the additional stages of financing, construction supervision must confirm the completed works (if construction has commenced and works have been performed in a considerable amount) and a third party must assess the market value of the collateral.</p>
		<p>The LTV in the 1 stage is 15.9% and can increase to 56.0% in further stages.</p>
	`,
};

Documents.propTypes = {
	data: PropTypes.arrayOf(PropTypes.shape({
		status: PropTypes.string,
		date: PropTypes.string,
		type: PropTypes.string,
		comments: PropTypes.string,
	})),
	statement: PropTypes.string,
};

export default Documents;
