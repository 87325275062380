import React from 'react';

import DashboardComponent from '../../components/dashboard';
import Item from './item';
import { ReactComponent as Coin } from '../../assets/img/icons/coin.svg';
import { ReactComponent as Income } from '../../assets/img/icons/income.svg';
import { ReactComponent as Investment } from '../../assets/img/icons/investment.svg';


const Items = (props) => {
	return (
		<DashboardComponent title={'Dashboard'}>
			<div className="dash__wrap">
				<Item
					title={'Account Information'}
					subtitle={'Avaliable balance'}
					currency={'€'}
					balance={'10,000'}
					icon={<Coin />}
				/>

				<Item
					title={'Income and Costs'}
					subtitle={'Annual return'}
					currency={'€'}
					balance={'1,275'}
					icon={<Income />}
				/>

				<Item
					title={'Investment Statistics'}
					subtitle={'Total portfolio'}
					currency={'€'}
					balance={'14'}
					icon={<Investment />}
				/>
			</div>
		</DashboardComponent>
	)
};

export default Items;
