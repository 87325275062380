import React from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Item from './Item';
import AddFounds from './addFounds';
import { getDeposit } from '../../redux/API/deposit';
import { setBalance } from '../../redux/reducers/authSlice';
import Loader from '../../components/loader';
import withRouter from '../../components/withRouter';


class Deposit extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			bank: '',
			bankAccount: '',
			beneficiaryAddress: '',
			beneficiaryBank: '',
			beneficiaryRegistration: '',
			depositID: '',
			assignedID: '',
			qrCode: '',
			minDeposit: '',
			netIncome: 0,
			isLoading: true,
			balance: 0,
		};
	}

	componentDidMount() {
		this.getDepositInfo()
	}

	async getDepositInfo() {
		const response = await getDeposit();

		this.setState({
			...response.data,
			isLoading: false,
		})
	}

	addBalance() {
		const {
			amount,
		} = this.props;
		const {
			balance,
		} = this.state;
		const resultAmount = Number(amount) + Number(balance);

		this.props.setBalance(resultAmount);
		this.props.router.navigate('/message/deposit', {
			state: {
				amountAdded: Number(balance),
				newAmount: resultAmount,
			}
		});
	}

	render() {
		const {
			bank,
			bankAccount,
			beneficiaryAddress,
			beneficiaryBank,
			beneficiaryRegistration,
			depositID,
			assignedID,
			qrCode,
			minDeposit,
			netIncome,
			isLoading,
			balance,
		} = this.state;

		return (
			<div id="deposit" className="row p-0 m-0">
				<div className="col p-0 m-0">
					<section>
						<div className="container">
							<div className="investment__item main">
								<Translation>
									{
										(t) => <p>{t('deposit.possibleInvestments')}</p>
									}
								</Translation>

								<Translation>
									{
										(t) => <p>{t('deposit.replenishmentFunds')}</p>
									}
								</Translation>
							</div>

							{isLoading
								? (
									<Loader />
								)
								: (
									<>
										<form className="form form--wide">
											<div className="form__group">
												<label>
													<Translation>{ (t) => t('deposit.minimumInvestment') }</Translation>
													&nbsp;
													€ 50
												</label>

												<input
													type="number"
													name="amount"
													className="icon__networth"
													value={balance}
													onChange={e => this.setState({ balance: e.target.value })}
												/>

												<button type="submit" onClick={() => this.addBalance()}>
													<Translation>{ (t) => t('Deposit') }</Translation>
												</button>
											</div>
										</form>

										<AddFounds
											bank={bank}
											bankAccount={bankAccount}
											beneficiaryAddress={beneficiaryAddress}
											beneficiaryBank={beneficiaryBank}
											beneficiaryRegistration={beneficiaryRegistration}
											depositID={depositID}
											assignedID={assignedID}
											qrCode={qrCode}
										/>

										<Item
											minDeposit={minDeposit}
											netIncome={netIncome}
										/>
									</>
								)
							}
						</div>
					</section>
				</div>
			</div>
		)
	}
}


Deposit.propTypes = {
	amount: PropTypes.number.isRequired,
	setBalance: PropTypes.func.isRequired,
	router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	return {
		amount: state.auth.data.balance,
	}
};

const mapDispatchToProps = {
	setBalance,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Deposit));
