import { URL } from '../../config/const';
import { countries } from '../../app/misc';

const urls = {
	getCountries: `${URL}/countries`,
}

const getCountries = async (params) => {
	const data = [...countries];

	return new Promise((resolve) =>
		setTimeout(() => resolve({
			data
		}), 1500)
	);

	// return new Promise((resolve, reject) =>
	// 	// eslint-disable-next-line prefer-promise-reject-errors
	// 	setTimeout(() => reject({
	// 		data: {
	// 			error: 'Incorrect credentials!'
	// 		}
	// 	}), 1500)
	// );
};

export {
	urls,
	getCountries,
}
