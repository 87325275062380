import React from 'react';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ReactComponent as Money } from '../../assets/img/icons/money.svg';
import { ReactComponent as Wallet } from '../../assets/img/icons/wallet.svg';
import withRouter from '../../components/withRouter';


class Message extends React.Component {
	render() {
		const message = this.props.router.params.message;
		const amount = this.props.router.location.state.amountAdded;

		return (
			<div id="message" className="row p-0 m-0">
				<div className="col p-0 m-0">
					<section className="message">
						<div className="container">
							<h1>
								<Translation>{ (t) => t(`message.${message}Successful`) }</Translation>
							</h1>

							{message === 'deposit'
								? (
									<Money className="message__icon" />
								)
								: (
									<Wallet className="message__icon" />
							)}

							<div className="message__text centered">
								<Translation>{ (t) => t('message.theAmountOf') }</Translation>
								&nbsp;
								<a href="">€ {amount}</a>
								<Translation>{ (t) => t(`message.${message}SuccessfullyReceived`) }</Translation>
							</div>

							<Link to={'/dashboard'} className="message__btn">
								<Translation>{ (t) => t('message.goToDashboard') }</Translation>
							</Link>
						</div>
					</section>
				</div>
			</div>

		)
	}
}

Message.defaultProps = {};

Message.propTypes = {
	router: PropTypes.object.isRequired,
};

export default withRouter(Message);
