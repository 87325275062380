import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import FaqItem from '../faq';


const Stages = (props) => {
	const {
		data
	} = props;
	const { t } = useTranslation();

	return (
		<FaqItem title={t('stages')}>
			<div className="stage__thead">
				<span></span>
				<span>{t('date')}</span>
				<span>{t('stage')}</span>
				<span>{t('comments')}</span>
			</div>

			{data.map((el, index) => (
				<div className="stage__row" key={`faqRow_${index}`}>
					<span className={el.status}>{t('project.' + el.status)}</span>
					<span>{el.date}</span>
					<span>{el.stage}</span>
					<span>{el.comments}</span>
				</div>
			))}
		</FaqItem>
	)
}

Stages.defaultProps = {
	data: [
		{
			status: 'approved',
			date: '2022-01-20',
			stage: 'The letter for payment day received',
			comments: 'Photos and certificated are received by the platform. Please see ...',
		},
		{
			status: 'pending',
			date: '2022-01-20',
			stage: 'The letter for payment day received',
			comments: 'Photos and certificated are received by the platform. Please see ...',
		},
		{
			status: 'declined',
			date: '2022-01-20',
			stage: 'The letter for payment day received',
			comments: 'Photos and certificated are received by the platform. Please see ...',
		},
		{
			status: 'approved',
			date: '2022-01-20',
			stage: 'The letter for payment day received',
			comments: 'Photos and certificated are received by the platform. Please see ...',
		},
	]
};

Stages.propTypes = {
	data: PropTypes.arrayOf(PropTypes.shape({
		status: PropTypes.string,
		date: PropTypes.string,
		stage: PropTypes.string,
		comments: PropTypes.string,
	}))
};

export default Stages;
