import React from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setBalance } from '../../redux/reducers/authSlice';
import withRouter from '../../components/withRouter';


class Withdraw extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			balance: 0,
		}
	}

	decreaseBalance() {
		const {
			amount,
		} = this.props;
		const {
			balance,
		} = this.state;
		const resultAmount = Number(amount) - Number(balance);

		this.props.setBalance(resultAmount);
		this.props.router.navigate('/message/withdraw', {
			state: {
				amountAdded: Number(balance),
				newAmount: resultAmount,
			}
		});
	}

	render() {
		const {
			balance,
		} = this.state;

		return (
			<div id="withdraw" className="row p-0 m-0">
				<div className="col p-0 m-0">
					<section>
						<div className="container">
							<div className="investment__item main">
								<div className="investment__inner">
									<div className="investment__title">
										<Translation>{ (t) => t('withdraw.requestWithdrawal') }</Translation>
									</div>
								</div>

								<div className="investment__sep"></div>
								<div className="investment__inner">
									<Translation>{ (t) => t('withdraw.withdrawMoney') }</Translation>
								</div>

								<div className="investment__sep"></div>
								<div className="investment__inner">
									<div className="investment__title">
										<Translation>{ (t) => t('id') }</Translation>
										405597
									</div>
								</div>

								<form className="form form--wide">
									<div className="form__group">
										<label>
											<Translation>{ (t) => t('withdraw.minWithdraw') }</Translation>
											&nbsp;
											€ 50
										</label>

										<input
											type="number"
											name="amount"
											className="icon__networth"
											value={balance}
											onChange={e => this.setState({ balance: e.target.value })}
										/>

										<button type="submit" onClick={() => this.decreaseBalance()}>
											<Translation>{ (t) => t('Withdraw') }</Translation>
										</button>
									</div>
								</form>

								<div className="investment__sep"></div>
								<div className="investment__inner">
									<Translation>{ (t) => t('withdraw.accordingToRules') }</Translation>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		)
	}
}

Withdraw.propTypes = {
	amount: PropTypes.number.isRequired,
	setBalance: PropTypes.func.isRequired,
	router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	return {
		amount: state.auth.data.balance,
	}
};

const mapDispatchToProps = {
	setBalance,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Withdraw));
