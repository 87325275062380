import { createSlice } from '@reduxjs/toolkit';

import { getDashboardAsync } from '../thunks/dashboardThunk';


const initialState = {
	status: 'idle',
	data: {
	},
	error: '',
}

export const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		clearError: (state) => {
			state.error = '';
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getDashboardAsync.pending, (state) => {
				state.status = 'loading';
				state.error = '';
			})
			.addCase(getDashboardAsync.fulfilled, (state, action) => {
				state.status = 'idle';
				state.error = '';
			})
			.addCase(getDashboardAsync.rejected, (state, action) => {
				state.status = 'error';
				state.error = action.payload;
				state.data = {
				};
			});
	},
});

export const { clearError } = dashboardSlice.actions;

export default dashboardSlice.reducer;
