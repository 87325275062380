const entryKnowledge = {
	entryKnowledgeTest: 'Entry knowledge test',
	weWillAsk: 'We will ask you a couple of questions to determine whether you are a professional or non-sophisticated investor as per the classification requirements of the Crowdfunding Service Provider Regula, and receive the according Investor treatment. ',
	doYouWork: 'Do you work or have you worked in the financial sector for at least one year in a professional position which requires knowledge of the transactions or services envisaged, or the investor has held an executive position for at least 12 months in a legal person complying with minimum criteria qualifying as a sophisticated investor (legal person)?',
	haveYouCarried: 'Have you carried out transactions of a significant size on the capital markets at an average frequency of 10 per quarter, over the previous four quarters?',
	myNetWorth: 'My net worth, consisting of my income, my assets ( including financial investments and any cash deposits, but excluding personal and investment property and pension funds) less debt (financial commitments, including regular, existing or future commitments.)',
	thisIsSimulation: 'This is a simulation of what would happen, if you would lose 10% of your net worth, as requested by the Regula.',
	netWorth: 'Net Worth',
	simulation: 'Simulation',
	iAcknowledge: 'I acknowledge that I have received the results of this simulation and I understand the risks',
};

export default entryKnowledge;
