import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Group from './group';
import Item from './item';
import { useTranslation } from 'react-i18next';


const Accordion = (props) => {
	const [active, setActive] = useState(0);
	const children = Array.isArray(props.children) ? props.children : new Array(props.children);
	const { t } = useTranslation();

	return (
		<div className="container">
			<div className="faq__wrap">
				<div className="faq__side">
					{children.map((el, index) => (
						<div
							className={`faq__nav ${index === active ? 'active' : ''}`}
							key={`faqSide_${index}`}
							onClick={() => {
								setActive(index);
								props.setActive(index);
							}}>
							{t(`faq.${el.props.title}`)}
						</div>
					))}
				</div>

				<div className="faq__content">
					{props.children}
				</div>
			</div>
		</div>
	)
};

Accordion.defaultProps = {
	children: <></>,
	setActive: () => {},
};
Accordion.propTypes = {
	children: PropTypes.any,
	setActive: PropTypes.func,
};


Accordion.Group = Group;
Accordion.Item = Item;

export default Accordion;
