const header = {
	myProjects: 'My projects',
	myFinances: 'My finances',
	signIn: 'Sign In',
	signUp: 'Sign Up',
	depositWithdraw: 'Deposit/Withdraw',
	howItWorks: 'How it works',
	forBorrowers: 'For Borrowers',
	aboutUS: 'About Us',
}

export default header;
