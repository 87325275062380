import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


const DashboardComponent = (props) => {
	const {
		className,
		title,
		subtitle,
		isVisibleBtn,
		link,
	} = props;

	return (
		<section className={className}>
			<div className="container">
				{title && (<h2>{title}</h2>)}
				{subtitle && (<h4>{subtitle}</h4>)}

				{props.children}

				{isVisibleBtn && (
					<Link to={link} className="offers__btn">Show more</Link>
				)}
			</div>
		</section>
	)
};

DashboardComponent.defaultProps = {
	className: 'dashboard',
	title: null,
	subtitle: null,
	isVisibleBtn: false,
	link: '',
}

DashboardComponent.propTypes = {
	className: PropTypes.string,
	children: PropTypes.any,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	isVisibleBtn: PropTypes.bool,
	link: PropTypes.string.isRequired,
};

export default DashboardComponent;


