import React, { useState } from 'react';

import Tab from 'react-bootstrap/Tab';
import { Nav } from 'react-bootstrap';

const Invest = () => {
	const [active, setActive] = useState(false);

	return (
		<section className={`invest ${active && 'active'}`}>
			<div className="container">
				<Tab.Container
					defaultActiveKey="investors"
					className="m-auto mb-3">
					<div className="tab__navs">
						<Nav variant="pills" className="tab__navc justify-content-center">
							<Nav.Item>
								<Nav.Link
									className="tab__nav"
									eventKey="investors"
									onClick={() => setActive(false)}
									style={{
										transition: '0s',
										backgroundColor: active ? 'var(--gray)' : 'var(--blue)',
										borderRadius: '32px'
									}}>
									For Investors
								</Nav.Link>
							</Nav.Item>

							<Nav.Item>
								<Nav.Link
									className="tab__nav"
									eventKey="borrowers"
									onClick={() => setActive(true)}
									style={{ transition: '0s' }}>
									For Borrowers
								</Nav.Link>
							</Nav.Item>
						</Nav>
					</div>


					<Tab.Content>
						<Tab.Pane eventKey="investors" title="Investors" className="tab__item">
							<h2>For Investors</h2>
							<h3>Invest and make financial gains</h3>
							<ul>
								<li>Generate passive income</li>
								<li>Have individually chosen projects in your personally managed portfolio</li>
								<li>Have access to our professional analysis, monitoring and collection services</li>
							</ul>
							<div className="btn__wrap">
								<a href="" className="btn btn--big">Invest</a>
							</div>
						</Tab.Pane>

						<Tab.Pane eventKey="borrowers" title="Borrowers" className="tab__item">
							<h2>For Borrowers</h2>
							<h3>Get funds and let the business grow</h3>

							<ul>
								<li>Raise funds for your trades directly from EU investors</li>
								<li>Have individually chosen projects in your personally managed portfolio</li>
								<li>Have access to our professional analysis, monitoring and collection services</li>
							</ul>

							<div className="btn__wrap">
								<a href="" className="btn btn--big">Borrow</a>
							</div>
						</Tab.Pane>
					</Tab.Content>
				</Tab.Container>
			</div>
		</section>
	)
};

export default Invest;
