const faq = {
	haveA: 'Have a',
	question: 'Question?',
	lookHere: 'Look here',
	investing: 'Investing',
	whoWeAre: 'Who we are',
	autoInvest: 'Auto-invest',
	withdravals: 'Withdravals',
};

export default faq;
