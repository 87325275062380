import React from 'react';

import Login from '../pages/auth';
import Forgot from '../pages/auth/forgot';
import Reset from '../pages/auth/reset';
import Register from '../pages/auth/register';


/**
 * Authorization routes
 */
const authRoutes = [
	{
		path: '/login',
		element: <Login />,
	},
	{
		path: '/forgot-password',
		element: <Forgot />,
	},
	{
		path: '/reset-password',
		element: <Reset />,
	},
	{
		path: '/register',
		element: <Register />,
	},
];

export default authRoutes;
