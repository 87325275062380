const projects = [
	{
		id: '6228824',
		date: '17.09.2022',
		rate: 17,
		amount: {
			current: 4850,
			debt: 0,
			remaining: 2000000,
		},
		items: [
			{
				date: '17.09.2022',
				rate: 17,
				amount: {
					current: 4850,
					debt: 0,
					remaining: 2000000,
				},
				status: 1,
			},
			{
				date: '17.09.2022',
				rate: 17,
				amount: {
					current: 4850,
					debt: 0,
					remaining: 2000000,
				},
				status: 1,
			},
			{
				date: '17.09.2022',
				rate: 17,
				amount: {
					current: 4850,
					debt: 0,
					remaining: 2000000,
				},
				status: 0,
			},
			{
				date: '17.09.2022',
				rate: 17,
				amount: {
					current: 4850,
					debt: 0,
					remaining: 2000000,
				},
				status: 1,
			},
		]
	},
	{
		id: '6228824',
		date: '17.09.2022',
		rate: 17,
		amount: {
			current: 4850,
			debt: 0,
			remaining: 2000000,
		},
		items: [
			{
				date: '17.09.2022',
				rate: 17,
				amount: {
					current: 4850,
					debt: 0,
					remaining: 2000000,
				},
				status: 1,
			},
			{
				date: '17.09.2022',
				rate: 17,
				amount: {
					current: 4850,
					debt: 0,
					remaining: 2000000,
				},
				status: 1,
			},
			{
				date: '17.09.2022',
				rate: 17,
				amount: {
					current: 4850,
					debt: 0,
					remaining: 2000000,
				},
				status: 0,
			},
			{
				date: '17.09.2022',
				rate: 17,
				amount: {
					current: 4850,
					debt: 0,
					remaining: 2000000,
				},
				status: 1,
			},
		]
	},
	{
		id: '6228824',
		date: '17.09.2022',
		rate: 17,
		amount: {
			current: 4850,
			debt: 0,
			remaining: 2000000,
		},
		items: [
			{
				date: '17.09.2022',
				rate: 17,
				amount: {
					current: 4850,
					debt: 0,
					remaining: 2000000,
				},
				status: 1,
			},
			{
				date: '17.09.2022',
				rate: 17,
				amount: {
					current: 4850,
					debt: 0,
					remaining: 2000000,
				},
				status: 1,
			},
			{
				date: '17.09.2022',
				rate: 17,
				amount: {
					current: 4850,
					debt: 0,
					remaining: 2000000,
				},
				status: 0,
			},
			{
				date: '17.09.2022',
				rate: 17,
				amount: {
					current: 4850,
					debt: 0,
					remaining: 2000000,
				},
				status: 1,
			},
		]
	},
	{
		id: '6228824',
		date: '17.09.2022',
		rate: 17,
		amount: {
			current: 4850,
			debt: 0,
			remaining: 2000000,
		},
		items: [
			{
				date: '17.09.2022',
				rate: 17,
				amount: {
					current: 4850,
					debt: 0,
					remaining: 2000000,
				},
				status: 1,
			},
			{
				date: '17.09.2022',
				rate: 17,
				amount: {
					current: 4850,
					debt: 0,
					remaining: 2000000,
				},
				status: 1,
			},
			{
				date: '17.09.2022',
				rate: 17,
				amount: {
					current: 4850,
					debt: 0,
					remaining: 2000000,
				},
				status: 0,
			},
			{
				date: '17.09.2022',
				rate: 17,
				amount: {
					current: 4850,
					debt: 0,
					remaining: 2000000,
				},
				status: 1,
			},
		]
	},
];

export default projects;
