import React from 'react';
import { Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../assets/index.scss';
import TopBar from '../components/layout/topbar';
import Header from '../components/layout/header';
import Footer from '../components/layout/footer';
import NotFoundPageError from '../components/rootErrorBoundary';


/**
 * App HOC
 */
class App extends React.Component {
    render() {
        const {
            isAuth,
            show404,
            data,
        } = this.props;

        return (
            <>
                {isAuth}
                <TopBar
                    isAuth={isAuth}
                    id={data.id}
                    type={data.type}
                    firstName={data.firstName}
                    lastName={data.lastName}
                    balance={data.balance}
                    currency={data.currency}
                />

                <Header
                    isAuth={isAuth}
                    type={data.type}
                />

                <main id="main">
                    <div className="container-fluid m-0 p-0">
                        {!show404 && (
                            <Outlet isAuth={isAuth} />
                        )}
                    </div>

                    {show404 && (<NotFoundPageError />)}
                </main>

                <Footer />
            </>
        )
    }
}


App.propTypes = {
    isAuth: PropTypes.bool,
    show404: PropTypes.bool,
    data: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        data: state.auth.data,
        isAuth: state.auth.isAuth
    }
};


export default connect(mapStateToProps, null)(App)
