const blogs = {
	items: [
		{
			date: '01 March 2022',
			title: 'Our investment policies',
			description: 'A regulation is a legal act of the European Union that becomes immediately enforceable as law in all member states simultaneously.',
			img: 'blog__img.png',
			link: 'test',
		},
		{
			date: '02 March 2022',
			title: 'Our investment policies',
			description: 'A regulation is a legal act of the European Union that becomes immediately enforceable as law in all member states simultaneously.',
			img: 'blog__img.png',
			link: 'test',
		},
		{
			date: '03 March 2022',
			title: 'Our investment policies',
			description: 'A regulation is a legal act of the European Union that becomes immediately enforceable as law in all member states simultaneously.',
			img: 'blog__img.png',
			link: 'test',
		},
		{
			date: '04 March 2022',
			title: 'Our investment policies',
			description: 'A regulation is a legal act of the European Union that becomes immediately enforceable as law in all member states simultaneously.',
			img: 'blog__img.png',
			link: 'test',
		},
		{
			date: '05 March 2022',
			title: 'Our investment policies',
			description: 'A regulation is a legal act of the European Union that becomes immediately enforceable as law in all member states simultaneously.',
			img: 'blog__img.png',
			link: 'test',
		},
		{
			date: '06 March 2022',
			title: 'Our investment policies',
			description: 'A regulation is a legal act of the European Union that becomes immediately enforceable as law in all member states simultaneously.',
			img: 'blog__img.png',
			link: 'test',
		},
	],
	hero: {
		date: '03 March 2022',
		title: 'The new EU Crowdfunding Regulation',
		description: 'Real estate crowdfunding as an industry is relatively new but increasingly growing; thus, it is not regulated just yet. But it\'s hard to ignore its presence – the real estate crowdfunding market is expected to reach a whopping $869 Billion by 2027, and this has prompted the EU parliament to announce Regulation (EU) 2020/1503, which refers to all EU member states and will come into force in November 2021.',
		img: 'hero__img.png',
		author: {
			title: 'James Arthur',
			date: 'Feb',
			img: 'hero__author.png',
			readTime: '5/6 min',
		},
	},
	total: 6,
};

export default blogs;
