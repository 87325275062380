import React from 'react';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import withRouter from '../../components/withRouter';
import ProjectCard from './card';
import ProjectInfo from './info';
import { getProject } from '../../redux/API/project';
import Loader from '../../components/loader';
import { setProject } from '../../redux/reducers/investmentSlice';


class Project extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			card: {
				id: 0,
				title: '',
				amount: {
					raised: 0,
					fulfilled: 0,
					investment: 0,
					available: 0,
					min: 0,
				},
				completed: 0,
				left: 0,
				rate: 0,
				term: 0,
				score: '',
				ltv: 0,
				purpose: '',
				security: '',
				repayment: ''
			},
			info: '<div></div>'
		};
	}

	componentDidMount() {
		const projectId = this.props.router.params.projectId;

		this.getProject({ projectId })
	}

	async getProject(params) {
		const response = await getProject(params);

		this.setState({
			card: response.data,
			isLoading: false,
		});
	}

	render() {
		const {
			card,
			isLoading,
		} = this.state;
		const {
			amount,
			type,
			name,
		} = this.props;
		card.amount.available = amount;

		return (
			<div id="project" className="row p-0 m-0">
				<div className="col p-0 m-0">
					<div className="breadcrumbs">
						<div className="container">
							<Link to={'/projects'} className="breadcrumbs__back" />

							<div className="breadcrumbs__title">
								<Translation>{ (t, { i18n }) =>
									<>
										{t('loan')}&nbsp;
										<span>{ t('id') } {card.id}</span>
									</>
								}</Translation>
							</div>
						</div>
					</div>

					{isLoading
					? (
						<div style={{ margin: '5rem' }}>
							<Loader />
						</div>
					)
					: (
						<>
							<ProjectCard
								{...card}
								type={type}
								name={name}
								router={this.props.router}
								setProject={data => this.props.setProject(data)}
							/>

							<ProjectInfo {...card} />
						</>
					)}
				</div>
			</div>
		)
	}
}

Project.propTypes = {
	amount: PropTypes.number.isRequired,
	type: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	router: PropTypes.object.isRequired,
	setProject: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {
		amount: state.auth.data.balance,
		type: state.auth.data.type,
		name: state.auth.data.name,
	}
};
const mapDispatchToProps = {
	setProject,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Project));
