import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';


import Sidebar from './sidebar';
import List from './list';
import Loader from '../../components/loader';
import { getProjects } from '../../redux/API/projects';
import BulletNav from '../../components/bulletNav';


class Projects extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			projects: [],
		};
	}

	componentDidMount() {
		this.getProjects();
	}

	async getProjects() {
		const response = await getProjects();

		this.setState({
			projects: response.data,
			isLoading: false,
		});
	}

	render() {
		const {
			isLoading,
			projects,
		} = this.state;

		return (
			<div id="projects" className="row p-0 m-0">
				<div className="col p-0 m-0">
					<BulletNav
						items={[
							this.props.t('fundraising'),
							this.props.t('active'),
							this.props.t('finalized'),
						]}
					/>

					<section className="main">
						<div className="container">
							<div className="list__wrap">
								<div className="sidebar__showfilters only--mobile">{this.props.t('projects.showFilter')}</div>

								<Sidebar />

								{isLoading
								? (
									<Loader />
								)
								: (
									<List data={projects} />
								)}
							</div>
						</div>
					</section>
				</div>
			</div>
		)
	}
}

Projects.propTypes = {
	t: PropTypes.func.isRequired,
};

export default withTranslation()(connect(null, null)(Projects));
