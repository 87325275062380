import { createSlice } from '@reduxjs/toolkit';

import { getNotificationsAsync } from '../thunks/notificationThunk';


const initialState = {
	status: 'idle',
	data: [],
	error: '',
}

export const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		addNotification: (state, action) => {
			state.data = [
				...state.data,
				action.payload,
			]
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getNotificationsAsync.pending, (state) => {
				state.status = 'loading';
				state.error = '';
			})
			.addCase(getNotificationsAsync.fulfilled, (state, action) => {
				state.status = 'idle';
				state.error = '';
				state.data = action.payload;
			})
			.addCase(getNotificationsAsync.rejected, (state, action) => {
				state.status = 'error';
				state.error = action.payload;
				state.data = [];
			});
	},
});

export const { addNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;
