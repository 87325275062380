import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Keyboard, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';


const Teams = (props) => {
	const {
		items,
	} = props;
	const { t } = useTranslation();

	return (
		<section id="teams" className="teams">
			<div className="container">
				<h2>
					{t('aboutUS.coreTeam')}
					<span>.</span>
				</h2>

				<Swiper
					spaceBetween={25}
					slidesPerView={4}
					modules={[Navigation, Keyboard]}
					effect={'fade'}
					onSlideChange={() => console.log('slide change')}
					onSwiper={(swiper) => console.log(swiper)}
					className="teams__content"
					navigation={{
						nextEl: '.slick-next',
						prevEl: '.slick-prev',
						disabledClass: 'swiper-button-disabled'
					}}
					keyboard
					watchSlidesProgress>
					{items.map((el, index) => (
						<SwiperSlide key={`teamsSlide_${index}`}>
							{({ isVisible }) => (
								<div className={`teams__item slick-slide ${isVisible ? 'slick-active' : ''}`}>
									<div className="teams__title">{el.title}</div>
									<div className="teams__img">
										<img src={`assets/img/${el.image}`} />
									</div>
									<div className="teams__name">{el.username.split(' ').join('\r\n')}</div>
									<a href="#" className={`teams__${el.type}`} />
								</div>
							)}
						</SwiperSlide>
					))}
				</Swiper>

				<button className="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false">Previous</button>
				<button className="slick-next slick-arrow" aria-label="Next" type="button" aria-disabled="false">Next</button>
			</div>
		</section>
	)
};

Teams.defaultProps = {
	items: [
		{
			title: 'Managing Partner',
			username: 'Lisa Langley',
			type: 'linkedin',
			image: 'team__1.png',
		},
		{
			title: 'Managing Partner',
			username: 'Lisa Langley',
			type: 'linkedin',
			image: 'team__2.png',
		},
		{
			title: 'Managing Partner',
			username: 'Lisa Langley',
			type: 'linkedin',
			image: 'team__3.png',
		},
		{
			title: 'Managing Partner',
			username: 'Lisa Langley',
			type: 'facebook',
			image: 'team__4.png',
		},
	]
};

Teams.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string,
		body: PropTypes.string,
	})),
};

export default Teams;
