import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Items = (props) => {
	const {
		data
	} = props;
	const { t } = useTranslation();

	return (
		<div className="blog row">
			{data.map((el, index) => (
				<div className="col-33" key={`blogItem_${index}`}>
					<div className="blog__item">
						<div className="blog__img">
							<img src={`/assets/img/${el.img}`} alt="Blog" />
						</div>

						<div className="blog__date">{el.date}</div>
						<div className="blog__title">{el.title}</div>
						<div className="blog__description">{el.description}</div>

						<Link to={`/blog/${el.link}`} className="btn">{t('blogs.moreInfo')}</Link>
					</div>
				</div>
			))}
		</div>
	)
};

Items.defaultProps = {
	page: 0,
	data: [
		{
			date: '03 March 2022',
			title: 'Our investment policies',
			description: 'A regulation is a legal act of the European Union that becomes immediately enforceable as law in all member states simultaneously.',
			img: 'blog__img.png',
			link: '#',
		},
		{
			date: '03 March 2022',
			title: 'Our investment policies',
			description: 'A regulation is a legal act of the European Union that becomes immediately enforceable as law in all member states simultaneously.',
			img: 'blog__img.png',
			link: '#',
		},
		{
			date: '03 March 2022',
			title: 'Our investment policies',
			description: 'A regulation is a legal act of the European Union that becomes immediately enforceable as law in all member states simultaneously.',
			img: 'blog__img.png',
			link: '#',
		},
	],
};

Items.propTypes = {
	page: PropTypes.number,
	data: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string,
		date: PropTypes.string,
		img: PropTypes.string,
		description: PropTypes.string,
		link: PropTypes.string,
	}))
};

export default Items;
