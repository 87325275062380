const investor = [
	{
		id: 'EU095714',
		type: 'Investor',
		firstName: 'James',
		middleName: 'Jameson',
		lastName: 'Iutchinson',
		balance: 1000000,
		KYCCompleted: true,
		borrowerCompleted: false,
		login: {
			email: 'user@investor.com',
			password: 'investor',
		},
	},
	{
		id: 'EU095715',
		type: 'Investor',
		firstName: 'Arthur',
		middleName: 'Jameson',
		lastName: 'Thompson',
		balance: 1000000,
		KYCCompleted: false,
		borrowerCompleted: false,
		login: {
			email: 'user@investor2.com',
			password: 'investor',
		},
	},
];

export default investor;
