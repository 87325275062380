import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';


const Card = (props) => {
	const { t } = useTranslation();
	const {
		id,
		title,
		classType,
		price,
		completed,
		rate,
		term,
		left,
		img,
	} = props;

	return (
		<div className="card">
			<div className="card__img">
				<img src={img} alt="Card" />
			</div>

			<div className="card__header">
				<div className="card__flex">
					<div className="card__id"><span>id</span>{id}</div>
					<div className="card__class">{classType}</div>
				</div>
				<div className="card__title">{title}</div>
			</div>

			<div className="card__flex">
				<div className="card__price">
					<div className="card__price-main">€{price.main}</div>
					<div className="card__price-secondary">{price.completed}% out of €{price.secondary}</div>
				</div>
				<div className="card__round">
					<svg className="card__progress" width="92" height="92">
						<circle
							className="ready__circle"
							stroke="#468aff"
							strokeWidth="8"
							strokeDasharray="300"
							fill="transparent"
							strokeLinecap="round"
							r="42"
							cx="46"
							cy="46" />
					</svg>
					<div className="card__rate"><span>{completed}%</span>{t('left')}</div>
				</div>
			</div>

			<div className="card__flex card__rates">
				<div className="card__rate"><span>{rate}%</span>{t('rate')}</div>
				<div className="card__rate"><span>{term} {t('month').toLowerCase()}</span>{t('term')}</div>
				<div className="card__rate"><span>{left}</span>{t('landing.daysLeft')}</div>
			</div>
			<div className="card__btn">
				<a className="btn">More info</a>
			</div>
		</div>
	)
};

Card.defaultProps = {
	title: 'Vegan sweets production',
	id: '00259',
	classType: 'B1',
	price: {
		main: 22710,
		secondary: 150000,
		completed: 30,
	},
	completed: 20,
	left: 15,
	rate: 14,
	term: 24,
	img: 'card__img.png',
};

Card.propTypes = {
	title: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	classType: PropTypes.string.isRequired,
	price: PropTypes.shape({
		main: PropTypes.number.isRequired,
		secondary: PropTypes.number.isRequired,
		completed: PropTypes.number.isRequired,
	}).isRequired,
	completed: PropTypes.number.isRequired,
	left: PropTypes.number.isRequired,
	rate: PropTypes.number.isRequired,
	term: PropTypes.number.isRequired,
	img: PropTypes.string.isRequired,
};

export default Card;
