import React from 'react';
import { Translation } from 'react-i18next';

import Features from './features';
import Roadmap from './roadmap';
import TeamImg from '../../assets/img/team__img.jpg';
import Teams from './teams';
import Together from './together';
import ContactUs from '../../components/contactUs';


class AboutUs extends React.Component {
	render() {
		return (
			<div id="about-us" className="row p-0 m-0">
				<div className="col p-0 m-0">
					<section className="title__big">
						<div className="container">
							<Translation>
								{ (t) => (<>
									<h1 data-echo={t('aboutUs.aboutUs')}>
										{t('aboutUS.where')}
										&nbsp;
										<span>{t('aboutUS.money')}</span>
										<br />
										&nbsp;
										{t('aboutUS.comes')}
									</h1>
								</>)}
							</Translation>
						</div>
					</section>

					<Features />

					<Roadmap />

					<section className="team-img">
						<Translation>
							{ (t) => (
								<img src={TeamImg} alt={t('aboutUS.teamImage')}/>
							)}
						</Translation>
					</section>

					<Teams />

					<Together />

					<ContactUs />
				</div>
			</div>
		)
	}
}

export default AboutUs;
