import { createAsyncThunk } from '@reduxjs/toolkit';

import { getProjects } from '../API/projects';


export const getProjectsAsync = createAsyncThunk(
	'auth/getProjects',
	async (data, { rejectWithValue }) => {
		try {
			const response = await getProjects(data);
			return response.data
		} catch (err) {
			return rejectWithValue(err.data.error)
		}
	}
);
