import React from 'react';

import { ReactComponent as Logo } from '../../assets/img/logo__element.svg';
const Together = () => {
	return (
		<section className="together">
			<div className="container">
				<Logo className="together__img" alt="Inlendo" />

				<div className="together__description">
					Together we will create a unique marketplace, where local EU businesses will be directly
					supported by their dedicated audience, creating a continuous, stable and reliable relationship
					to secure business long-term cash flow growth.<br /><br />
					All loan deals are directly concluded between borrower and investor. All transactions are made
					on a segregated client’s account, that is fully separated from the platform&apos;s own current
					accounts, both provided by the most reliable financial service supplier on the European
					crowdlending market.
				</div>
			</div>
		</section>
	)
};

export default Together;
