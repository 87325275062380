const projects = {
	dayUntilRepayment: 'Expected day until repayment',
	dayOfReturn: 'Scheduled date of return in Offer',
	project: 'Project',
	pa: 'p.a.',
	days: 'days',
	showFilter: 'Show Filters',
};

export default projects;
