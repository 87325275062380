import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from '../../assets/img/logo__element.svg';
import { ReactComponent as Lemonway } from '../../assets/img/lemonway.svg';
import { ReactComponent as LinkedIn } from '../../assets/img/linkedin.svg';
import { ReactComponent as Facebook } from '../../assets/img/facebook.svg';


const Footer = () => {
	const { t } = useTranslation();

	return (
		<footer className="footer">
			<div className="container">
				<h3>{t('footer.subscribeToNews')}</h3>

				<div className="form__wrap">
					<form className="form subscribe">
						<input type="text" name="email" placeholder={t('footer.enterEmail')} />
						<input type="submit" value="Subscribe" />
					</form>
				</div>

				<div className="footer__sep"></div>
				<div className="footer__row">
					<div className="footer__col">
						<Logo className="footer__logo" alt="Inlendo" />
					</div>

					<div className="footer__col">
						<div className="footer__title">Inlendo</div>
						<Link to={'/landing'} className="footer__link">{t('footer.landing')}</Link>
						<Link to={'/about-us'} className="footer__link">{t('footer.aboutUs')}</Link>
						<a href="#" className="footer__link">{t('footer.loanOriginators')}</a>
						<Link to={'/blogs'} className="footer__link">{t('Blog')}</Link>
						<a href="#" className="footer__link">{t('footer.newsAndUpdates')}</a>
						<a href="#" className="footer__link">{t('footer.annualReports')}</a>
					</div>

					<div className="footer__col">
						<div className="footer__title">{t('Legal')}</div>
						<a href="#" className="footer__link">{t('footer.userTerms')}</a>
						<a href="#" className="footer__link">{t('footer.privacyPolicy')}</a>
						<a href="#" className="footer__link">{t('footer.generalLoanTerms')}</a>
						<a href="#" className="footer__link">{t('footer.cookiePolicy')}</a>
						<a href="#" className="footer__link">{t('footer.policyAvoidance')}</a>
						<a href="#" className="footer__link">{t('footer.investorRisk')}</a>
						<a href="#" className="footer__link">{t('footer.lemonWayTC')}</a>
						<a href="#" className="footer__link">{t('footer.legalOpinion')}</a>
					</div>

					<div className="footer__col">
						<div className="footer__title">{t('footer.helpCenter')}</div>
						<Link to={'/faq'} className="footer__link">{t('Faq')}</Link>
						<Link to={'/contact-us'} className="footer__link">{t('footer.contactUs')}</Link>
						<Link to={'/documents'} className="footer__link">{t('footer.documents')}</Link>
						<a href="#" className="footer__link">{t('footer.privacyPolicy')}</a>
						<a href="#" className="footer__link">{t('footer.generalLoanTerms')}</a>
					</div>

					<div className="footer__col">
						<div className="footer__title">{t('footer.companyDetails')}</div>
						<a href="#" className="footer__link">{t('footer.legalAddress')}:</a>
						<span className="footer__link">{t('footer.registrationNumber')}</span>
					</div>
				</div>

				<div className="footer__sep"></div>
				<div className="footer__text">
					<b>{t('footer.paymentOperator')}</b>
					<br />
					{t('footer.partnerOf')}&nbsp;
					<a href="">{t('footer.lemonWay')}</a>
					{t('footer.europeanPayment')}
					<br />
					<Lemonway alt={'Lemonway'} />
				</div>

				<div className="footer__sep"></div>
				<div className="footer__row">
					{t('footer.copyright')}

					<div className="footer__socials">
						<a href="#" className="footer__social">
							<LinkedIn alt={'LinkedIn'} />
						</a>

						<a href="#" className="footer__social">
							<Facebook alt={'Facebook'} />
						</a>
					</div>
				</div>
			</div>
		</footer>
	)
};

export default Footer;
