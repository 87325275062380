import html from './html';
import project from './project';
import projects from './projects';
import deposit from './deposit';
import header from './header';
import footer from './footer';
import withdraw from './withdraw';
import dashboard from './dashboard';
import message from './message';
import faq from './faq';
import aboutUS from './aboutUs';
import contactUS from './contactUs';
import blogs from './blogs';
import blog from './blog';
import landing from './landing';
import entryKnowledge from './entryKnowledge';
import finances from './finances';
import invest from './invest';
import kyc from './kyc';
import documents from './documents';
import notifications from './notifications';


const translation = {
	id: 'ID',
	loan: 'Loan',
	security: 'Security',
	status: 'Status',
	country: 'Country',
	owners: 'Owners',
	cogs: 'COGS',
	ebitda: 'EBITDA',
	borrower: 'Borrower',
	stages: 'Stages',
	date: 'Date',
	stage: 'Stage',
	type: 'Type',
	rate: 'Rate',
	term: 'Term',
	sell: 'Sell',
	comments: 'Comments',
	approved: 'Approved',
	pending: 'Pending',
	declined: 'Declined',
	Documents: 'Documents',
	investment: 'Investment',
	readMore: 'Read more',
	readLess: 'Read less',
	bank: 'Bank',
	Dashboard: 'Dashboard',
	Invest: 'Invest',
	Deposit: 'Deposit',
	Withdraw: 'Withdraw',
	Project: 'Project',
	Projects: 'projects',
	Blog: 'Blog',
	Faq: 'FAQ',
	left: 'Left',
	month: 'Month',
	Legal: 'Legal',
	Home: 'Home',
	Search: 'Search',
	phone: 'Phone',
	email: 'Email',
	address: 'Address',
	name: 'Name',
	Message: 'Message',
	yes: 'Yes',
	no: 'No',
	next: 'Next',
	confirm: 'Confirm',
	cancel: 'Cancel',
	fundraising: 'Fundraising',
	active: 'Active',
	finalized: 'Finalized',
	schedules: 'Schedules',
	transactions: 'Transactions',
	amount: 'Amount',
	remaining: 'Remaining',
	logout: 'Logout',
	firstName: 'First name',
	middleName: 'Middle name',
	lastName: 'Last name',
	selfEmployed: 'Self-Employed',
	employed: 'Employed',
	investor: 'Investor',
	All: 'All',
	Notifications: 'Notifications',
	Submit: 'Submit',

	html,
	project,
	projects,
	deposit,
	header,
	footer,
	withdraw,
	dashboard,
	message,
	faq,
	aboutUS,
	contactUS,
	blogs,
	blog,
	landing,
	entryKnowledge,
	finances,
	invest,
	kyc,
	documents,
	notifications,
};

export default translation;
