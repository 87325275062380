import React from 'react';
import PropTypes from 'prop-types';


const Features = (props) => {
	const {
		items,
	} = props;

	return (
		<section className="features">
			<div className="container">
				{items.map((el, index) => (
					<div className="features__item" key={`feature_${index}`}>
						<div className="features__title">
							<img src={`assets/icons/${el.icon}`} />
							{el.title}
						</div>

						<div className="features__subtitle">
							{el.body}
						</div>
					</div>
				))}
			</div>
		</section>
	)
}

Features.defaultProps = {
	items: [
		{
			title: 'Licensed and Regulated',
			icon: 'check--alt.svg',
			body: 'INLENDO is a licensed fintech service provider. It operates in compliance with the EU Regulation 2020/1503 on European crowdlending service providers. The firm is licensed and supervised by the Financial and Capital Market Commission of the Republic of Latvia.',
		},
		{
			title: 'Cutting-edge software platform',
			icon: 'laptop.svg',
			body: 'The lending platform of INLENDO runs on robust and sophisticated software architecture, vetted and approved by the financial services regulators. All core processes are fully automated, secured and error-proof.',
		},
		{
			title: 'Rigorous risk scoring',
			icon: 'alert.svg',
			body: 'INLENDO is a licensed fintech service provider. It operates in compliance with the EU Regulation 2020/1503 on European crowdlending service providers. The firm is licensed and supervised by the Financial and Capital Market Commission of the Republic of Latvia.',
		},
		{
			title: 'Segregated accounts',
			icon: 'users.svg',
			body: 'The lending platform of INLENDO runs on robust and sophisticated software architecture, vetted and approved by the financial services regulators. All core processes are fully automated, secured and error-proof.',
		},
		{
			title: 'Deployment control',
			icon: 'papershield.svg',
			body: 'In trade-financing projects, INLENDO controls the remittance of project funds to outside suppliers in accordance with the approved financing plan. Potential misallocation of funds by the Borrower is prevented.',
		},
		{
			title: 'Receivables control',
			icon: 'receiver.svg',
			body: 'INLENDO utilises legal control tools to oversee the revenue cash flows of the Borrower’s operations. Liquidity issues are detected early and defaults are prevented.',
		},
		{
			title: 'Collateral and recovery',
			icon: 'pig.svg',
			body: 'Most Borrower projects are secured by collateral, such as real estate, inventory or other physical assets. In case of Borrower default, INLENDO will carry out rigorous legal recovery procedures on behalf of the Investors.',
		},
		{
			title: 'Skin in the game',
			icon: 'hands.svg',
			body: 'INLENDO earns most of its fee when the loans are repaid. It also invests its own funds into each Borrower project, with up to 10% of each project amount. Its interests are therefore fully aligned with the Investors.',
		},
	],
};

Features.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string,
		icon: PropTypes.string,
		body: PropTypes.string,
	}))
};

export default Features;
