import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import List from './list';
import Sidebar from './sidebar';
import Blank from './blank';
import { getFinances } from '../../redux/API/finances';
import BulletNav from '../../components/bulletNav';
import Loader from '../../components/loader';


class Finances extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			projects: [],
		};
	}

	componentDidMount() {
		this.getFinances();
	}

	async getFinances() {
		const response = await getFinances();

		this.setState({
			projects: response.data,
			isLoading: false,
		});
	}

	render() {
		const {
			projects,
			isLoading,
		} = this.state;

		return (
			<div id="finances" className="row p-0 m-0">
				<div className="col p-0 m-0">
					<BulletNav
						items={[
							this.props.t('schedules'),
							this.props.t('transactions'),
							this.props.t('finances.textReport'),
						]}
					/>

					<section className="main">
						<div className="container">
							<div className="list__wrap">
								{projects.length > 0 && <Sidebar />}

								{isLoading
								? (
									<Loader />
								)
								: projects.length > 0
									? (
										<List data={projects} />
									)
									: (
										<></>
									)
								}

								{(!isLoading && projects.length === 0) && <Blank />}
							</div>
						</div>
					</section>
				</div>
			</div>
		)
	}
}

Finances.propTypes = {
	t: PropTypes.func.isRequired,
};

export default withTranslation()(Finances);
