import React from 'react';

import Select from './select';


const Sidebar = (props) => {
	return (
		<div className="sidebar">
			<div className="sidebar__title">Interest Rate</div>
			<div className="input__wrap">
				<input type="text" placeholder="From (%)" />
				<input type="text" placeholder="To (%)" />
			</div>

			<div className="sidebar__title">Country</div>
			<Select>
				<label htmlFor="1">
					Czech Republic
					<input type="checkbox" id="1" defaultChecked />
				</label>
				<label htmlFor="2">
					Kazakhstan
					<input type="checkbox" id="2" />
				</label>
				<label htmlFor="3">
					Lithuania
					<input type="checkbox" id="3" />
				</label>
				<label htmlFor="4">
					Moldova
					<input type="checkbox" id="4" />
				</label>
				<label htmlFor="5">
					Philippines
					<input type="checkbox" id="5" />
				</label>
				<label htmlFor="6">
					Czech Republic
					<input type="checkbox" id="6" />
				</label>
				<label htmlFor="7">
					Kazakhstan
					<input type="checkbox" id="7" />
				</label>
				<label htmlFor="8">
					Lithuania
					<input type="checkbox" id="8" />
				</label>
				<div className="select__btn">Show more</div>
			</Select>

			<div className="sidebar__title">Loan Rating</div>
			<Select>
				<label htmlFor="o1">
					Option 1
					<input type="checkbox" id="o1" defaultChecked />
				</label>
				<label htmlFor="o2">
					Option 2
					<input type="checkbox" id="o2" defaultChecked />
				</label>
				<label htmlFor="o3">
					Option 3
					<input type="checkbox" id="o3" defaultChecked />
				</label>
				<label htmlFor="o4">
					Option 4
					<input type="checkbox" id="o4" />
				</label>
			</Select>

			<div className="sidebar__title">Loan Types</div>
			<Select>
				<label htmlFor="l1">
					Loan 1
					<input type="checkbox" id="l1" />
				</label>
				<label htmlFor="l2">
					Loan 2
					<input type="checkbox" id="l2" />
				</label>
				<label htmlFor="l3">
					Loan 3
					<input type="checkbox" id="l3" />
				</label>
			</Select>

			<div className="sidebar__title">Loan Statuses</div>
			<Select>
				<label htmlFor="s1">
					Status 1
					<input type="checkbox" id="s1" />
				</label>
				<label htmlFor="s2">
					Status 2
					<input type="checkbox" id="s2" />
				</label>
				<label htmlFor="s3">
					Status 3
					<input type="checkbox" id="s3" />
				</label>
				<label htmlFor="s4">
					Status 4
					<input type="checkbox" id="s4" />
				</label>
				<label htmlFor="s5">
					Status 5
					<input type="checkbox" id="s5" />
				</label>
				<label htmlFor="s6">
					Status 6
					<input type="checkbox" id="s6" />
				</label>
				<div className="select__btn">Show more</div>
			</Select>

			<div className="sidebar__title">Currency Exposure</div>
			<Select>
				<label htmlFor="c1">
					Currency 1
					<input type="checkbox" id="c1" />
				</label>
				<label htmlFor="c2">
					Currency 2
					<input type="checkbox" id="c2" />
				</label>
				<label htmlFor="c3">
					Currency 3
					<input type="checkbox" id="c3" />
				</label>
			</Select>
		</div>
	)
};

export default Sidebar;
