import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Keyboard } from 'swiper';

const Blog = () => {
	const blogs = [
		{
			img: 'blog__img.png',
			date: '03 March 2022',
			title: 'Our investment policies',
			description: 'A regulation is a legal act of the European Union that\n' +
				'\t\t\t\t\t\t\t\t\t\tbecomes immediately enforceable as law in all member states simultaneously.',
		},
		{
			img: 'blog__img--alt.png',
			date: '03 March 2022',
			title: 'Our investment policies',
			description: 'A regulation is a legal act of the European Union that\n' +
				'\t\t\t\t\t\t\t\t\t\tbecomes immediately enforceable as law in all member states simultaneously.',
		},
		{
			img: 'blog__img.png',
			date: '03 March 2022',
			title: 'Our investment policies',
			description: 'A regulation is a legal act of the European Union that\n' +
				'\t\t\t\t\t\t\t\t\t\tbecomes immediately enforceable as law in all member states simultaneously.',
		},
		{
			img: 'blog__img--alt.png',
			date: '03 March 2022',
			title: 'Our investment policies',
			description: 'A regulation is a legal act of the European Union that\n' +
				'\t\t\t\t\t\t\t\t\t\tbecomes immediately enforceable as law in all member states simultaneously.',
		}
	];

	return (
		<section id="blogs">
			<div className="container">
				<h2>Our Blog<span>.</span></h2>

				<Swiper
					spaceBetween={50}
					slidesPerView={3}
					modules={[Navigation, Keyboard]}
					effect={'fade'}
					onSlideChange={() => console.log('slide change')}
					onSwiper={(swiper) => console.log(swiper)}
					className="card__slider"
					navigation={{
						nextEl: '.slick-next',
						prevEl: '.slick-prev',
						disabledClass: 'swiper-button-disabled'
					}}
					keyboard
					watchSlidesProgress>
					{blogs.map((el, index) => (
						<SwiperSlide key={`blog_${index}`}>
							{({ isVisible }) => (
								<div className={`blog__item slick-slide ${isVisible ? 'slick-active' : ''}`}>
									<div className="blog__img">
										<img src={`/assets/img/${el.img}`} alt="Blog" />
									</div>

									<div className="blog__date">{el.date}</div>
									<div className="blog__title">{el.title}</div>
									<div className="blog__description">{el.description}
									</div>

									<a href="" className="btn">More info</a>
								</div>
							)}
						</SwiperSlide>
					))}

					<button className="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false">Previous</button>
					<button className="slick-next slick-arrow" aria-label="Next" type="button" aria-disabled="false">Next</button>
				</Swiper>

				<div className="btn__wrap">
					<a href="" className="btn btn--big">Read more</a>
				</div>
			</div>
		</section>
	)
};

export default Blog;
