import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Card, Item } from './item';


const SmsAuth = (props) => {
	const {
		phone,
	} = props;
	const { t } = useTranslation();

	return (
		<Card>
			<Item>
				<div className="investment__div">
					<div className="investment__title">{t('invest.authorisation')}</div>
					<div className="investment__subtext">
						{t('invest.inOrderToProceed')}
						<a href={`tel:${phone}`} className="investment__link">
							{phone}
						</a>
					</div>
				</div>

				<div className="investment__div">
					<div className="investment__wrap">
						<div className="investment__btn">{t('invest.requestCode')}</div>
						<input type="text" className="investment__code" placeholder="0000" maxLength="4" />
					</div>
				</div>
			</Item>
		</Card>
	)
};

SmsAuth.defaultProps = {
	phone: '+123 4567 8900',
};

SmsAuth.propTypes = {
	phone: PropTypes.string,
};

export default SmsAuth;
