import React from 'react';
import * as Yup from 'yup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Loader from '../../components/loader';
import { loginUserAsync } from '../../redux/thunks/authThunk';
import { clearError } from '../../redux/reducers/authSlice';
import withRouter from '../../components/withRouter';


const schema = Yup.object().shape({
	email: Yup
		.string()
		.email()
		.required()
		.matches(
			/^[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]{2,}$/,
			'Please provide valid email address'
		),
	password: Yup
		.string()
		.required()
		.min(7, 'Must be at least 8 characters')
		.max(20, 'Must be less  than 20 characters')
		.matches(
			/^[a-zA-Z0-9]+$/,
			'Cannot contain special characters or spaces.'
		),
});


class Login extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			password: '',
			isLoading: false,
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {
			isAuth,
			authStatus,
		} = this.props;
		const {
			isLoading,
		} = this.state;

		if (authStatus !== 'loading' && isLoading) {
			this.setState({
				isLoading: false,
			})
		}

		if (isAuth !== prevProps.isAuth && isAuth) {
			this.props.router.navigate('/dashboard');
		}
	}

	componentDidMount() {
		this.props.clearError();
	}

	onSubmit(data) {
		this.setState({
			email: data.email,
			password: data.password,
			isLoading: true,
		});

		this.props.loginUserAsync(data);
	}

	render() {
		const {
			isLoading,
		} = this.state;
		const {
			authError,
		} = this.props;
		
		return (
			<div id="login" className="row p-0 m-0">
				<div className="col p-0 m-0">
					<section className="s-login s-login--login">
						<div className="container">
							<h1>Log in</h1>

							{isLoading
							? (
								<div style={{
									width: '100%',
									height: '60vh',
									display: 'flex',
									alignItems: 'center'
								}}>
									<Loader type="white" />
								</div>
							)
							: (
								<Formik
									validationSchema={schema}
									onSubmit={data => this.onSubmit(data)}
									initialValues={{
										email: '',
										password: '',
									}}>
									{({
										handleSubmit,
										handleChange,
										handleBlur,
										values,
										touched,
										isValid,
										errors,
									}) => (
										<Form noValidate onSubmit={handleSubmit} className="form">
											<Form.Group controlId="email">
												<Form.Label className="label-hidden">Email address</Form.Label>
												<Form.Control
													type="email"
													name="email"
													placeholder="Email address"
													value={values.email}
													onChange={handleChange}
													isInvalid={!!errors.email}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.email}
												</Form.Control.Feedback>
											</Form.Group>

											<Form.Group controlId="password">
												<Form.Label className="label-hidden">Password</Form.Label>
												<Form.Control
													type="password"
													name="password"
													placeholder="Password"
													value={values.password}
													onChange={handleChange}
													isInvalid={!!errors.password}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.password}
												</Form.Control.Feedback>
											</Form.Group>

											{authError && authError.length > 0 && (
												<div className="auth-error">
													{authError}
												</div>
											)}

											<Button type="submit">Log in</Button>

											<p className="form__hint">
												Forgot your password?
												<Link to="../forgot-password">Recover here</Link>
											</p>
											<div className="form__sep"></div>
											<a href="" className="form__btn">Sign Up</a>
										</Form>
									)}
								</Formik>
							)}
						</div>
					</section>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		isAuth: state.auth.isAuth,
		authStatus: state.auth.status,
		authError: state.auth.error,
	}
};

const mapDispatchToProps = {
	loginUserAsync,
	clearError,
};


Login.propTypes = {
	isAuth: PropTypes.bool.isRequired,
	authStatus: PropTypes.string.isRequired,
	authError: PropTypes.string,
	loginUserAsync: PropTypes.func.isRequired,
	clearError: PropTypes.func.isRequired,
	router: PropTypes.object.isRequired,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
