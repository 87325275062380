import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Bag } from '../../assets/img/icons/bag.svg';


const Blank = (props) => {
	const { t } = useTranslation();

	return (
		<section className="message">
			<div className="container">
				<h1>{t('finances.nothingHere')}</h1>

				<Bag className="message__icon" />

				<div className="message__text">
					{t('finances.youCurrently')}
				</div>

				<a href="#" className="message__btn">{t('Invest')}</a>
			</div>
		</section>
	)
};

export default Blank;
