import React from 'react';
import { Translation } from 'react-i18next';

import ContactUS from '../../components/contactUs';


class ContactUs extends React.Component {
	render() {
		return (
			<div id="contact-us" className="row p-0 m-0">
				<div className="col p-0 m-0">
					<section className="title__big">
						<div className="container">
							<Translation>
								{
									(t) =>
										<h1 data-echo={t('contactUS.contactUs')}>
											{t('contactUS.were')} <span>{t('contactUS.here')}</span><br /> {t('contactUS.toHelp')}
										</h1>
								}
							</Translation>
						</div>
					</section>

					<ContactUS />
				</div>
			</div>
		)
	}
}

export default ContactUs;
