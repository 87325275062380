import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';


const Hero = (props) => {
	const {
		date,
		title,
		description,
		img,
		author,
	} = props;
	const { t } = useTranslation();

	return (
		<div className="hero__item">
			<div className="hero__img">
				<img src={`/assets/img/${img}`} alt="Post title" />
			</div>

			<div className="hero__content">
				<div className="hero__date">{date}</div>
				<div className="hero__title">{title}</div>
				<div className="hero__description">{description}</div>

				<div className="hero__sep"></div>
				<div className="author">
					<div className="author__img">
						<img src={`/assets/img/${author.img}`} alt="Author" />
					</div>

					<div className="author__content">
						<div className="author__title">{author.title}</div>
						<div className="author__date">
							{author.date}
							&nbsp;
							{author.readTime}
							&nbsp;
							{t('blogs.read')}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
};

Hero.defaultProps = {
	date: '03 March 2022',
	title: 'The new EU Crowdfunding Regulation',
	description: 'Real estate crowdfunding as an industry is relatively new but increasingly growing; thus, it is not regulated just yet. But it\'s hard to ignore its presence – the real estate crowdfunding market is expected to reach a whopping $869 Billion by 2027, and this has prompted the EU parliament to announce Regulation (EU) 2020/1503, which refers to all EU member states and will come into force in November 2021.',
	img: 'hero__img.png',
	author: {
		title: 'James Arthur',
		date: 'Feb',
		img: 'hero__author.png',
		readTime: '5/6 min',
	},
};

Hero.propTypes = {
	date: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	img: PropTypes.string,
	author: PropTypes.shape({
		title: PropTypes.string,
		date: PropTypes.string,
		img: PropTypes.string,
		readTime: PropTypes.string,
	}),
};

export default Hero;
