import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as Yup from 'yup';
import { Formik } from 'formik';


const schema = Yup.object().shape({
	email: Yup
		.string()
		.email()
		.required()
		.matches(
			/^[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]{2,}$/,
			'Please provide valid email address'
		),
});


const Forgot = () => {
	return (
		<div id="forgot" className="row p-0 m-0">
			<div className="col p-0 m-0">
				<section className="s-login s-login--login">
					<div className="container">
						<h1>Recover password</h1>

						<Formik
							validationSchema={schema}
							onSubmit={console.log}
							initialValues={{
								email: '',
							}}>
							{({
								handleSubmit,
								handleChange,
								handleBlur,
								values,
								touched,
								isValid,
								errors,
							}) => (
								<Form noValidate onSubmit={handleSubmit} className="form">
									<Form.Group controlId="email">
										<Form.Label className="label-hidden">Email address</Form.Label>
										<Form.Control
											type="email"
											name="email"
											placeholder="Email address"
											value={values.email}
											onChange={handleChange}
											isInvalid={!!errors.email}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.email}
										</Form.Control.Feedback>
									</Form.Group>

									<Button type="submit">Send link</Button>
								</Form>
							)}
						</Formik>
					</div>
				</section>
			</div>
		</div>
	)
};

export default Forgot;
