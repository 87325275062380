const landing = {
	tradeFinance: 'Trade finance',
	youWillEnjoy: 'you will enjoy',
	investSell: 'Invest, sell and control your entire',
	financialActivity: 'financial activity - in one place.',
	getStarted: 'Get started',
	seeHowItWorks: 'See How it Works',
	daysLeft: 'Days left',
};

export default landing;
