import React from 'react';
import PropTypes from 'prop-types';


const Item = (props) => {
	return (
		<div className="investment__inner">
			{props.children}
		</div>
	)
};

Item.defaultProps = {
	children: <></>
};

Item.propTypes = {
	children: PropTypes.any,
};

const Card = (props) => {
	const {
		className,
	} = props;

	return (
		<div className={`investment__item ${className}`}>
			{props.children}
		</div>
	)
};

Card.defaultProps = {
	children: <></>,
	className: 'main',
};

Card.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string.isRequired,
};

export {
	Card,
	Item,
};
