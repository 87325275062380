import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


const NonSophisticated = (props) => {
	const [iAcknowledge, setIAcknowledge] = useState(false);
	const [netWorth, setNetWorth] = useState('');
	const [percent, setPercent] = useState('');
	const { t } = useTranslation();
	
	return (
		<>
			<div className="entry__title">
				{t('KYC.myNetWorth')}
			</div>
			<div className="entry">
				<div className="entry__text">
					{t('KYC.thisIsSimulation')}
				</div>

				<Form noValidate className="form">
					<Form.Group controlId="networth">
						<Form.Control
							type="number"
							name="networth"
							placeholder={t('KYC.netWorth')}
							className="icon__networth"
							value={netWorth}
							onChange={e => setNetWorth(e.target.value)}
						/>
					</Form.Group>

					<Form.Group controlId="percent">
						<Form.Control
							type="number"
							name="percent"
							placeholder={t('KYC.simulation')}
							className="icon__percent"
							value={percent}
							onChange={e => setPercent(e.target.value)}
						/>
					</Form.Group>

					<Form.Group controlId="offers">
						<Form.Label className="form__glabel">
							<Form.Check
								type="checkbox"
								id="offers"
								checked={iAcknowledge}
								onChange={() => setIAcknowledge(!iAcknowledge)}
							/>
							<p>
								{t('KYC.iAcknowledge')}
							</p>
						</Form.Label>
					</Form.Group>

					<div className="btns">
						<Button className="btn btn--big">{t('confirm')}</Button>

						<a className="btn btn--big alt" onClick={() => props.setStep('hero')}>{t('cancel')}</a>
					</div>
				</Form>
			</div>
		</>
	)	
};

NonSophisticated.defaultProps = {
	setStep: () => {},
};

NonSophisticated.propTypes = {
	setStep: PropTypes.func.isRequired,
};

export default NonSophisticated;
