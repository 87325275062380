import { createAsyncThunk } from '@reduxjs/toolkit';

import { getDashboard } from '../API/dashboard';


export const getDashboardAsync = createAsyncThunk(
	'auth/getDashboard',
	async (data, { rejectWithValue }) => {
		try {
			const response = await getDashboard(data);
			return response.data
		} catch (err) {
			return rejectWithValue(err.data.error)
		}
	}
);
