const message = {
	depositSuccessful: 'Deposit successful',
	withdrawSuccessful: 'Withdraw successful',
	theAmountOf: 'The amount of',
	depositSuccessfullyReceived: ' has been succesfully issued and we should receive it in our account within 3 workdays.',
	withdrawSuccessfullyReceived: ' has been succesfully issued and you should receive it in your account within 3 workdays.',
	goToDashboard: 'Go to dashboard',
};

export default message;
