import React, { useState } from 'react';
import PropTypes from 'prop-types';


const Item = (props) => {
	const {
		title,
		body,
	} = props;
	const [isActive, setActive] = useState(false);

	return (
		<div className="faq__item">
			<div className={`faq__question ${isActive ? 'active' : ''}`} onClick={() => setActive(!isActive)}>
				{title}
			</div>

			<div className="faq__answer">
				{body}
			</div>
		</div>
	)
};

Item.defaultProps = {
	title: '',
	body: '',
};

Item.propTypes = {
	title: PropTypes.string.isRequired,
	body: PropTypes.string.isRequired,
};

export default Item;
