import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Select = (props) => {
	const [isActive, setActive] = useState(false);

	return (
		<div className="select__wrap">
			<div className={`select__title ${isActive ? 'active' : ''}`} data-toggle onClick={() => setActive(!isActive)}>0 selected</div>

			<div className="select__dropdown">
				<div className="select__all">Select all</div>

				{props.children}
			</div>
		</div>
	)
}

Select.propTypes = {
	children: PropTypes.arrayOf(PropTypes.element)
};

Select.defaultProps = {
	children: <></>
};

export default Select;
