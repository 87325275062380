import React from 'react';
import { Translation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Lender from './lender';
import Borrower from './borrower';
import withRouter from '../../components/withRouter';
import { getCountries } from '../../redux/API/countries';
import { occupations } from '../../app/misc';
import { updateProfile } from '../../redux/reducers/authSlice';


class KYC extends React.Component {
	constructor(props) {
		super(props);

		let step = 'lender';
		const {
			data,
			forms,
		} = this.props;

		if (data.type === 'Investor') {
			if (!forms.KYCCompleted) {
				step = 'lender';
			}
		} else {
			if (!forms.KYCCompleted) {
				step = 'lender';
			}
			if (!forms.borrowerCompleted) {
				step = 'borrower';
			}
		}

		this.state = {
			step,
			countries: [],
			occupations,
		};
	}

	componentDidMount() {
		this.getCountries();
	}

	async getCountries() {
		const response = await getCountries();

		this.setState({
			countries: response.data,
		});
	}

	render() {
		const {
			data,
		} = this.props;
		const {
			step,
			countries,
			occupations,
		} = this.state;

		return (
			<div id="kyc" className="row p-0 m-0">
				<div className="col p-0 m-0">
					<section className="title">
						<div className="container">
							<Translation>
								{ (t) => (
									<>
										<h1>{t('kyc.kycVerification')}</h1>
										<h3>{t('kyc.inOrderToInvest')}</h3>
									</>
								)}
							</Translation>
						</div>
					</section>

					{step === 'lender' && (
						<Lender
							countries={countries}
							occupations={occupations}
							data={data}
							updateProfile={profile => this.props.updateProfile(profile)}
						/>
					)}
					{step === 'borrower' && (<Borrower countries={countries} occupations={occupations} />)}
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		data: state.auth.data,
		forms: state.auth.forms,
	}
}

const mapDispatchToProps = {
	updateProfile,
};

KYC.propTypes = {
	data: PropTypes.object.isRequired,
	router: PropTypes.object.isRequired,
	forms: PropTypes.object.isRequired,
	updateProfile: PropTypes.func.isRequired,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KYC));
