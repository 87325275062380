const header = {
	myProjects: 'Мои проекты',
	myFinances: 'My финансы',
	signIn: 'Войти',
	signUp: 'Регистраций',
	depositWithdraw: 'Депозит/Снятие',
	howItWorks: 'Как это работает',
	forBorrowers: 'For Borrowers',
	aboutUS: 'О нас',
}

export default header;
