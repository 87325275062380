import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';


const Sophisticated = (props) => {
	const {
		body,
	} = props;
	const { t } = useTranslation();
	
	return (
		<div className="entry">
			<div className="entry__text" dangerouslySetInnerHTML={{
				__html: body,
			}} />
			<div className="btns">
				<a className="btn btn--big">{t('confirm')}</a>
				<a className="btn btn--big alt" onClick={() => props.setStep('hero')}>{t('cancel')}</a>
			</div>
		</div>
	)	
};

Sophisticated.defaultProps = {
	body: `
		I hereby confirm that I meet the needs to be considered as a sophisticated investor, and the
		information provided in forms above is correct and true.<br/>
		I am aware that by being treated as a sophisticated investor, I will lose the investor
		protection, in compliance with the Regula*<br/><br/>
		<b>I hereby confirm that I remain liable for the veracity of the information provided in this
		request.</b>
	`,
	setStep: () => {},
};

Sophisticated.propTypes = {
	body: PropTypes.string,
	setStep: PropTypes.func.isRequired,
};

export default Sophisticated;
