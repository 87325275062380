import React from 'react';
import { useTranslation } from 'react-i18next';

import FaqItem from '../faq';
import PropTypes from 'prop-types';


const Finandscore = (props) => {
	const {
		balanceSheet,
		longTermAssets,
		shortTermAssets,
		totalAssets,
		interestBearing,
		otherLiabilities,
		totalLiabilities,
		totalEquity,
		incomeStatement,
		netRevenues,
		cogs,
		grossProfit,
		netProfit,
		kpi,
		ebitda,
		ebitdaMargin,
		debtEquityRatio,
		netWorkingCapital,
		negativePoints,
		companyFinancials,
		dealInformation,
		securityGuarantees,
		otherInformationFactors,
		companyInfoTransparency,
		scoringValue,
	} = props;
	const { t } = useTranslation();

	return (
		<FaqItem title={t('project.financialAndScoring')}>
			<div className="in__wrap">
				<div className="in__row in__thead in__flarge mb-2">
					<span>{t('project.balanceSheet')}</span>

					{balanceSheet.map((el, index) => (
						<span key={`balanceSheet_${index}`}>{el}</span>
					))}
				</div>

				<div className="in__row in__flarge">
					<span>{t('project.longTermAssets')}</span>

					{longTermAssets.map((el, index) => (
						<span key={`longTermAssets${index}`}>{el}</span>
					))}
				</div>

				<div className="in__row in__flarge">
					<span>{t('project.shortTermAssets')}</span>

					{shortTermAssets.map((el, index) => (
						<span key={`shortTermAssets${index}`}>{el}</span>
					))}
				</div>

				<div className="in__row in__result in__flarge mt-2">
					<span>{t('project.totalAssets')}</span>

					{totalAssets.map((el, index) => (
						<span key={`totalAssets${index}`}>{el}</span>
					))}
				</div>

				<div className="in__row in__flarge">
					<span>{t('project.interestBearing')}</span>

					{interestBearing.map((el, index) => (
						<span key={`interestBearing${index}`}>{el}</span>
					))}
				</div>

				<div className="in__row in__flarge">
					<span>{t('project.otherLiabilities')}</span>

					{otherLiabilities.map((el, index) => (
						<span key={`otherLiabilities${index}`}>{el}</span>
					))}
				</div>

				<div className="in__row in__result in__flarge mt-2">
					<span>{t('project.totalLiabilities')}</span>

					{totalLiabilities.map((el, index) => (
						<span key={`totalLiabilities${index}`}>{el}</span>
					))}
				</div>

				<div className="in__row in__result in__flarge">
					<span>{t('project.totalEquity')}</span>

					{totalEquity.map((el, index) => (
						<span key={`totalEquity${index}`}>{el}</span>
					))}
				</div>
			</div>

			<div className="in__wrap">
				<div className="in__row in__thead in__flarge mb-2">
					<span>{t('project.incomeStatement')}</span>

					{incomeStatement.map((el, index) => (
						<span key={`incomeStatement${index}`}>{el}</span>
					))}
				</div>

				<div className="in__row in__flarge">
					<span>{t('project.netRevenues')}</span>

					{netRevenues.map((el, index) => (
						<span key={`netRevenues${index}`}>{el}</span>
					))}
				</div>

				<div className="in__row in__flarge">
					<span>{t('cogs')}</span>

					{cogs.map((el, index) => (
						<span key={`cogs${index}`}>{el}</span>
					))}
				</div>

				<div className="in__row in__result in__flarge mt-2">
					<span>{t('project.grossProfit')}</span>

					{grossProfit.map((el, index) => (
						<span key={`grossProfit${index}`}>{el}</span>
					))}
				</div>

				<div className="in__row in__result in__flarge">
					<span>{t('project.netProfit')}</span>

					{netProfit.map((el, index) => (
						<span key={`netProfit${index}`}>{el}</span>
					))}
				</div>
			</div>

			<div className="in__wrap">
				<div className="in__row in__thead in__flarge mb-2">
					<span>{t('project.kpi')}</span>

					{kpi.map((el, index) => (
						<span key={`kpi${index}`}>{el}</span>
					))}
				</div>

				<div className="in__row in__flarge">
					<span>{t('ebitda')}</span>

					{ebitda.map((el, index) => (
						<span key={`ebitda${index}`}>{el}</span>
					))}
				</div>

				<div className="in__row in__flarge">
					<span>{t('project.ebitdaMargin')}</span>

					{ebitdaMargin.map((el, index) => (
						<span key={`ebitdaMargin${index}`}>{el}</span>
					))}
				</div>

				<div className="in__row in__result in__flarge mt-2">
					<span>{t('project.debtEquityRatio')}</span>

					{debtEquityRatio.map((el, index) => (
						<span key={`debtEquityRatio${index}`}>{el}</span>
					))}
				</div>

				<div className="in__row in__result in__flarge">
					<span>{t('project.netWorkingCapital')}</span>

					{netWorkingCapital.map((el, index) => (
						<span key={`netWorkingCapital${index}`}>{el}</span>
					))}
				</div>
			</div>

			<div className="in__wrap">
				<div className="in__row in__thead">
					<span>{t('project.scoringBreakdown')}</span>
				</div>

				<div className="chart__wrap">
					<div className="chart__info">
						<div className="chart__legend">
							<div className="in__row">
								<span>{t('project.negativePoints')}</span>
								<span>{negativePoints}</span>
							</div>
							<div className="chart__line-bg">
								<div className="chart__line" style={{ width: '20%' }}></div>
							</div>
						</div>

						<div className="chart__legend">
							<div className="in__row">
								<span>{t('project.companyFinancials')}</span>
								<span>{companyFinancials}</span>
							</div>
							<div className="chart__line-bg">
								<div className="chart__line chart__line--blue-pale" style={{ width: '60%' }}></div>
							</div>
						</div>

						<div className="chart__legend">
							<div className="in__row">
								<span>{t('project.dealInformation')}</span>
								<span>{dealInformation}</span>
							</div>
							<div className="chart__line-bg">
								<div className="chart__line chart__line--green-pale" style={{ width: '50%' }}></div>
							</div>
						</div>

						<div className="chart__legend">
							<div className="in__row">
								<span>{t('project.securityGuarantees')}</span>
								<span>{securityGuarantees}</span>
							</div>
							<div className="chart__line-bg">
								<div className="chart__line chart__line--green" style={{ width: '50%' }}></div>
							</div>
						</div>

						<div className="chart__legend">
							<div className="in__row">
								<span>{t('project.otherInformationFactors')}</span>
								<span>{otherInformationFactors}</span>
							</div>
							<div className="chart__line-bg">
								<div className="chart__line" style={{ width: '50%' }}></div>
							</div>
						</div>

						<div className="chart__legend">
							<div className="in__row">
								<span>{t('project.companyInfoTransparency')}</span>
								<span>{companyInfoTransparency}</span>
							</div>
							<div className="chart__line-bg">
								<div className="chart__line chart__line--purple-deep" style={{ width: '50%' }}></div>
							</div>
						</div>
					</div>

					<div className="chart__graph">
						<div className="chart__title">{t('project.scoringPoints')}</div>
						<div className="chart__subtitle">{t('project.scoringPointsEarned')}</div>
						<div className="chart__pie" style={{ backgroundImage: 'conic-gradient(var(--blue__pale), var(--green) 64%, #c5cdd8 0%)' }}>
							<div className="chart__value">{scoringValue}%</div>
						</div>
					</div>
				</div>
			</div>
		</FaqItem>
	)
}

Finandscore.defaultProps = {
	balanceSheet: [
		2020,
		2019,
		2018,
	],
	longTermAssets: [
		1350.000,
		1350.000,
		585.000,
	],
	shortTermAssets: [
		585.000,
		585.000,
		585.000,
	],
	totalAssets: [
		1935.000,
		1935.000,
		585.000,
	],
	interestBearing: [
		1515.000,
		1515.000,
		1515.000,
	],
	otherLiabilities: [
		1515.000,
		1515.000,
		1515.000,
	],
	totalLiabilities: [
		1935.000,
		1935.000,
		585.000,
	],
	totalEquity: [
		1935.000,
		1935.000,
		585.000,
	],
	incomeStatement: [
		2020,
		2019,
		2018,
	],
	netRevenues: [
		1350.000,
		1350.000,
		585.000,
	],
	cogs: [
		585.000,
		585.000,
		585.000,
	],
	grossProfit: [
		1935.000,
		1935.000,
		585.000,
	],
	netProfit: [
		1935.000,
		1935.000,
		585.000,
	],
	kpi: [
		2020,
		2019,
		2018,
	],
	ebitda: [
		1350.000,
		1350.000,
		585.000,
	],
	ebitdaMargin: [
		585.000,
		585.000,
		585.000,
	],
	debtEquityRatio: [
		1935.000,
		1935.000,
		585.000,
	],
	netWorkingCapital: [
		1935.000,
		1935.000,
		585.000,
	],
	negativePoints: -6,
	companyFinancials: 18,
	dealInformation: 32,
	securityGuarantees: 32,
	otherInformationFactors: 32,
	companyInfoTransparency: 32,
	scoringValue: 42,
};

Finandscore.propTypes = {
	balanceSheet: PropTypes.arrayOf(PropTypes.number),
	longTermAssets: PropTypes.arrayOf(PropTypes.number),
	shortTermAssets: PropTypes.arrayOf(PropTypes.number),
	totalAssets: PropTypes.arrayOf(PropTypes.number),
	interestBearing: PropTypes.arrayOf(PropTypes.number),
	otherLiabilities: PropTypes.arrayOf(PropTypes.number),
	totalLiabilities: PropTypes.arrayOf(PropTypes.number),
	totalEquity: PropTypes.arrayOf(PropTypes.number),
	incomeStatement: PropTypes.arrayOf(PropTypes.number),
	netRevenues: PropTypes.arrayOf(PropTypes.number),
	cogs: PropTypes.arrayOf(PropTypes.number),
	grossProfit: PropTypes.arrayOf(PropTypes.number),
	netProfit: PropTypes.arrayOf(PropTypes.number),
	kpi: PropTypes.arrayOf(PropTypes.number),
	ebitda: PropTypes.arrayOf(PropTypes.number),
	ebitdaMargin: PropTypes.arrayOf(PropTypes.number),
	debtEquityRatio: PropTypes.arrayOf(PropTypes.number),
	netWorkingCapital: PropTypes.arrayOf(PropTypes.number),
	negativePoints: PropTypes.number,
	companyFinancials: PropTypes.number,
	dealInformation: PropTypes.number,
	securityGuarantees: PropTypes.number,
	otherInformationFactors: PropTypes.number,
	companyInfoTransparency: PropTypes.number,
	scoringValue: PropTypes.number,
};

export default Finandscore;
