import { URL } from '../../config/const';
import project from '../../mocks/project';

const urls = {
	getProject: (id) => `${URL}/project/${id}`,
}

const getProject = async (params) => {
	let data;

	if (params.projectId === '6228824') {
		data = project[0];
	} else if (params.projectId === '8228824') {
		data = project[1];
	} else {
		return new Promise((resolve, reject) =>
			// eslint-disable-next-line prefer-promise-reject-errors
			setTimeout(() => reject({
				data: {
					error: 'Incorrect projectID!'
				}
			}), 500)
		);
	}

	return new Promise((resolve) =>
		setTimeout(() => resolve({
			data
		}), 3000)
	);
};

export {
	urls,
	getProject,
}
