import React from 'react';

import '../assets/loader.scss';
import PropTypes from 'prop-types';


const Loader = (props) => {
	const {
		type,
		style,
	} = props;

	return (
		<div className={`loader loader-${type}`} style={{ ...style }}>
			<div className="loader-dot"></div>
			<div className="loader-dot"></div>
			<div className="loader-dot"></div>
			<div className="loader-dot"></div>
			<div className="loader-dot"></div>
			<div className="loader-dot"></div>
		</div>
	)
};

Loader.defaultProps = {
	type: 'default',
	style: {},
};

Loader.propTypes = {
	type: PropTypes.string,
	style: PropTypes.object,
};

export default Loader;
