import { URL } from '../../config/const';
import borrower from '../../mocks/user/borrower';
import investor from '../../mocks/user/investor';

const urls = {
	loginUser: `${URL}/login-user`
}

const loginUser = async (data) => {
	const borrowers = [...borrower];
	const investors = [...investor];
	let item;

	if (data.email.search('investor') !== -1) {
		item = investors.find(el => {
			if (el.login.email === data.email) {
				if (el.login.password === data.password) {
					return true;
				}
			}

			return false;
		});
	} else if (data.email.search('borrower') !== -1) {
		item = borrowers.find(el => {
			if (el.login.email === data.email) {
				if (el.login.password === data.password) {
					return true;
				}
			}

			return false;
		});
	}

	if (item) {
		return new Promise((resolve) =>
			setTimeout(() => resolve({
				data: item,
			}), 1500)
		);
	}

	return new Promise((resolve, reject) =>
		// eslint-disable-next-line prefer-promise-reject-errors
		setTimeout(() => reject({
			data: {
				error: 'Incorrect credentials!'
			}
		}), 1000)
	);
};

export {
	urls,
	loginUser,
}
