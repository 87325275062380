import storage from 'redux-persist/lib/storage';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

import authReducer from '../redux/reducers/authSlice';
import dashboardReducer from '../redux/reducers/dashboardSlice';
import projectsReducer from '../redux/reducers/projectsSlice';
import investmentReducer from '../redux/reducers/investmentSlice';
import notificationsReducer from '../redux/reducers/notificationsSlice';
// import counterReducer from '../features/counter/counterSlice';


const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};
const rootReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  projects: projectsReducer,
  investment: investmentReducer,
  notifications: notificationsReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
});

export const persistor = persistStore(store);

/**
 * Call this to update state
 */
// persistor.purge();

/**
 * Call this to clear state
 */
// persistor.flush();
