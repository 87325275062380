import project from '../project';

const dashboard = [
	{
		img: 'card__img.png',
		id: project[0].id.toString(),
		class: project[0].score,
		title: project[0].title,
		price: project[0].amount.investment.toString(),
		priceSecondary: `${project[0].completed}% out of €${project[0].amount.fulfilled}`,
		priceLeft: project[0].left,
		rate: project[0].rate,
		term: '24 month',
		left: project[0].left,
	},
	{
		img: 'card__img--alt.png',
		id: project[1].id.toString(),
		class: project[1].score,
		title: project[1].title,
		price: project[1].amount.investment.toString(),
		priceSecondary: `${project[1].completed}% out of €${project[1].amount.fulfilled}`,
		priceLeft: project[1].left,
		rate: project[1].rate,
		term: '24 month',
		left: project[1].left,
	},
	{
		img: 'card__img.png',
		id: '1',
		class: project[1].score,
		title: 'Title 1',
		price: '22,710',
		priceSecondary: '30% out of €150,000',
		priceLeft: 20,
		rate: project[0].rate,
		term: '24 month',
		left: project[0].left,
	},
	{
		img: 'card__img.png',
		id: '2',
		class: project[1].score,
		title: 'Title 2',
		price: '22,710',
		priceSecondary: '30% out of €150,000',
		priceLeft: 20,
		rate: project[1].rate,
		term: '24 month',
		left: project[1].left,
	},
	{
		img: 'card__img.png',
		id: '3',
		class: project[0].score,
		title: 'Title 3',
		price: '22,710',
		priceSecondary: '30% out of €150,000',
		priceLeft: 20,
		rate: project[0].rate,
		term: '24 month',
		left: project[0].left,
	},
];

export default dashboard;
